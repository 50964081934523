/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vigi-country-map.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/primeng/components/gmap/gmap.ngfactory";
import * as i3 from "primeng/components/gmap/gmap";
import * as i4 from "./vigi-country-map.component";
import * as i5 from "../../selected-country.service";
import * as i6 from "../../../../_module_dialog/growl_dialog/growl.service";
import * as i7 from "../../../../models_services/map.service";
import * as i8 from "../../../../models_services/vigiregion.service";
import * as i9 from "../../../../app.config";
import * as i10 from "../../../../models_services/country.service";
import * as i11 from "../../../../models_services/vigicountry.service";
var styles_VigiCountryMapComponent = [i0.styles];
var RenderType_VigiCountryMapComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_VigiCountryMapComponent, data: {} });
export { RenderType_VigiCountryMapComponent as RenderType_VigiCountryMapComponent };
export function View_VigiCountryMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p-gmap", [["id", "googleMap"]], null, [[null, "onMapReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onMapReady" === en)) {
        var pd_0 = (_co.setMap($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GMap_0, i2.RenderType_GMap)), i1.ɵdid(1, 8699904, [["gmap", 4]], 0, i3.GMap, [i1.ElementRef, i1.IterableDiffers, i1.ChangeDetectorRef, i1.NgZone], { style: [0, "style"], options: [1, "options"], overlays: [2, "overlays"] }, { onMapReady: "onMapReady" }), i1.ɵpod(2, { "width": 0, "height": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, "100%", "100%"); var currVal_1 = _co.options; var currVal_2 = _co.overlays; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_VigiCountryMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vigi-country-map", [], null, null, null, View_VigiCountryMapComponent_0, RenderType_VigiCountryMapComponent)), i1.ɵdid(1, 114688, null, 0, i4.VigiCountryMapComponent, [i5.VIGISelectedCountryService, i6.GrowlService, i7.MapService, i8.VigiRegionService, i9.AppConfig, i10.CountryService, i11.VigiCountryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VigiCountryMapComponentNgFactory = i1.ɵccf("app-vigi-country-map", i4.VigiCountryMapComponent, View_VigiCountryMapComponent_Host_0, {}, {}, []);
export { VigiCountryMapComponentNgFactory as VigiCountryMapComponentNgFactory };
