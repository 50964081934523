import { AppConfig } from '../app.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var AdminLogsService = /** @class */ (function () {
    function AdminLogsService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    AdminLogsService.prototype.getAllWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/admin_logs', {
            params: httpParams,
            observe: 'body'
        });
    };
    AdminLogsService.ngInjectableDef = i0.defineInjectable({ factory: function AdminLogsService_Factory() { return new AdminLogsService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: AdminLogsService, providedIn: "root" });
    return AdminLogsService;
}());
export { AdminLogsService };
