import { Country } from './country';
import { VigiDescr } from './vigidescr';
import { VigiRegion } from './vigiregion';
export class VigiCountry {
    id: number;
    country_id: number;
    country: Country;    
    risk_level: string;

    vigiregions: VigiRegion[];

    constructor(){
    }
}
