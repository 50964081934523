
<div fxLayout="column">
  <p-dropdown [options]="factSheetTypesSI"
      [(ngModel)]="selectedFactSheetTypeId" (onChange)="onFactSheetTypeChange()" 
      placeholder="Select sheet type">
  </p-dropdown>

  <br />
  <div fxLayout="column" fxLayoutGap="5px" *ngIf="selectedFactSheetTypeId">
    <span>Description EN</span>
    <p-editor [(ngModel)]="descr_en" [style]="{'height':'150px'}"></p-editor>
    <br />
    <span>Description FR</span>
    <p-editor [(ngModel)]="descr_fr" [style]="{'height':'150px'}"></p-editor>
  </div>

  <p-button *ngIf="selectedFactSheetTypeId"
    label="Save" type="button" (click)="saveFactSheetDescr()"></p-button>
</div>
