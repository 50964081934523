import { Injectable } from '@angular/core';

import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class BPIService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }
   
    getBasicPobInfoForCountry(countryName: string) {
        return this.httpClient.post(this.config.apiUrl + '/bpi/for_country', {'country_name': countryName});
    }

    getBasicPobInfoForAllUsers(userType) {
        return this.httpClient.post(this.config.apiUrl + '/bpi/all_users',
            {
                'user_type': userType
            }
        );
    }

    getBasicPobInfoForAllUsersWithTimeFilter(userType, startTime, endTime, limit, timeSortOrder) {
        let maxOverTime = true;
        if (startTime || endTime) {
            maxOverTime = false;
        }
        return this.httpClient.post(this.config.apiUrl + '/bpi/all_users/time_filter', 
            {
                'start_time': startTime,
                'end_time': endTime,
                'limit': limit,
                'time_sort_order': timeSortOrder,
                'max_over_time': maxOverTime,
                'user_type': userType
            }
        );
    }

    getBasicPobLocations(backendUserId, userType, startTime, endTime, limit, timeSortOrder) {
        return this.httpClient.post(this.config.apiUrl + '/bpi/locations', 
            {
                'pob_id': backendUserId,
                'start_time': startTime,
                'end_time': endTime,
                'limit': limit,
                'time_sort_order': timeSortOrder,
                'user_type': userType,
            }
        );
    }

    getBasicPobLocationsWithMaxOverLocationTime(backendUserId, userType, startTime, endTime, limit, timeSortOrder, maxOverTime) {
        return this.httpClient.post(this.config.apiUrl + '/bpi/user/time_filter', 
            {
                'pob_id': backendUserId,
                'start_time': startTime,
                'end_time': endTime,
                'limit': limit,
                'time_sort_order': timeSortOrder,
                'max_over_time': maxOverTime,
                'user_type': userType
            }
        );
    }

    getBasicPobPanicLocationsWithMaxOverLocationTime(
        userType,
        startTime, endTime, limit, timeSortOrder, 
        maxOverTime, pobId=0, filterForPanicStatus=true
    ) {
        return this.httpClient.post(this.config.apiUrl + '/bpi/user/time_filter', 
            {
                'start_time': startTime,
                'end_time': endTime,
                'limit': limit,
                'time_sort_order': timeSortOrder,
                'max_over_time': maxOverTime,
                'filter_for_panic_status': filterForPanicStatus,
                'user_type': userType,
                'pob_id': pobId
            }
        );
    }

}
