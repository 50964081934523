<button *ngxPermissionsOnly="['CREATE_USERS']" mat-fab (click)="addItem()" style="position: fixed;bottom: 15px; right: 15px "
  pTooltip="Create new user">
  <mat-icon>add</mat-icon>
</button>
<div class="col-md-10 col-md-offset-1">
  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncUsers | async" 
        [paginator]="true" [rowsPerPageOptions]="[25, 50, 100, 250, 500]" paginatorPosition="both"
        [rows]="25" autoLayout="true" 
        [lazy]="true" [totalRecords]="count" (onLazyLoad)="loadTransactionsLazy($event)" 
        [responsive]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>Actions</th>
              <th [pSortableColumn]="'date_created'">Date created (GMT)<p-sortIcon [field]="'date_created'"></p-sortIcon></th>
              <th [pSortableColumn]="'email'">Email <p-sortIcon [field]="'email'"></p-sortIcon></th>
              <th [pSortableColumn]="'firstname'">Given name <p-sortIcon [field]="'firstname'"></p-sortIcon></th>
              <th [pSortableColumn]="'lastname'">Surname<p-sortIcon [field]="'lastname'"></p-sortIcon></th>
              <th [pSortableColumn]="'phone'">Phone<p-sortIcon [field]="'phone'"></p-sortIcon></th>
              <th>{{ 'Group'}}</th>
              <th>{{ 'Pole'}}</th>
              <th>{{ 'Division'}}</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th>
                <input pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'equals')">
              </th>
              <th>
                <input pInputText type="text" (input)="dt.filter($event.target.value, 'firstname', 'equals')">
              </th>
              <th>
                <input pInputText type="text" (input)="dt.filter($event.target.value, 'lastname', 'equals')">
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td>
                <span class="ui-column-title">Actions</span>
                <button mat-icon-button pTooltip="Edit user" (click)="editItem(rowData)">
                    <i class="fa fa-edit" style="font-size: 24px;"></i>
                </button>
                <button mat-icon-button pTooltip="Delete user" (click)="removeItem(rowData)">
                    <i class="fa fa-trash" style="font-size: 24px;"></i>
                </button>
              </td>
              <td>
                <span class="ui-column-title">Date created (GMT)</span>
                {{ rowData.date_created }}
              </td>
              <td>
                <span class="ui-column-title">Email</span>
                {{ rowData.email }}
              </td>
              <td>
                <span class="ui-column-title">Firstname</span>
                {{ rowData.firstname }}
              </td>
              <td>
                <span class="ui-column-title">Lastname</span>
                {{ rowData.lastname }}
              </td>
              <td>
                <span class="ui-column-title">Phone</span>
                {{ rowData.phone }}
              </td>
              <td>
                <span class="ui-column-title">Group</span>
                {{ rowData.group?.name_en }}
              </td>
              <td>
                <span class="ui-column-title">Pole</span>
                {{ rowData.pole?.name_en}}
              </td>
              <td>
                <span class="ui-column-title">Division</span>
                {{ rowData.division?.name_en}}
              </td>
            </tr>
          </ng-template>
      </p-table>
    </div>
  </div>
</div>
