import { Observable } from 'rxjs/Observable';
import { InfoDialogComponent } from './info-dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { Injectable } from '@angular/core';

@Injectable()
/**
 * The service will act as a wrapper for MdDialog to
 * simplify the process of calling the dialog and subscribing to it.
 */
export class InfoDialogsService {

    constructor(private dialog: MatDialog) { }

    public show(title: string, message: string): Observable<boolean> {
        let dialogRef: MatDialogRef<InfoDialogComponent>;
        dialogRef = this.dialog.open(InfoDialogComponent, {
            width: '400px',
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        return dialogRef.afterClosed(); // emits event when the dialog is closed
    }
}

