/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./swagger-documentation.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./swagger-documentation.component";
import * as i3 from "../../app.config";
var styles_SwaggerDocumentationComponent = [i0.styles];
var RenderType_SwaggerDocumentationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SwaggerDocumentationComponent, data: {} });
export { RenderType_SwaggerDocumentationComponent as RenderType_SwaggerDocumentationComponent };
export function View_SwaggerDocumentationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Swagger API docs 1.1"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Swagger API docs 2.0"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.appConfig.apiUrl + "/swagger-ui1_1"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.appConfig.apiUrl + "/swagger-ui2_0"); _ck(_v, 6, 0, currVal_1); }); }
export function View_SwaggerDocumentationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-swagger-documentation", [], null, null, null, View_SwaggerDocumentationComponent_0, RenderType_SwaggerDocumentationComponent)), i1.ɵdid(1, 114688, null, 0, i2.SwaggerDocumentationComponent, [i3.AppConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SwaggerDocumentationComponentNgFactory = i1.ɵccf("app-swagger-documentation", i2.SwaggerDocumentationComponent, View_SwaggerDocumentationComponent_Host_0, {}, {}, []);
export { SwaggerDocumentationComponentNgFactory as SwaggerDocumentationComponentNgFactory };
