import { Group } from './group';
import { Pole } from './pole';
import { Division } from './division';

export class MobileUser {
    id: number;
    date_created: string;
    date_updated: string;
    password: string;
    blocked: boolean;
    deleted: boolean;
    role: string;
    phone: string;
    email: string;
    email_identity_provider: string;
    // instagram_number: string;
    group_id: number;
    pole_id: number;
    division_id: number;
    sponsor_id: number;
    sponsor_email: string;
    status: string;

    group: Group;
    pole: Pole;
    division: Division;
    sponsor_group: Group;
    sponsor_pole: Pole;
    firstname: string;
    lastname: string;

    is_sponsored: boolean;

    // Devices information
    a1_fb: string;
    a1_os: string;
    a1_os_v: string;
    a1_d_t: string;
    a1_install_date: string;
    a2_fb: string;
    a2_os: string;
    a2_os_v: string;
    a2_d_t: string;
    a2_install_date: string;
    i1_fb: string;
    i1_os: string;
    i1_os_v: string;
    i1_d_t: string;
    i1_install_date: string;
    i2_fb: string;
    i2_os: string;
    i2_os_v: string;
    i2_d_t: string;
    i2_install_date: string;

    construct() {
        this.role = 'MOBILE_USER';
    }
}
