import { OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '../../../models_services/location.service';
import { tap, map } from 'rxjs/operators';
import { MobileUserService } from '../../../models_services/mobile-users.service';
import { VigiRegionService } from '../../../models_services/vigiregion.service';
var MobileUserLocationsComponent = /** @class */ (function () {
    function MobileUserLocationsComponent(route, locationService, userService, vigiRegionService) {
        this.route = route;
        this.locationService = locationService;
        this.userService = userService;
        this.vigiRegionService = vigiRegionService;
        this.itIsSingleUserPage = false;
        this.currentPage = 1;
        this.displayDialog = false;
        this.dialogCallback = null;
        this.showCorporateUsersTable = 1;
        this.params = {};
        this.options = {
            center: { lat: 48.830772399902344, lng: 2.8097193241119385 },
            zoom: 9
        };
        this.overlays = [];
        this.waitingParameterResolve = true;
    }
    MobileUserLocationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.options = {
            center: { lat: 36.890257, lng: 30.707417 },
            zoom: 12
        };
        try {
            this.itIsSingleUserPage = !!parseInt(this.route.snapshot.paramMap.get('id'));
        }
        catch (error) {
            console.log('Users locations page');
        }
        if (this.itIsSingleUserPage) {
            this.params = {
                page: 1, per_page: 10, order: '-time',
                email: { operator: '%', value: null, 'class': 'MobileUser' },
                firstname: { operator: '%', value: null, 'class': 'MobileUser' },
                lastname: { operator: '%', value: null, 'class': 'MobileUser' },
            };
        }
        else {
            this.params = {
                page: 1, per_page: 10, order: '-time',
                email: { operator: '%', value: null, 'class': 'MobileUser' },
                firstname: { operator: '%', value: null, 'class': 'MobileUser' },
                lastname: { operator: '%', value: null, 'class': 'MobileUser' },
                is_external_contractor: { operator: '=', value: 0, 'class': 'MobileUser' },
            };
        }
        this.subscription = this.route.params.subscribe(function (params) {
            if (params.hasOwnProperty('id')) {
                _this.userService.getById(params['id']).subscribe(function (data) {
                    _this.waitingParameterResolve = false;
                    console.log(data.user);
                    _this.user = data.user;
                    _this.loadLocationsForUser();
                });
            }
            else {
                _this.waitingParameterResolve = false;
                _this.loadLocationsForUser();
            }
        });
    };
    MobileUserLocationsComponent.prototype.setMap = function (event) {
        this.map = event.map;
        this.map.setCenter(this.options.center);
        this.dialogCallback();
    };
    MobileUserLocationsComponent.prototype.loadLocationsForUser = function () {
        this.getPage(1);
    };
    MobileUserLocationsComponent.prototype.getPage = function (page) {
        var _this = this;
        this.params.page = page;
        var userId = (this.user) ? this.user.id : 0;
        if (userId != 0) {
            this.asyncLocations = this.locationService.getAllLocationsForUserWithParams(this.params, userId).pipe(tap(function (data) {
                _this.count = data.count;
                _this.loading = false;
                _this.currentPage = page;
            }), map(function (data) {
                if (data.locations) {
                    var _loop_1 = function (location_1) {
                        if (location_1.country_id) {
                            _this.vigiRegionService.getVigiRegionsForCountryId(location_1.country_id).subscribe(function (data) {
                                location_1.regions = (data.vigiregions) ? data.vigiregions : [];
                            });
                        }
                    };
                    for (var _i = 0, _a = data.locations; _i < _a.length; _i++) {
                        var location_1 = _a[_i];
                        _loop_1(location_1);
                    }
                }
                return data.locations;
            }));
        }
        else {
            this.asyncLocations = this.locationService.getAllLocationsForUsersWithParams(this.params).pipe(tap(function (data) {
                _this.count = data.count;
                _this.loading = false;
                _this.currentPage = page;
            }), map(function (data) {
                if (data.locations) {
                    var _loop_2 = function (location_2) {
                        if (location_2.country_id) {
                            _this.vigiRegionService.getVigiRegionsForCountryId(location_2.country_id).subscribe(function (data) {
                                location_2.regions = (data.vigiregions) ? data.vigiregions : [];
                            });
                        }
                    };
                    for (var _i = 0, _a = data.locations; _i < _a.length; _i++) {
                        var location_2 = _a[_i];
                        _loop_2(location_2);
                    }
                }
                return data.locations;
            }));
        }
    };
    MobileUserLocationsComponent.prototype.loadTransactionsLazy = function (event) {
        if (this.waitingParameterResolve) {
            return;
        }
        this.params.email.value = event.filters['email'] ? event.filters['email'].value : '';
        this.params.lastname.value = event.filters['lastname'] ? event.filters['lastname'].value : '';
        this.params.firstname.value = event.filters['firstname'] ? event.filters['firstname'].value : '';
        if (event.sortField) {
            this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
        }
        this.params.per_page = event.rows;
        this.getPage((event.first / event.rows) + 1);
    };
    MobileUserLocationsComponent.prototype.onRowSelect = function () {
        if (this.map) {
            console.log('on row select');
            var latitude = parseFloat(this.selectedLocation.latitude);
            var longitude = parseFloat(this.selectedLocation.longitude);
            var center = new google.maps.LatLng(latitude, longitude);
            var marker = new google.maps.Marker({ position: { lat: latitude, lng: longitude }, title: this.selectedLocation.country + ':' + this.selectedLocation.city });
            this.overlays.push(marker);
            this.map.setCenter(center);
        }
        else {
            this.dialogCallback = this.onRowSelect;
        }
        this.showDialog();
    };
    MobileUserLocationsComponent.prototype.handleOverlayClick = function (event) {
        console.log(event.overlay); // event.map, event.overlay, event.originalEvent
    };
    MobileUserLocationsComponent.prototype.showDialog = function () {
        this.displayDialog = true;
    };
    MobileUserLocationsComponent.prototype.showCorporateUsers = function () {
        if (!this.showCorporateUsersTable) {
            this.showCorporateUsersTable = 1;
            this.params.is_external_contractor.value = 0;
            this.getPage(1);
        }
    };
    MobileUserLocationsComponent.prototype.showSponsoredUsers = function () {
        if (this.showCorporateUsersTable) {
            this.showCorporateUsersTable = 0;
            this.params.is_external_contractor.value = 1;
            this.getPage(1);
        }
    };
    return MobileUserLocationsComponent;
}());
export { MobileUserLocationsComponent };
