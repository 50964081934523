import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var BackendUserService = /** @class */ (function () {
    function BackendUserService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    BackendUserService.prototype.getAll = function () {
        return this.httpClient.get(this.config.apiUrl + '/backendusers');
    };
    BackendUserService.prototype.getAllWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/backendusers', {
            params: httpParams,
            observe: 'body'
        });
    };
    BackendUserService.prototype.getById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/backendusers/' + _id);
    };
    BackendUserService.prototype.create = function (user) {
        return this.httpClient.post(this.config.apiUrl + '/backendusers', user);
    };
    BackendUserService.prototype.update = function (user) {
        return this.httpClient.put(this.config.apiUrl + '/backendusers/' + user.id, user);
    };
    BackendUserService.prototype.delete = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/backendusers/' + _id);
    };
    BackendUserService.prototype.downloadUsersCSV = function () {
        return this.httpClient.get(this.config.apiUrl + '/backendusersCSV', {
            headers: new HttpHeaders().set('Accept', 'application/zip'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    BackendUserService.prototype.sendResetPasswordEmail = function (email) {
        return this.httpClient.post(this.config.apiUrl + '/backendusers/reset_password_email', { 'email': email });
    };
    BackendUserService.ngInjectableDef = i0.defineInjectable({ factory: function BackendUserService_Factory() { return new BackendUserService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: BackendUserService, providedIn: "root" });
    return BackendUserService;
}());
export { BackendUserService };
