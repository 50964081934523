<div fxLayout="column" fxLayoutGap="10px" style="margin: 1rem">
  <h2>Alert sending form</h2>


<p-dropdown 
  [options]="alertTypes"
  [(ngModel)]="selectedAlertType"
  optionLabel="name"
  [showClear]="true" 
  placeholder="Please select alert receivers">
</p-dropdown>

  <div [hidden]="!(selectedAlertType && selectedAlertType.code == 'MULTY_DIVISION_ALERT') ">
    <!-- ENTITIES FORM -->
    <p-tree
      [value]="trees" selectionMode="checkbox" [(selection)]="selectedNodes"
      (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)" 
    >
    </p-tree>
  </div>
  <div [hidden]="!(selectedAlertType && selectedAlertType.code == 'MULTY_USER_ALERT') ">
    <input class="input-normal" pInputText 
          placeholder="Search for user" #searchUsersInput >
      Selected users count: {{dropdowns['selected_users_ids'].size}} 
      <br /> <br />

      <p-multiSelect  
        [filter]="false"
        *ngIf="dropdowns.users && dropdowns.users.length > 0"
        [options]="dropdowns.users"
        [(ngModel)]="dropdowns.selected_users"
        defaultLabel="..." displaySelectedLabel="false"
        maxSelectedLabels="0"
        (onChange)="onDropdownUserSelectionChange($event)">
      </p-multiSelect>
      <div *ngIf="!dropdowns.users || dropdowns.users.length == 0">
        There are not users with registration for pushes in the accessible entities
      </div>
  </div>
  <div [hidden]="!(selectedAlertType && selectedAlertType.code == 'SUBSCRIPTION_COUNTRY_ALERT') ">
    <p-multiSelect [options]="countriesSI" [(ngModel)]="selectedSubscriptionCountriesIds"
      defaultLabel="{{ 'TRIPLE_DOTS' | translate }}" displaySelectedLabel="false"
      maxSelectedLabels="0" (onChange)="onSubscriptionCountriesChange()" >
    </p-multiSelect>
  </div>
  <div [hidden]="!(selectedAlertType && selectedAlertType.code == 'HOW_ARE_YOU') ">
    <span style="color:red">Not implemented</span>
  </div>


<!-- Localized in location form -->
<!-- <p-accordionTab  header="Send to users localized in country">
  <p-multiSelect [options]="countriesSI" [(ngModel)]="selectedLocalizationCountriesIds"
    defaultLabel="{{ 'TRIPLE_DOTS' | translate }}" displaySelectedLabel="false"
    maxSelectedLabels="0" (onChange)="onLocalizationCountriesChange()" >
  </p-multiSelect>
</p-accordionTab> -->

<div fxLayout="row">
  <p-checkbox 
    [(ngModel)]="itIsHowAreYou" 
    [binary]="true"
    inputId="itIsHowAreYouID"
    (onChange)="onItIsHowAreYouCheckboxChange($event)">
  </p-checkbox>
  &nbsp;&nbsp;
  <label for="itIsHowAreYouID">Send "How are you?" alert.</label>
</div>

<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
  <h2>English</h2>
  <div fxLayout="column">
    <input [(ngModel)]="title" placeholder="Title (en)" type="text" pInputText maxlength="200">
    <br />
    <div fxLayout="column">
      <span>Alert text (en)</span>
      <p-editor [(ngModel)]="body" [style]="{'height':'200px'}"></p-editor>
    </div>
    <!-- <textarea maxlength="1600"
      [(ngModel)]="body" placeholder="Alert text (en)" 
      rows="5" cols="30" pInputTextarea autoResize="autoResize"></textarea>
    -->
    <br />
  </div>
  <h2>French</h2>
  <div fxLayout="column">
    
    <input [(ngModel)]="titleFr" placeholder="Title (fr)" type="text" pInputText maxlength="200">
    <br />
    <div fxLayout="column">
      <span>Alert text (fr)</span>
      <p-editor [(ngModel)]="bodyFr" [style]="{'height':'200px'}"></p-editor>
    </div>
    <!-- <textarea maxlength="1600"
      [(ngModel)]="bodyFr" placeholder="Alert text (fr)" rows="5" cols="30" pInputTextarea autoResize="autoResize"></textarea> 
    -->
    <br />
  </div>
</div>
<button [disabled]="!selectedAlertType || !title || !body || !titleFr || !bodyFr" 
    pButton type="button" label="Send" (click)="sendPushMessage()">
</button>

  


</div>

