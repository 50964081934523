import { Component, OnInit } from '@angular/core';
import { DomainService } from '../../models_services/domain.service';
import { Domain } from '../../models/domain';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  // domain
  domainDisplayDialog: boolean;
  domain: Domain = new Domain();
  selectedDomain: Domain;
  newDomain: boolean;
  domains: Domain[] = [];

  constructor(
    private domainService: DomainService,
    private growlService: GrowlService
  ) { }

  ngOnInit() {
    this.loadAllDomains();
  }

  loadAllDomains() {
    this.domainService.getAllEmailDomains().subscribe(data => this.domains = (data.domains) ? data.domains : []);
  }

  showDomainDialogToAdd(selectedDomain: Domain) {
    this.newDomain = true;
    this.domain = new Domain();
    this.domainDisplayDialog = true;
  }


  /**
   * Domain
   */
  saveDomain() {
    const domains = [...this.domains];
    if (this.newDomain) { // NEW
      this.domainService.createDomain(this.domain).subscribe(
        (data: any) => {
          domains.push(data.domain);
          this.domains = domains;
          this.domain = null;
          this.domainDisplayDialog = false;
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.domain = null;
          this.domainDisplayDialog = false;
        }
      );
    } else { // EDIT
      this.domainService.updateDomain(this.domain).subscribe(
        (data: any) => {
          domains[this.domains.indexOf(this.selectedDomain)] = data.domain;
          this.domains = domains;
          this.domain = null;
          this.domainDisplayDialog = false;
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.domain = null;
          this.domainDisplayDialog = false;
        }
      );
    }
  }


  /**
   * delete domain
   */
  deleteDomain() {
    this.domainService.deleteDomain(this.selectedDomain.id).subscribe(
      (data) => {
        const index = this.domains.indexOf(this.selectedDomain);
        this.domains = this.domains.filter((val, i) => i !== index);
        this.domain = null;
        this.domainDisplayDialog = false;
        this.growlService.showInfo('Domain is deleted.');
      },
      (error_data) => {
        this.growlService.showError(error_data.error.msg_const);
        this.domain = null;
        this.domainDisplayDialog = false;
      }
    );
  }

  onDomainRowSelect(event) {
    this.newDomain = false;
    this.domain = this.cloneDomain(event.data);
    this.domainDisplayDialog = true;
  }

  cloneDomain(c: Domain): Domain {
    const domain = new Domain();
    Object.keys(c).forEach(prop => domain[prop] = c[prop]);
    return domain;
  }

}
