/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "../../../../node_modules/ngx-image-gallery/ngx-image-gallery.ngfactory";
import * as i9 from "ngx-image-gallery";
import * as i10 from "./upload-dialog.component";
var styles_UploadFileDialogComponent = [];
var RenderType_UploadFileDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UploadFileDialogComponent, data: {} });
export { RenderType_UploadFileDialogComponent as RenderType_UploadFileDialogComponent };
function View_UploadFileDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_UploadFileDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_UploadFileDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h3", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "input", [["type", "file"]], [[8, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "button", [["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(8, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["Cancel"])), (_l()(), i0.ɵeld(10, 0, null, null, 7, "p", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadFileDialogComponent_1)), i0.ɵdid(13, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadFileDialogComponent_2)), i0.ɵdid(15, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 1, "ngx-image-gallery", [], [[2, "active", null]], [["window", "keydown"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 17).onKeyboardInput($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i0.ɵnov(_v, 17).onWindowResize($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_NgxImageGalleryComponent_0, i8.RenderType_NgxImageGalleryComponent)), i0.ɵdid(17, 638976, null, 0, i9.NgxImageGalleryComponent, [i0.ElementRef, i0.Renderer2], { images: [0, "images"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_6 = _co.imageUrl; _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.imageUrl; _ck(_v, 15, 0, currVal_7); var currVal_9 = _co.images; _ck(_v, 17, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.fileExtension; _ck(_v, 4, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 8).disabled || null); var currVal_5 = (i0.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_8 = i0.ɵnov(_v, 17).opened; _ck(_v, 16, 0, currVal_8); }); }
export function View_UploadFileDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-upload-dialog", [], null, null, null, View_UploadFileDialogComponent_0, RenderType_UploadFileDialogComponent)), i0.ɵdid(1, 49152, null, 0, i10.UploadFileDialogComponent, [i1.MatDialogRef], null, null)], null, null); }
var UploadFileDialogComponentNgFactory = i0.ɵccf("app-upload-dialog", i10.UploadFileDialogComponent, View_UploadFileDialogComponent_Host_0, {}, {}, []);
export { UploadFileDialogComponentNgFactory as UploadFileDialogComponentNgFactory };
