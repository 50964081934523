import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Component, Inject, ForwardRefFn, forwardRef } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
// the reactive forms module is used for the reactive driven approach
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { AppRoutingModule, routedComponents } from './module_routing/routing.module';
import { AuthService } from './_services/auth.service';
import { AuthGuard } from './_services/auth-guard.service';
// interceptors are implemented into the core module because are generally used in every app

import { AppComponent } from './app.component';
// MODULES
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // needed for the material module
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgcFloatButtonModule } from 'ngc-float-button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// PRIMENG MODULES
import {
  AccordionModule,
  ButtonModule,
  ChartModule,
  PanelModule,
  MultiSelectModule,
  CalendarModule,
  GMapModule,
  DataTableModule,
  TabViewModule,
  ScrollPanelModule,
  FileUploadModule,
  CardModule,
  // GalleriaModule,
  DropdownModule,
  DialogModule,
  DataListModule,
  // LightboxModule,
  ProgressBarModule,
  TooltipModule,
  // AutoCompleteModule,
  EditorModule,
  CheckboxModule,
  RadioButtonModule,
} from 'primeng/primeng';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TreeModule } from 'primeng/tree';

// MY MODULES
// import { SimpleSpeedDialModule } from './_simple_speed_dial/simple_speed_dial.module'
import { CoreModule } from './_module_core/core.module';
import { DialogsModule } from './_module_dialog/dialog.module';

import { MyMaterialModule } from './_module_my_material/material.module';
// imports for translator
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

// COMPONENTS
import { AlertComponent } from './_module_dialog/alert/alert.component';
import { HomeComponent } from './home/home.component';
// MAIN SERVICES
import { AppConfig } from './app.config';
// COMPONENT SERVICES
import { AlertService } from './_module_dialog/alert/alert.service';
import { MyPermissionsService } from './_services/myx-permissions.service';
// RESOLVERS
import { CountriesResolve } from './models_resolvers/countries.resolve';

import { ZipService } from './utils/jszip_service';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
// export function createTranslateLoader(http: HttpClient) {
//         return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

@NgModule({
  imports: [
    BrowserModule, FormsModule, BrowserAnimationsModule,
    ReactiveFormsModule, HttpClientModule,
    FlexLayoutModule, NgxImageGalleryModule,
    NgcFloatButtonModule, FontAwesomeModule,
    // primeng modules
    // CalendarModule, GalleriaModule, LightboxModule, AutoCompleteModule,
    AccordionModule, MultiSelectModule, CalendarModule, GMapModule, DataTableModule, TableModule, TableModule,
    TabViewModule, ScrollPanelModule, FileUploadModule, CardModule,
    DropdownModule, DialogModule, DataListModule, ProgressBarModule,
    TooltipModule, ButtonModule, PanelModule, ChartModule, EditorModule,
    CheckboxModule, RadioButtonModule, OverlayPanelModule, InputSwitchModule, 
    TreeModule,

    MyMaterialModule,
    // SimpleSpeedDialModule.forRoot(), 
    CoreModule, AppRoutingModule, DialogsModule, NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [
    AppComponent, routedComponents,
    AlertComponent, HomeComponent,
  ],
  providers: [
    AuthService, AuthGuard, AlertService, AppConfig,
    MyPermissionsService,
    // Resolvers
    CountriesResolve,
    // Dialogs
    // util services
    ZipService
  ],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
