import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class DeviceService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getDeviceOsVStats() {
        return this.httpClient.get<any>(this.config.apiUrl + '/devices/os_v_stats');
    }

}
