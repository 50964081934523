import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class PanicService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getLocationPanicInfo(locationId) {
        return this.httpClient.get<any>(
            this.config.apiUrl 
            + '/location/' 
            + locationId 
            + '/panic/info'
        );
    }

}
