import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse, HttpClient, HttpBackend } from '@angular/common/http';
import { } from '@angular/common/http';
import { Observable, pipe, throwError } from 'rxjs';
import { tap, map, catchError, mergeMap } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { MyPermissionsService } from './myx-permissions.service';

@Injectable()
export class AuthService {
    private httpClient: HttpClient;

    constructor(
        private handler: HttpBackend,  // is not intercepted
        private config: AppConfig,
        private myPermissionsService: MyPermissionsService
    ) {
        this.httpClient = new HttpClient(handler);
    }

    /** it posts the users credentials to the api and checks the response for a JWT token, if there is one
     * it means authentication was successful
     * so the user details including the token are added to local storage. */
    login(email: string, password: string) {
        return this.httpClient.post(this.config.apiUrl + '/backendusers/login',
            { email: email, password: password },
            { headers: new HttpHeaders() }
        ).pipe(
            map((data: any) => {
                // login successful if there's a jwt token in the response
                if (data && data.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('current_user', JSON.stringify(data.user));
                    localStorage.setItem('auth_token', data.token);
                    localStorage.setItem('email', email);
                    localStorage.setItem('role', data.user.role);
                    
                    this.myPermissionsService.definePermisions(
                        localStorage.getItem('role')
                    );
                }
                return data;
            }),
            catchError((err: Response) => {
                if (typeof err.json === 'function') {
                    return throwError(err.json());
                } else { return throwError(err); }
            })
        );
    }

    logout() {
        // Remove user from local storage to log user out
        // This method is invoked from LoginComponent onInit, or every time we load the login page
        localStorage.removeItem('current_user');
        localStorage.removeItem('auth_token');
        this.myPermissionsService.deleteAllPermissions();
        this.httpClient.get(this.config.apiUrl + '/backendusers/logout').subscribe();
    }

    // token() {
    //     const httpHeaders = new HttpHeaders().set('Authorization', 'Basic ' + btoa(localStorage.getItem('email') + ':' + localStorage.getItem('password')));
    //         return this.httpClient.get(this.config.apiUrl + '/token', { headers: httpHeaders }
    //     );
    // }

    getRecoverPasswordPath() {
        return this.httpClient.get(this.config.apiUrl + '/reset_password_path').pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err: Response) => throwError(err.json()))
        );
    }

}
