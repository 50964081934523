import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable({providedIn: 'root'})
export class AlertService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    sentTo(type,
        divisionIds, poleIds, mobileuserIds, selectedSubscriptionCountryIds, selectedLocalizedCountryIds,
        title, body, titleFr, bodyFr, itIsHowAreYou=false) {
        return this.httpClient.post(this.config.apiUrl + '/alerts/send_to', 
        {
            'type': type,
            'division_ids': divisionIds,
            'pole_ids': poleIds,
            'mobileuser_ids': mobileuserIds,
            'selected_subscription_country_ids': selectedSubscriptionCountryIds,
            'selected_localized_country_ids': selectedLocalizedCountryIds,
            'title': title,
            'body': body,
            'title_fr': titleFr,
            'body_fr': bodyFr,
            'it_is_how_are_you':itIsHowAreYou
        });
    }

    getAllHowAreYouForUserWithParams(params: any, mobileUserId: number) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/alerts/howareyou/' + mobileUserId, {
            params: httpParams,
            observe: 'body'
        });
    }

    getHowAreYouWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/alerts/howareyou', {
            params: httpParams,
            observe: 'body'
        });
    }

    getAllWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/alerts', {
            params: httpParams,
            observe: 'body'
        });
    }

    getAllHAYWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/hayalerts', {
            params: httpParams,
            observe: 'body'
        });
    }

    getAllAlertTypes() {
        return this.httpClient.get<any>(this.config.apiUrl + '/alerts/types');
    }

    getAlertSentUsers(alertId: number) {
        return this.httpClient.get<any>(
            this.config.apiUrl
             + '/alerts/' + alertId  + '/alerted_users'
            );
    }

    getById(alertId: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/alerts/' + alertId);
    }

    update(alert) {
        return this.httpClient.put(this.config.apiUrl + '/alerts/' + alert.id, alert);
    }

    show(id: number) {
        return this.httpClient.put(this.config.apiUrl + '/alerts/' + id + '/show', alert);
    }

    hide(id: number) {
        return this.httpClient.put(this.config.apiUrl + '/alerts/' + id + '/hide', alert);
    }

    delete(id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/alerts/' + id);
    }

    deleteBulkOfAlerts(alerts: any[]) {
        let ids = [];
        if(alerts && alerts.length > 0) { ids = alerts.map(a => a.id); }
        return this.httpClient.post(this.config.apiUrl + '/alerts/bulkdelete', {"ids": ids});
    }

    downloadAlertsCSV() {
        return this.httpClient.get(this.config.apiUrl 
            + '/alerts/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    downloadAlertsPdf() {
        return this.httpClient.get(this.config.apiUrl 
            + '/alerts/pdf', {
            headers: new HttpHeaders().set('Accept', 'application/pdf' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    downloadHAYsCSV() {
        return this.httpClient.get(this.config.apiUrl 
            + '/alerts/howareyou/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    downloadHAYsPdf() {
        return this.httpClient.get(this.config.apiUrl 
            + '/alerts/howareyou/pdf', {
            headers: new HttpHeaders().set('Accept', 'application/pdf' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

}
