import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { BasicPobInfo } from '../../../models/bpi';
import { BPIService } from '../../../models_services/bpi.service';
import * as moment from 'moment';
import { TimeFilterInterface } from '../time-filter/time-filter.component';
import { PobMapComponent } from '../pob-map/pob-map.component';

@Component({
  selector: 'app-map-all',
  templateUrl: './map-all.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class MapAllComponent implements OnInit {
  @ViewChild(PobMapComponent) pobMapComponent: PobMapComponent;
  private subscription: Subscription;
  bpiList: BasicPobInfo[];
  displayLegend = false;
  displayTimeFilterForm = false;
  showTimeFilterButtons = false;
  userType = null;
  startTime = null;
  endTime = null;
  isCursorActive = false;

  constructor(
    private bpiService: BPIService,
  ) {
    Date.prototype.toJSON = function () { return moment(this).format(); };
  }

  ngOnInit() {
    this.showTimeFilterButtons = true;
    this.getBasicPobInfoForAllUsers();
  }

  getBasicPobInfoForAllUsers(){
    // This service is used only when the map is first loaded.
    // The other cases are using the service with the filter
    this.subscription = this.bpiService.getBasicPobInfoForAllUsers(null).subscribe(
      (data: any) => {
        this.bpiList = data.bpis;
        if (! this.bpiList) {
          console.log('No locations are found');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onFilter(event: TimeFilterInterface) {
    this.displayTimeFilterForm = false;
    this.startTime = event.startTime;
    this.endTime = event.endTime;
    this.getBasicPobInfoForAllUsersWithTimeFilter(
      null, 
      'desc'
    );
  }

  getBasicPobInfoForAllUsersWithTimeFilter(limit, timeSortOrder){
    this.bpiService.getBasicPobInfoForAllUsersWithTimeFilter(
      this.userType,
      this.startTime,
      this.endTime,
      limit,
      timeSortOrder
    ).subscribe(
      (data: any) => {
        this.bpiList = data.bpis;
        if (! this.bpiList) {
          console.log('No locations are found');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onTimeFilterButtonClick() {
    this.displayTimeFilterForm = !this.displayTimeFilterForm;
  }

  onLegendQuestionClick() {
    this.displayLegend = !this.displayLegend;
  }

  showCorporateUsers() {
    if (this.userType != 'CORPORATE') {
      this.userType = 'CORPORATE';
    } else {
      this.userType = null;
    }
    // fetch the corporate users
    this.getBasicPobInfoForAllUsersWithTimeFilter(null, 'desc');
  }

  showSponsoredUsers() {
    if (this.userType != 'SPONSORED') {
      this.userType = 'SPONSORED';
    } else {
      this.userType = null;
    }
    // fetch the corporate users
    this.getBasicPobInfoForAllUsersWithTimeFilter(null, 'desc');
  }

  switchMapDrawingOnOff() {
    this.isCursorActive = !this.isCursorActive;
    this.pobMapComponent.onCursorClick();
  }

}
