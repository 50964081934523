import { Injectable, EventEmitter } from '@angular/core';

@Injectable({providedIn: 'root'})
export class VIGISelectedCountryService {
    public selectedCountryId: number = 81; // selected country is France by default
    countryIdUpdatedEvent = new EventEmitter();

    constructor() {}

    setCountryId(selectedCountryId) {
        this.selectedCountryId = selectedCountryId;
        this.countryIdUpdatedEvent.emit(this.selectedCountryId);
    }

}
