<h2 style="font-size: 1.2em" fxlayoutalign="left center">{{vigiSection}}</h2>
<p-dropdown [options]="countriesSI"
    [(ngModel)]="selectedCountryId" (onChange)="onCountryChange()" 
    placeholder="Select country">
</p-dropdown>

<div id="fields_container" fxLayout="row" fxLayoutAlign="center start"  style="width: 100%; height: 100%;">  
    <!-- <button [disabled]="leftButtonDisabled" fxLayoutAlign="start" 
      mat-icon-button [routerLink]="[{ outlets: { vigi: [left_link] } }]" 
      class="arrow_button">
      <mat-icon svgIcon="left_page_switch" class="primary-color" 
        [ngStyle]="{ 'color' : (leftButtonDisabled)? 'lightgray' : '' }">
      </mat-icon>
    </button> -->
    
    <div style="width: 100%; height: 100%; position: relative;">
        <router-outlet name="vigi"></router-outlet>
    </div>
    
    <!-- <button [disabled]="rightButtonDisabled" fxLayoutAlign="end"
      class="arrow_button"
      mat-icon-button [routerLink]="[{ outlets: { vigi: [right_link] } }]" >
      <mat-icon svgIcon="right_page_switch" class="primary-color" 
        [ngStyle]="{ 'color' : (rightButtonDisabled)? 'lightgray' : '' }">
      </mat-icon>
    </button> -->
</div>
