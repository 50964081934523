import { OnInit } from '@angular/core';
import * as OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { BackendUserService } from '../../../models_services/backend_user.service';
import { MapService } from '../../../models_services/map.service';
import { StoreService } from 'src/app/common/application.state';
import { Router } from '@angular/router';
var PobMapComponent = /** @class */ (function () {
    function PobMapComponent(growlService, mapService, backendUserService, storeService, router) {
        this.growlService = growlService;
        this.mapService = mapService;
        this.backendUserService = backendUserService;
        this.storeService = storeService;
        this.router = router;
        this.displayNavigationArrows = false;
        this.displayMarkerInfoDialog = false;
        this.isCursorActive = false;
        this.showPobsEmails = true;
        this.options = {
            center: { lat: 48.830772399902344, lng: 2.8097193241119385 },
            zoom: 9
        };
        this.overlays = [];
    }
    PobMapComponent.prototype.ngOnInit = function () {
        this.mapService.nullMapService();
        this.subscribeToDrawingPolygon();
    };
    Object.defineProperty(PobMapComponent.prototype, "bpiList", {
        set: function (bpiList) {
            this._bpiList = bpiList;
            this.refreshMapPOIs();
        },
        enumerable: true,
        configurable: true
    });
    PobMapComponent.prototype.setMap = function (event) {
        this.map = event.map;
        // window.wmap = this.map;
        this.map.setCenter(this.options.center);
        this.refreshMapPOIs();
    };
    PobMapComponent.prototype.refreshMapPOIs = function () {
        var _this = this;
        this.overlays.length = 0;
        if (!this._bpiList || this._bpiList.length === 0) {
            console.log("There are not locations to be displayed on the map");
        }
        if (this.map) { // map could be initialized later. When map is set, it will call this method again
            this.oms = new OverlappingMarkerSpiderfier(this.map, {
                markersWontMove: true,
                markersWontHide: true,
                basicFormatEvents: true
            });
            this.markersInfoMap = new Map();
            this.markerCluster = this.mapService.populateMapWithPath(this.map, this.markersInfoMap, this.overlays, this.oms, this.markerCluster, this._bpiList, this.handleOverlayClick, this.showPobsEmails);
            this.oms.addListener('click', function (marker, event) {
                _this.handleOverlayClick(marker);
            });
        }
    };
    // handleOverlayClick(event) if it comes from primeng with (onOverlayClick)="handleOverlayClick($event)"
    // event.overlay.labelContent
    // But here the event comes from the spiderfier oms and receives the marker as parameter
    PobMapComponent.prototype.handleOverlayClick = function (marker) {
        var label = marker.labelContent;
        this.selectedBPI = this.markersInfoMap.get(+(label.split('.')[0]));
        this.displayMarkerInfoDialog = true;
        // let requestTeamsObservable = this.backendUserService.getTeams(this.selectedBPI.pob_id);
        // let requestManagedTeamsObservable = this.backendUserService.getManaged(this.selectedBPI.pob_id);
        // // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        // forkJoin(requestTeamsObservable, requestManagedTeamsObservable).subscribe(
        //   (res: Array<any>) => {
        //     let teamsData = res[0];
        //     let managedTeamsData = res[1];
        //     if ( teamsData ) { this.selectedUserTeams = teamsData.teams; }
        //     if (managedTeamsData) { this.selectedUserManagedTeams = managedTeamsData.managed_teams; }
        //   }
        // );
    };
    PobMapComponent.prototype.onDialogClose = function () {
        this.displayMarkerInfoDialog = false;
    };
    PobMapComponent.prototype.moveNextLocation = function () {
        var result = this.mapService.moveNextLocation(this._bpiList);
        (result) ? this.map.setCenter(result) : this.options.center;
    };
    PobMapComponent.prototype.movePrevLocation = function () {
        var result = this.mapService.movePrevLocation(this._bpiList);
        (result) ? this.map.setCenter(result) : this.options.center;
    };
    PobMapComponent.prototype.moveToLastLocation = function () {
        var result = this.mapService.moveToLastLocation(this._bpiList);
        (result) ? this.map.setCenter(result) : this.options.center;
    };
    PobMapComponent.prototype.moveToFirstLocation = function () {
        var result = this.mapService.moveToFirstLocation(this._bpiList);
        (result) ? this.map.setCenter(result) : this.options.center;
    };
    PobMapComponent.prototype.onCursorClick = function () {
        console.log("onCursorClick");
        this.isCursorActive = !this.isCursorActive;
        if (this.isCursorActive) {
            this.mapService.initDrawingManager(this.map);
        }
        else {
            this.mapService.deactivateDrawingManager();
            this.mapService.clearDrawingManager();
        }
    };
    PobMapComponent.prototype.subscribeToDrawingPolygon = function () {
        var _this = this;
        this.mapService.getPolygonDrawingEndObservable().subscribe({
            next: function (result) {
                _this.polygonDrawingEndResult = result;
                console.log("PolygonDrawingEndResult: ");
                console.log(_this.polygonDrawingEndResult);
                if (_this.polygonDrawingEndResult && _this.polygonDrawingEndResult.length > 0) {
                    var selectedBPIsIds = [];
                    _this.polygonDrawingEndResult.forEach(function (pdr) {
                        var label = pdr.labelContent;
                        var basicPobInfo = _this.markersInfoMap.get(+(label.split('.')[0]));
                        selectedBPIsIds.push(basicPobInfo.pob_id);
                    });
                    selectedBPIsIds.forEach(function (element) {
                        console.log("selected bpi");
                        console.log(element);
                    });
                    _this.storeService.setHowAreYouPobIds(selectedBPIsIds);
                    // navigate to how are you
                    _this.router.navigate(['/home/push_notification']);
                }
                else {
                    _this.growlService.showInfoText("No locations were found in that polygon");
                    _this.isCursorActive = false;
                    _this.mapService.deactivateDrawingManager();
                    _this.mapService.clearDrawingManager();
                }
            },
            error: function (error) {
                console.error('Error:', error);
            }
        });
    };
    return PobMapComponent;
}());
export { PobMapComponent };
