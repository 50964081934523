import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var SSOLogsService = /** @class */ (function () {
    function SSOLogsService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    SSOLogsService.prototype.getAllWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/sso_logs', {
            params: httpParams,
            observe: 'body'
        });
    };
    SSOLogsService.prototype.delete = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/sso_logs/' + _id);
    };
    SSOLogsService.prototype.deleteBulkOfSSOLogs = function (logs) {
        var ids = [];
        if (logs && logs.length > 0) {
            ids = logs.map(function (a) { return a.id; });
        }
        return this.httpClient.post(this.config.apiUrl + '/sso_logs/bulkdelete', { "ids": ids });
    };
    SSOLogsService.prototype.downloadSSOLogsCSV = function () {
        return this.httpClient.get(this.config.apiUrl + '/sso_logs/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    SSOLogsService.ngInjectableDef = i0.defineInjectable({ factory: function SSOLogsService_Factory() { return new SSOLogsService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: SSOLogsService, providedIn: "root" });
    return SSOLogsService;
}());
export { SSOLogsService };
