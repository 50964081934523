import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Pole } from '../models/pole';

@Injectable({providedIn: 'root'})
export class PoleService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAllPoles() {
        return this.httpClient.get<any>(this.config.apiUrl + '/poles/all');
    }

    getAllAccessiblePoles(groupId: number, editedUserId: number) {
        editedUserId = editedUserId? editedUserId : 0;
        return this.httpClient.get<any>(this.config.apiUrl + '/poles/all_accessible/' + groupId + '/edited_user/' + editedUserId);
    }

    getPolesForGroups(groups){
        return this.httpClient.post(this.config.apiUrl + '/poles/get_by_groups', {'groups': groups});
    }

    getCountOfUsersForPoles(poleIds: number[]) {
        return this.httpClient.post<any>(this.config.apiUrl + '/poles/stats_for_users', {'pole_ids': poleIds});
    }

    // Get by ID
    getPoleById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/poles/' + _id);
    }

    // DELETE
    deletePole(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/poles/' + _id);
    }

    // UPDATE
    updatePole(pole: Pole) {
        return this.httpClient.put(this.config.apiUrl + '/poles/' + pole.id, pole);
    }

    // CREATE
    createPole(pole: Pole) {
        return this.httpClient.post(this.config.apiUrl + '/poles', pole);
    }

}
