import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var PanicService = /** @class */ (function () {
    function PanicService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    PanicService.prototype.getLocationPanicInfo = function (locationId) {
        return this.httpClient.get(this.config.apiUrl
            + '/location/'
            + locationId
            + '/panic/info');
    };
    PanicService.ngInjectableDef = i0.defineInjectable({ factory: function PanicService_Factory() { return new PanicService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: PanicService, providedIn: "root" });
    return PanicService;
}());
export { PanicService };
