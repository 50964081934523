import { Component, OnInit } from '@angular/core';
import { TipService } from '../../../models_services/tip.service';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { Tip } from '../../../models/tip';
import { SelectItem } from 'primeng/api';
import { AppConfig } from '../../../app.config';
import { ConfirmDialogsService } from 'src/app/_module_dialog/confirm_dialog/confirm-dialog.service';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.css']
})
export class TipsComponent implements OnInit {
  tip: Tip;
  tips: Tip[];


  tipDisplayDialog: boolean;
  selected: Tip;
  tipsSI: SelectItem[];
  selectedTipId: any = 0;
  formData: FormData = null;
  sendingContent = false;
  imageError = '';
  tipImgUrl = '';
  timestamp = 0;

  constructor(
    private tipService: TipService,
    private growlService: GrowlService,
    private appConfig: AppConfig,
    private confirmDService: ConfirmDialogsService
  ) {
    this.formData = new FormData();
    this.timestamp = (new Date()).getTime();
  }

  ngOnInit() {
    // this.tipService.getById(1).subscribe(
    //   (data) => {
    //     if (data.tip) {
    //       this.tip = data.tip;
    //     }
    //   }
    // );
    // for tip images
    this.loadAllTips();
  }

  loadAllTips() {
    this.tipService.getAll().subscribe(
        (data: any) => {
          this.tips = data.tips;
          this.tipImgUrl = this.tips[0].image_url + '/width/200/height/100/keep_ratio/1';
          this.selectedTipId = this.tips[0].id;
          // this.tipsSI = [];
          // data.forEach(c => {
          //     this.tipsSI.push({ label: c.name, value: c.id });
          // });
        }
    );
  }

  saveTip(tip) {
    this.tipService.update(tip).subscribe(
      (data) => {
        this.growlService.showInfoText('Changes are saved');
      },
      (error_data) => {
        if(error_data.error) {
          this.growlService.showError(error_data.error.msg_const);
        }
      }
    );
  }

  onTabChange(event){
    console.log(event);
    this.tipImgUrl = this.tips[event.index].image_url + '/width/200/height/100/keep_ratio/1';
    this.selectedTipId = this.tips[event.index].id;
  }

  // ========================================================================================
  // ================================Assets part of the form=================================
  // ========================================================================================
  // ========================================================================================

  imageChange(event) {
      console.log('imageChange');
      const fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        this.imageError = '';
        const file: File = fileList[0];
        this.formData.set('image', file, file.name);
      }
  }

    uploadNewImageForTip() {
        if (!this.selectedTipId) {
            this.growlService.showErrorText('Please select tip ID first');
            return;
        }
        console.log('uploadNewImageFortip');

        let someOfFilesIsMissing = false;
        if (!this.formData) {
            someOfFilesIsMissing = true;
            this.imageError = 'Image file is required';
        } else {
            if (!this.formData.get('image')) {
                someOfFilesIsMissing = true;
            }
            if (someOfFilesIsMissing) {
                if (!this.formData.get('imageCaptation')) {
                    this.imageError = 'Image file is required'
                }
            }
        }

        if (!someOfFilesIsMissing) {
            this.sendingContent = true;
            this.tipService.saveImageForTip(this.selectedTipId, this.formData).subscribe(
                (data: any) => {
                    this.timestamp = (new Date()).getTime();
                    this.growlService.showInfo('Image is saved');
                    this.sendingContent = false;
                },
                (data: any) => {
                    this.sendingContent = false;
                    this.growlService.showError(data.error.msg_const);
                }
            );
        }
    }
    
    onTipChange() {
        console.log('onTipChange');
        this.tipImgUrl = this.appConfig.apiUrl + '/tip_images/' + this.selectedTipId + '/image.jpeg/width/200/height/100/keep_ratio/1';
    }

    addItem() {
      this.confirmDService.show(
        'Create new tips', 
        'Are you sure that you want to create new tips?')
      .subscribe(res => {
        const result = res;
        if (res) {
          this.tipService.create().subscribe(
            () => {
              this.loadAllTips();
              this.growlService.showInfo('New Tip is created. Check it out in the new tap');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
    }

    deleteTip(tip: Tip) {
      this.confirmDService.show(
        'Delede tips', 
        'Are you sure that you want to delete this tip: "' + tip.name_en + '" ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          this.tipService.delete(tip.id).subscribe(
            () => {
              this.loadAllTips();
              this.growlService.showInfo('Tip is deleted.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
    }

}
