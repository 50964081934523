/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/flex-layout/flex";
import * as i2 from "@angular/flex-layout/core";
import * as i3 from "./_module_dialog/growl_dialog/growl.component.ngfactory";
import * as i4 from "./_module_dialog/growl_dialog/growl.component";
import * as i5 from "./_module_dialog/growl_dialog/growl.service";
import * as i6 from "./_module_core/spinner/spinner.component.ngfactory";
import * as i7 from "./_module_core/spinner/spinner.component";
import * as i8 from "./_module_core/logger.service";
import * as i9 from "./_module_core/spinner/spinner.service";
import * as i10 from "@angular/router";
import * as i11 from "./app.component";
import * as i12 from "./_services/myx-permissions.service";
import * as i13 from "@ngx-translate/core";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["fxFlexFill", ""]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i1.FlexFillDirective, [i0.ElementRef, i2.StyleUtils, i1.FlexFillStyleBuilder, i2.MediaMarshaller], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-growl", [], null, null, null, i3.View_GrowlComponent_0, i3.RenderType_GrowlComponent)), i0.ɵdid(3, 114688, null, 0, i4.GrowlComponent, [i5.GrowlService], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-spinner", [], null, null, null, i6.View_SpinnerComponent_0, i6.RenderType_SpinnerComponent)), i0.ɵdid(5, 245760, null, 0, i7.SpinnerComponent, [i8.LoggerService, i9.SpinnerService], null, null), (_l()(), i0.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(7, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i11.AppComponent, [i12.MyPermissionsService, i13.TranslateService], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
