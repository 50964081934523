import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var VIGISelectedCountryService = /** @class */ (function () {
    function VIGISelectedCountryService() {
        this.selectedCountryId = 81; // selected country is France by default
        this.countryIdUpdatedEvent = new EventEmitter();
    }
    VIGISelectedCountryService.prototype.setCountryId = function (selectedCountryId) {
        this.selectedCountryId = selectedCountryId;
        this.countryIdUpdatedEvent.emit(this.selectedCountryId);
    };
    VIGISelectedCountryService.ngInjectableDef = i0.defineInjectable({ factory: function VIGISelectedCountryService_Factory() { return new VIGISelectedCountryService(); }, token: VIGISelectedCountryService, providedIn: "root" });
    return VIGISelectedCountryService;
}());
export { VIGISelectedCountryService };
