<div>
  
    <p-tabView [style]="{'max-width':'100%'}">
      <p-tabPanel header="Location expiry days" [selected]="false" >
        <form [formGroup]="locForm" (ngSubmit)="onLocExpirySubmit()">
          <div class="p-field">
            <input type="text" 
              id="locationExpirityDays" 
              pInputText 
              formControlName="locationExpirityDays" 
              placeholder="Database Locations expiry days"
            />
            <small *ngIf="locForm.get('locationExpirityDays').errors?.pattern" class="p-error">
              Only numeric values allowed
            </small>
          </div>
          <button type="submit" pButton label="Save" [disabled]="!locForm.valid">
          </button>
        </form>
      </p-tabPanel>

      <p-tabPanel header="Travels expiry days" [selected]="false" >
        <form [formGroup]="traForm" (ngSubmit)="onTraExpirySubmit()">
          <div class="p-field">
            <input type="text" 
              id="travelExpirityDays" 
              pInputText 
              formControlName="travelExpirityDays" 
              placeholder="Database Travels expiry days"
            />
            <small *ngIf="traForm.get('travelExpirityDays').errors?.pattern" class="p-error">
              Only numeric values allowed
            </small>
          </div>
          <button type="submit" pButton label="Save" [disabled]="!traForm.valid">
          </button>
        </form>
      </p-tabPanel>

      <p-tabPanel header="Admin logs expiry days" [selected]="false" >
        <form [formGroup]="alogForm" (ngSubmit)="onAdminLogsExpirySubmit()">
          <div class="p-field">
            <input type="text" 
              id="adminLogExpirityDays" 
              pInputText 
              formControlName="adminLogExpirityDays" 
              placeholder="Database admin logs expiry days"
            />
            <small *ngIf="alogForm.get('adminLogExpirityDays').errors?.pattern" class="p-error">
              Only numeric values allowed
            </small>
          </div>
          <button type="submit" pButton label="Save" [disabled]="!alogForm.valid">
          </button>
        </form>
      </p-tabPanel>
    </p-tabView>
    
  

</div>
