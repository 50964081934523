<ngc-float-button icon="more_vert" style="position: fixed; bottom: 15px; right: 15px; z-index:1; " 
  pTooltip="Options">
  <ngc-float-item-button 
    *ngxPermissionsOnly="['CREATE_VIGI_REGIONS']" 
    pTooltip="Create new region" 
    icon="add" 
    (click)="addRegion()" content="">
  </ngc-float-item-button>

  <ngc-float-item-button 
    pTooltip="Export regions to xlsx"
    icon="file_download" 
    (click)="exportRegions()" content="">
  </ngc-float-item-button>
  
  <ngc-float-item-button 
    pTooltip="Import regions from xlsx"
    icon="file_upload" 
    (click)="importRegions()" content="">
  </ngc-float-item-button>
</ngc-float-button>

<!-- ======================= Regions =====================================-->
<!-- <div style="margin:10px" style="width: 100%;" *ngIf="regions && regions.length>0">
    <p-dropdown [options]="riskLevelsSI" [(ngModel)]="selectedRiskLevel" placeholder="Select risk level"></p-dropdown>

    <button mat-raised-button color="primary" type="button" (click)="saveVigiRegionDescr()"
        [disabled]="(!selectedRiskLevel || !selectedVigiRegion)? true: false">Save</button>
</div> -->

<div fxLayout="row" fxLayoutGap="10px">
    <p-table [value]="riskLevelsSI" 
        [autoLayout]="true"
        [style]="{'width':'100%'}">
        
        <ng-template pTemplate="header">
            <tr><th colspan="2">Regions</th></tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData >
            <tr>
                <td width="15%" *ngIf="rowData.value == 'LOW_RISK'" style="background-color:skyblue; color: white">{{rowData.label}}</td>
                <td width="15%" *ngIf="rowData.value == 'MODERATE_RISK'" style="background-color:yellow">{{rowData.label}}</td>
                <td width="15%" *ngIf="rowData.value == 'HIGH_RISK'" style="background-color:orange">{{rowData.label}}</td>
                <td width="15%" *ngIf="rowData.value == 'VERY_HIGH_RISK'" style="background-color:red; color: white">{{rowData.label}}</td>
                <td width="15%" *ngIf="rowData.value == 'WAR_ZONE'" style="background-color:black; color:white">{{rowData.label}}</td>
                <td>
                    <span *ngFor="let r of regions; last as isLast">
                        <span *ngIf="r.risk_level == rowData.value">
                            <a class="link"  (click)="onAreaSelect(r, 'region')">{{r.name}}</a>
                            <button *ngIf="r.risk_level == rowData.value"
                                type="button" pButton icon="fa fa-times" style="color: red; background-color: transparent;" 
                                (click)="deleteRegion(r)">
                            </button>
                            &nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-table [value]="riskLevelsSI" 
        [autoLayout]="true"
        [style]="{'width':'100%'}">
        
        <ng-template pTemplate="header">
            <tr><th colspan="2">Cities</th></tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData >
            <tr>
                <td width="15%" *ngIf="rowData.value == 'LOW_RISK'" style="background-color:skyblue; color: white">{{rowData.label}}</td>
                <td width="15%" *ngIf="rowData.value == 'MODERATE_RISK'" style="background-color:yellow">{{rowData.label}}</td>
                <td width="15%" *ngIf="rowData.value == 'HIGH_RISK'" style="background-color:orange">{{rowData.label}}</td>
                <td width="15%" *ngIf="rowData.value == 'VERY_HIGH_RISK'" style="background-color:red; color: white">{{rowData.label}}</td>
                <td width="15%" *ngIf="rowData.value == 'WAR_ZONE'" style="background-color:black; color:white">{{rowData.label}}</td>
                <td>
                    <span *ngFor="let r of cities; last as isLast">
                        <span *ngIf="r.risk_level == rowData.value">
                            <a class="link"  (click)="onAreaSelect(r, 'city')">{{r.name}}</a>
                            <button *ngIf="r.risk_level == rowData.value"
                                type="button" pButton icon="fa fa-times" style="color: red; background-color: transparent;" 
                                (click)="deleteRegion(r)">
                            </button>
                            &nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>


<p-dialog 
appendTo="body" 
header="Region details" 
[(visible)]="areaDisplayDialog" 
[responsive]="true" 
showEffect="fade" [modal]="true" [width]="600">
    <div class="ui-g ui-fluid" *ngIf="area">
        <div class="ui-g-12">
            <h2 *ngIf="!area.id">Create new area</h2>
            <h2 *ngIf="area.id">Edit area</h2>
            <h2 *ngIf="!area.id">Create new 
            <p-dropdown 
                id="area_type"
                [(ngModel)]="area.area_type"
                appendTo="body" 
                [options]="areaTypes">
            </p-dropdown>
            </h2>

            <div fxLayout="column">
                <div fxLayout="row">
                    <label for="vin">Name EN</label>
                    <input required class="input-normal" pInputText id="vin" [(ngModel)]="area.name" />
                </div>
                <div fxLayout="row">
                    <label for="vin">Name FR</label>
                    <input required class="input-normal" pInputText id="vin" [(ngModel)]="area.name_fr" />
                </div>
                <div fxLayout="row">
                    <label></label>
                    <p-dropdown
                        required
                        [options]="riskLevelsSI" [(ngModel)]="area.risk_level" 
                        class="input-normal"
                        placeholder="Select risk level"></p-dropdown>
                </div>
                <div fxLayout="row">
                    <label for="vin">Latitude</label>
                    <input class="input-normal" type="number" pInputText id="vin" [(ngModel)]="area.center_lat" />
                </div>
                <div fxLayout="row">
                    <label for="vin">Longitude</label>
                    <input class="input-normal" type="number" pInputText id="vin" [(ngModel)]="area.center_lon" />
                </div>
                <div fxLayout="row">
                    <label for="vin">Kilometers</label>
                    <input class="input-normal" type="number" pInputText id="vin" [(ngModel)]="area.km" />
                </div>
                <div class="ui-g-4">
                    <button [disabled]="(!area.name || !area.name_fr || !area.risk_level) ? true : false"
                        type="button" pButton icon="fa fa-check" (click)="saveRegion()" label="Save"></button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
