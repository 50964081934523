import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-are-you',
  templateUrl: './how-are-you.component.html',
  styleUrls: ['./how-are-you.component.css']
})
export class HowAreYouComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
