import { Group } from './group';
import { Pole } from './pole';
import { Division } from './division';

export class BackendUser {
    id: number;
    date_created: string;
    date_updated: string;
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    phone: string;
    role: string;
    group_id: number;
    pole_id: number;
    division_id: number;

    group: Group;
    pole: Pole;
    division: Division;

    construct() {
        this.role = 'ADMIN';
    }
}
