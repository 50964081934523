import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Company } from '../models/company';

@Injectable({providedIn: 'root'})
export class SSOLogsService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAllWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/sso_logs', {
            params: httpParams,
            observe: 'body'
        });
    }

    delete(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/sso_logs/' + _id);
    }

    deleteBulkOfSSOLogs(logs: any[]) {
        let ids = [];
        if(logs && logs.length > 0) { ids = logs.map(a => a.id); }
        return this.httpClient.post(this.config.apiUrl + '/sso_logs/bulkdelete', {"ids": ids});
    }

    downloadSSOLogsCSV() {
        return this.httpClient.get(this.config.apiUrl + '/sso_logs/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }
}
