import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var TipService = /** @class */ (function () {
    function TipService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    // getAll() {
    //     const tips = [
    //         { id: 'TEN_TIPS_FOR_SAFE', 'name': '10 Tips for safe road travel abroad' },
    //         { id: 'ADVICE_FOR_FEMALE', 'name': 'Advice for female travellers' },
    //         { id: 'GENERAL_RECOMENDATION', 'name': 'General recommendations' },
    //         { id: 'NATURAL_HAZARDS', 'name': 'Natural hazards' },
    //         { id: 'SOCIAL_RULE_AND_CONDUCT', 'name': 'Social rules and conduct in the Arab and Islamic world' },
    //         { id: 'TRAVELER_CHECKLIST', 'name': 'Traveller Checklist' },
    //     ];
    //     const observable = from(tips);
    //     return observable.pipe(
    //         toArray(),
    //     );
    // }
    TipService.prototype.getAll = function () {
        return this.httpClient.get(this.config.apiUrl + '/tips/all');
    };
    // Get by ID
    TipService.prototype.getById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/tips/' + _id);
    };
    TipService.prototype.update = function (tip) {
        return this.httpClient.put(this.config.apiUrl + '/tips/' + tip.id, tip);
    };
    TipService.prototype.saveImageForTip = function (tipId, formData) {
        console.log(formData);
        return this.httpClient.post(this.config.apiUrl + '/tips/' + tipId + '/image', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    };
    TipService.prototype.create = function () {
        return this.httpClient.post(this.config.apiUrl + '/tips', {});
    };
    TipService.prototype.delete = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/tips/' + _id);
    };
    TipService.ngInjectableDef = i0.defineInjectable({ factory: function TipService_Factory() { return new TipService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: TipService, providedIn: "root" });
    return TipService;
}());
export { TipService };
