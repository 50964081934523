import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GrowlService } from '../../../../_module_dialog/growl_dialog/growl.service';
import { MapService } from '../../../../models_services/map.service';
import * as MarkerClusterer from "@google/markerclusterer";
import * as OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
import { VigiRegion } from '../../../../models/vigiregion';
import { forkJoin } from 'rxjs';
import { VigiRegionService } from '../../../../models_services/vigiregion.service';
import { VIGISelectedCountryService } from '../../selected-country.service';
import { AppConfig } from '../../../../app.config';
import { CountryService } from '../../../../models_services/country.service';
import { VigiCountryService } from '../../../../models_services/vigicountry.service';
import { VigiCountry } from '../../../../models/vigicountry';
import { Country } from '../../../../models/country';

declare var geoXML3: any;

@Component({
  selector: 'app-vigi-country-map',
  templateUrl: './vigi-country-map.component.html',
  styleUrls: ['./vigi-country-map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VigiCountryMapComponent implements OnInit {
  // IT IS NOT USED AND NO KML FILES ARE FOUND IN THE PROJECT
  // The goal of this file is to have overlay with specific color over the map
  // while the country color is matching the level of warning for the country or region
  selectedCountryId;
  displayMarkerInfoDialog = false;
  country: Country;
  countryIdsLoadedAsOverlays = [];
  vigiCountry: VigiCountry;
  regions: VigiRegion[];
  selectedVigiRegion: VigiRegion;
  markersInfoMap: Map<number, VigiRegion>;
  markerCluster: MarkerClusterer;
  showPobsEmails: boolean = true;

  oms: any;
  options = {
    center: {lat: 48.830772399902344, lng: 2.8097193241119385},
    zoom: 9
  };
  overlays = [];
  map: google.maps.Map;

  constructor(
    private vigiSelectedCountryService: VIGISelectedCountryService,
    private growlService: GrowlService,
    private mapService: MapService,
    private vigiRegionService: VigiRegionService,
    private config: AppConfig,
    private countryService: CountryService,
    private vigiCountryService: VigiCountryService,
  ) {
    this.selectedCountryId = this.vigiSelectedCountryService.selectedCountryId;
    console.log('SelectedCountryId:' + this.selectedCountryId);
    this.getVigiInfo(this.selectedCountryId);
  }

  ngOnInit() {
    this.vigiSelectedCountryService.countryIdUpdatedEvent.subscribe(
      (val) => {
        this.getVigiInfo(val);
      }
    )
  }

  getVigiInfo(countryId: number) {
    let countryGet = this.countryService.getById(countryId);
    let vigiRegionsGet = this.vigiRegionService.getVigiRegionsWithCoordinatesForCountryId(countryId);

    forkJoin([countryGet, vigiRegionsGet]).subscribe(results => {
      this.country = results[0].country;
      this.vigiCountry = (results[1].vigicountry) ? results[1].vigicountry : {'risk_level': 'LOW_RISK'};
      this.regions = (results[1].vigiregions) ? results[1].vigiregions : [];
      this.refreshMapPOIs();
    });
  }

  setMap(event) {
    this.map = event.map;
    // window.wmap = this.map;
    this.map.setCenter(this.options.center);
    this.refreshMapPOIs();
    // WARNING https://stackoverflow.com/questions/3514785/loading-a-local-kml-file-using-google-maps
    // google needs outside url for this to work
    // kml files are taken from https://download.geofabrik.de/
  }

  refreshMapPOIs(){
    this.overlays.length = 0;
    if (!this.regions || this.regions.length === 0) {
      console.log("There are not locations to be displayed on the map");
    }

    // WARNING THIS PEACE OF CODE WORKS ONLY WHEN DEPLOYED, because google needs outside url for this to work
    if(this.country && this.countryIdsLoadedAsOverlays.indexOf(this.country.id) < 0 ) {
      var geoXML3Parser = new geoXML3.parser({
        map: this.map,
        singleInfoWindow: false,
        vigiCountry: this.vigiCountry,
        afterParse: this.useGeoXMLData
      });
      this.countryIdsLoadedAsOverlays.push(this.country.id);

      geoXML3Parser.parse('/static/kml_files/_' + this.country.code_three_letter.toLocaleUpperCase() + '.kml');
      this.countryIdsLoadedAsOverlays.push(this.country.id);
    }
    
    if (this.map) { // map could be initialized later. When map is set, it will call this method again
      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true
      });
      this.markersInfoMap = new Map<number, VigiRegion>();
      this.markerCluster = this.mapService.populateMapWithPath(this.map, this.markersInfoMap, this.overlays, 
        this.oms, this.markerCluster, this.regions, this.handleOverlayClick, this.showPobsEmails);
      this.oms.addListener('click', (marker, event) => {
        this.handleOverlayClick(marker);
      });
    }
  }

  useGeoXMLData(doc){
    console.log('DOC: ');
    console.log(doc);
    console.log('THIS');
    console.log(this);
    var geoXmlDoc = doc[0];

    if (this.vigiCountry) {
      switch (this.vigiCountry.risk_level) {
        case 'LOW_RISK':
          console.log('LOW_RISK');
          geoXmlDoc.gpolygons[0].setOptions({fillColor: "#11AAFF", strokeColor: "#0000cc"});
          break;
        case 'MODERATE_RISK':
          console.log('MODERATE_RISK');
          geoXmlDoc.gpolygons[0].setOptions({fillColor: "#ffff55", strokeColor: "#cccc00"});
          break;
        case 'HIGH_RISK':
          console.log('HIGH_RISK');
          geoXmlDoc.gpolygons[0].setOptions({fillColor: "#ff5500", strokeColor: "#ffa500"});
          break;
        case 'VERY_HIGH_RISK':
          console.log('VERY_HIGH_RISK');
          geoXmlDoc.gpolygons[0].setOptions({fillColor: "#cc0000", strokeColor: "#cc0000"});
          break;
        case 'WAR_ZONE':
          console.log('WAR_ZONE');
          geoXmlDoc.gpolygons[0].setOptions({fillColor: "#333333", strokeColor: "#000000"});
          break;
        default:
          break;
      }
    } else {
      console.log('No vigiCountry');
      geoXmlDoc.gpolygons[0].setOptions({fillColor: "#11AAFF", strokeColor: "#0000cc"});
    }
  };

  // handleOverlayClick(event) if it comes from primeng with (onOverlayClick)="handleOverlayClick($event)"
  // event.overlay.labelContent
  // But here the event comes from the spiderfier oms and receives the marker as parameter
  handleOverlayClick(marker) {
    const label: string = marker.labelContent;
    this.selectedVigiRegion = this.markersInfoMap.get(+(label.split('.')[0]));
    this.displayMarkerInfoDialog = true;
  }

}
