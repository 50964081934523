import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VigiRegion } from '../models/vigiregion';

@Injectable({providedIn: 'root'})
export class VigiRegionService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAllVigiRegions() {
        return this.httpClient.get<any>(this.config.apiUrl + '/vigiregions/all');
    }

    /**
     * returns vigiregions list if any for the vigicountry with the countryId
     */
    getVigiRegionsForCountryId(countryId: number){
        return this.httpClient.get(this.config.apiUrl + '/vigiregions/country/' + countryId);
    }

    getVigiAreasRegionsForCountryId(countryId: number){
        return this.httpClient.get(this.config.apiUrl + '/vigiareas/country/' + countryId + '/regions');
    }

    getVigiAreasCitiesForCountryId(countryId: number){
        return this.httpClient.get(this.config.apiUrl + '/vigiareas/country/' + countryId + '/cities');
    }

    getVigiRegionsWithCoordinatesForCountryId(countryId: number){
        return this.httpClient.get<any>(this.config.apiUrl + '/vigiregions/with_coord/country/' + countryId);
    }

    // Get by ID
    getVigiRegionById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/vigiregions/' + _id);
    }

    exportRegionsInXLSX(selectedCountryId: number) {
        return this.httpClient.get(
            this.config.apiUrl + '/vigiregions/country/' + selectedCountryId + '/export_regions_in_xlsx',  {
            headers: new HttpHeaders().set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    importRegionsFromXLSX(formData: FormData, selectedCountryId: number) {
        console.log(formData);
        return this.httpClient.post(
            this.config.apiUrl + '/vigiregions/country/' + selectedCountryId + '/import_regions_from_xlsx', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    }

    
    // Fact Sheet descr methods
    saveDescrForRegion(factSheetDescription, regionId: number) {
        return this.httpClient.put(
            this.config.apiUrl + '/vigiregions/' + regionId + '/fact_sheet_descr', factSheetDescription);
    }
    
    getFactSheetDescrForSelectedRegionAndSheetType(vigiRegionId: number, selectedFactSheetTypeId) {
        return this.httpClient.get<any>(this.config.apiUrl + '/vigiregions/' + vigiRegionId + '/fact_sheet_type/' + selectedFactSheetTypeId);
    }
    // END Fact Sheet descr methods

    // DELETE
    deleteVigiRegion(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/vigiregions/' + _id);
    }

    // UPDATE
    updateVigiRegion(vc: VigiRegion) {
        return this.httpClient.put(this.config.apiUrl + '/vigiregions/' + vc.id, vc);
    }

    // CREATE
    createVigiRegion(vc: VigiRegion) {
        return this.httpClient.post(this.config.apiUrl + '/vigiregions', vc);
    }

}
