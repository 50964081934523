import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { SpinnerService } from '../spinner/spinner.service';
import { tap, map, catchError, mergeMap } from 'rxjs/operators';
import { Observable, pipe, throwError } from 'rxjs';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';

export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private spinnerService: SpinnerService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {  // Observable<HttpEvent<any>>
        // console.log('INTERCEPT', req, localStorage.getItem('auth_token'));
        const copiedReq = this.clearMultipartFormHeader(this.authenticateRequest(req));
        // console.log('*Updated httpRequest*', copiedReq);
        this.spinnerService.show();
        // const some = next.handle(copiedReq).do((event: HttpEvent<any>) => {
        //     if (event instanceof HttpResponse) {this.spinnerService.hide(); }
        // });
        return next.handle(copiedReq).pipe(
            tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {this.spinnerService.hide(); }
            }),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('*An intercepted httpResponse*', event);
                    return event;
                }
            }),
            catchError((error: any) => {
                this.spinnerService.hide();
                console.log('HTTP Server error:' + error.status);
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {  // && error.url !== environment.authEndpoint
                        this.router.navigate(['/login']);
                        return throwError(error);
                        // return this.authService.token().pipe(
                        //     mergeMap(
                        //         (data: any) => {
                        //         localStorage.setItem('auth_token', data.token);
                        //         const authReqRepeat = this.authenticateRequest(req);
                        //         console.log('*Repeating httpRequest*', authReqRepeat);
                        //         return next.handle(authReqRepeat)
                        //             .catch((reqRpeatError: any) => {
                        //                 if (error instanceof HttpErrorResponse) {
                        //                     if (error.status === 401) {  // && error.url !== environment.authEndpoint
                        //                         setTimeout(() => { this.router.navigate(['/login']); }, 1000);
                        //                         return next.handle(authReqRepeat);
                        //                     }
                        //                     return next.handle(req);
                        //                 } else {
                        //                     return throwError(error);
                        //                 }
                        //             });
                        //         }
                        //     ),
                        //     catchError( 
                        //         (err: any) => {
                        //             console.error(err);
                        //             this.router.navigate(['/login']);
                        //             return throwError(error);
                        //         }
                        //     )
                        // );
                    } else if (error.status === 500 || error.status === 0) {
                        error.error.msg_const = 'ERROR_SERVER';
                    }
                    return throwError(error);
                } else {
                    return throwError(error);
                }
            })
        );
    }

    clearMultipartFormHeader(req) {
        if (req.headers.get('Content-Type') === 'multipart/form-data') {
            // we delete the multipart so that the server to automaticaly determine what is the type of the req
            return req.clone({ headers: req.headers.delete('Content-Type') });
        }
        return req;
    }

    authenticateRequest(req) {
        const auth_header = 'Basic ' + btoa(localStorage.getItem('auth_token') + ':');
        return req.clone({ headers: req.headers.set('Authorization', auth_header) });
    }

}
