import { Division } from './division';
import { Country } from './country';
import { MobileUser } from './mobile-user';
import { BackendUser } from './backend-user';

export class Alert {
    id: number;
    deleted: boolean;
    mobileuser_id: number;

    type: string;
    type_str: string;
    type_of_panic: string;

    is_hidden: boolean;

    title: string;
    content: string;
    title_fr: string;
    content_fr: string;
    time: number;
    payload: string;

    vigi_country_id: number;
    vigi_region_id: number;

    group_id: number;
    pole_id: number;
    division_id: number;

    alerted_divisions: Division[];                       
    alerted_mobileusers: MobileUser[];
    alerted_subsciption_countries: Country[];
    alerted_country_localizations: Country[];
    last_location_country_id: number;
    last_location_country_name: string;
    new_location_country_id: number;
    new_location_country_name: string;
    sender: BackendUser;

    how_are_yous: any[]

    construct() {
    }
}
