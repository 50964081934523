import { Injectable, EventEmitter } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VigiDescr } from '../models/vigidescr';

@Injectable({providedIn: 'root'})
export class VigiDescriptionService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    // Get by ID
    getById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/vigidescr/' + _id);
    }

    update(vigiDescr: VigiDescr) {
        return this.httpClient.put(this.config.apiUrl + '/vigidescr/' + vigiDescr.id, vigiDescr);
    }

}