import { Injectable, EventEmitter } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Tip } from '../models/tip';
import { from } from 'rxjs';
import { toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class TipService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    // getAll() {
    //     const tips = [
    //         { id: 'TEN_TIPS_FOR_SAFE', 'name': '10 Tips for safe road travel abroad' },
    //         { id: 'ADVICE_FOR_FEMALE', 'name': 'Advice for female travellers' },
    //         { id: 'GENERAL_RECOMENDATION', 'name': 'General recommendations' },
    //         { id: 'NATURAL_HAZARDS', 'name': 'Natural hazards' },
    //         { id: 'SOCIAL_RULE_AND_CONDUCT', 'name': 'Social rules and conduct in the Arab and Islamic world' },
    //         { id: 'TRAVELER_CHECKLIST', 'name': 'Traveller Checklist' },
    //     ];
    //     const observable = from(tips);
    //     return observable.pipe(
    //         toArray(),
    //     );
    // }

    getAll() {
        return this.httpClient.get<any>(this.config.apiUrl + '/tips/all');
    }

    // Get by ID
    getById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/tips/' + _id);
    }

    update(tip: Tip) {
        return this.httpClient.put(this.config.apiUrl + '/tips/' + tip.id, tip);
    }

    saveImageForTip(tipId: string, formData: FormData) {
        console.log(formData);
        return this.httpClient.post(this.config.apiUrl + '/tips/' + tipId + '/image', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    }

    create() {
        return this.httpClient.post(this.config.apiUrl + '/tips', {});
    }

    delete(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/tips/' + _id);
    }

}
