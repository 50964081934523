<div #scrollMe>
    <h3 fxLayoutAlign="left center">Domain whitelist for sponsored users</h3>
    <div style="padding:15px" fxLayoutAlign="center" #scrollMe>
        <div fxLayout="row">
    
            <!-- ======================= Domains =====================================-->
            <div style="margin:10px">
                <p-table [value]="domains" selectionMode="single" 
                [(selection)]="selectedDomain" (onRowSelect)="onDomainRowSelect($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th colspan="1">
                                <button type="button" pButton icon="fa fa-plus" 
                                (click)="showDomainDialogToAdd(rowData)" label="Add"></button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData >
                        <tr [pSelectableRow]="rowData"> 
                            <td>@{{rowData.domain}}</td>
                        </tr>
                    </ng-template>
                </p-table>
    

                <p-dialog appendTo="body" header="Domain details" [(visible)]="domainDisplayDialog" 
                    showEffect="fade" [modal]="true" [style]="{width: '50em'}">
                    <div fxLayout="row" fxLayout.lt-sm="column" *ngIf="domain">
                        <label for="vin">Name</label>
                        <div style="font-size: 1.2rem">
                            <b>@</b><input pInputText id="vin" [(ngModel)]="domain.domain" />
                        </div>
                    </div>
                    <p-footer>
                        <div class="ui-dialog-buttonpane ui-helper-clearfix">
                            <button type="button" pButton icon="fa fa-close" (click)="deleteDomain()" label="Delete"></button>
                            <button type="button" pButton icon="fa fa-check" (click)="saveDomain()" label="Save"></button>
                        </div>
                    </p-footer>
                </p-dialog>
            </div>
        </div>
    </div>
</div>
