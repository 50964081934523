import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { AdminLogsService } from 'src/app/models_services/admin_logs_service';
import { AdminLog } from 'src/app/models/admin_log';

import { LazyLoadEvent } from 'primeng/components/common/api';


@Component({
  selector: 'app-admin-logs',
  templateUrl: 'admin-logs.component.html',
  styleUrls: ['admin-logs.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminLogsComponent implements OnInit {
  userId: number = 0;
  asyncAdminLogs: Observable<AdminLog[]>;
  selectedAdminLog: AdminLog = null
  count: 0;
  currentPage = 1;
  loading: boolean;
  displayAdminLogJSONInfoDialog: boolean = false;

  // server side filter
  sortBy: string;
  orderAsc: string;

  params = {
    page: 1, per_page: 25, order: '-date_created',
    'email': { operator: '%', value: '' },
    'firstname': { operator: '%', value: '' },
    'lastname': { operator: '%', value: '' }
  };

  constructor(
    private adminLogsService: AdminLogsService,
    private growlService: GrowlService
    ) {
  }

  ngOnInit() {
    this.loadAllAdminLogs();
  }

  showAdminLogInfo(al: AdminLog) {
    this.selectedAdminLog = al;
    console.log(this.selectedAdminLog.json_data);
    this.displayAdminLogJSONInfoDialog = true;
  }

  // =================================================================
  private loadAllAdminLogs() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.params.page = page;
    this.asyncAdminLogs = this.adminLogsService.getAllWithParams(this.params).pipe(
      tap((data: any) => {
        this.count = data.count;
        this.loading = false;
        this.currentPage = page;
      }),
      map(data => {
        return data.admin_logs;
      })
    )
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    this.params.email.value = event.filters['email'] ? event.filters['email'].value : '';
    this.params.firstname.value = event.filters['firstname'] ? event.filters['firstname'].value : '';
    this.params.lastname.value = event.filters['lastname'] ? event.filters['lastname'].value : '';
    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

}
