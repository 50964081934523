import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Airport } from '../../../models/airport';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { AirportService } from 'src/app/models_services/airport.service';

@Component({
  selector: 'app-airport-edit',
  templateUrl: 'airport-edit.component.html',
  styles: []
})
export class AirportEditComponent implements OnInit, OnDestroy {
  currentAirport: Airport;
  airport: Airport = new Airport();
  subscription: Subscription;
  airportForm: FormGroup = new FormGroup({});
  isNew = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private airportService: AirportService,
    private fromBuilder: FormBuilder,
    private growlService: GrowlService) {
      this.currentAirport = JSON.parse(localStorage.getItem('current_airport'));
    }

  ngOnInit() {
    this.initForm();
    this.loadTheAirport();
  }

  onDivisionChange(){
  }

  loadTheAirport() {
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        if (params.hasOwnProperty('id')) {
          this.isNew = false;
          this.airportForm.controls.id.disable();
          this.airportService.getById(params['id']).subscribe(data => {
            console.log("Airport:" + data.airport);
            this.airport = data.airport;
            if (this.airport != null) {
              this.initForm();
            }
          });
        }
      }
    );
  }

  private initForm() {
    let formGroupObject = <any>{};
    formGroupObject = {
      id: new FormControl({value: this.airport.id, disabled: false},
        [Validators.required, Validators.pattern('[A-Z][A-Z][A-Z]')]),
      name: new FormControl(this.airport.name, Validators.required),
      city_name: new FormControl(this.airport.city_name, Validators.required),
      country: new FormControl(this.airport.country, [Validators.required]),
      country_code: new FormControl(this.airport.country_code, 
        [Validators.required, Validators.pattern('[A-Z][A-Z]')]),
      latitude: new FormControl(this.airport.latitude, [Validators.required]),
      longitude: new FormControl(this.airport.longitude, [Validators.required]),
      timezone: new FormControl(this.airport.timezone, [Validators.required]),
      is_custom_created: new FormControl(this.airport.is_custom_created),
    };
    this.airportForm = new FormGroup(formGroupObject);
  }

  onSubmit() {
    const editedAirport = this.airportForm.value;
    console.log(editedAirport);
    if (this.isNew) {
      this.airportService.create(editedAirport).subscribe(
        (data) => {
          this.growlService.showInfo('Airport is created');
          this.navigateBack();
        },
        (error_data: any) => {
          console.log(error_data.error);
          this.growlService.showError(error_data.error.msg_const);
        }
      );
    } else {
      editedAirport.id = this.airport.id;
      this.airportService.update(editedAirport).subscribe(
        data => {
          this.growlService.showInfo('Airport is updated');
          this.navigateBack();
        },
        (error_data: any) => {
          console.log(error_data.error);
          this.growlService.showError(error_data.error.msg_const);
        }
      );
    }
  }

  private navigateBack() {
    this.router.navigate(['/home/airports']);
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
