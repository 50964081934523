/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./info-dialog.component";
var styles_InfoDialogComponent = [];
var RenderType_InfoDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InfoDialogComponent, data: {} });
export { RenderType_InfoDialogComponent as RenderType_InfoDialogComponent };
export function View_InfoDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h3", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "p", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(9, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["OK"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 9).disabled || null); var currVal_4 = (i0.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_3, currVal_4); }); }
export function View_InfoDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-dialog", [], null, null, null, View_InfoDialogComponent_0, RenderType_InfoDialogComponent)), i0.ɵdid(1, 49152, null, 0, i7.InfoDialogComponent, [i1.MatDialogRef], null, null)], null, null); }
var InfoDialogComponentNgFactory = i0.ɵccf("app-confirm-dialog", i7.InfoDialogComponent, View_InfoDialogComponent_Host_0, {}, {}, []);
export { InfoDialogComponentNgFactory as InfoDialogComponentNgFactory };
