import { PoleService } from '../models_services/pole.service';
import { DivisionService } from '../models_services/division.service';
import { GroupService } from '../models_services/group.service';
import { Group } from '../models/group';
import { Pole } from '../models/pole';
import { Division } from '../models/division';
import { MyPermissionsService } from '../_services/myx-permissions.service';
import { Subject } from 'rxjs/Subject';
import * as i0 from "@angular/core";
import * as i1 from "../models_services/pole.service";
import * as i2 from "../models_services/division.service";
import * as i3 from "../models_services/group.service";
import * as i4 from "../_services/myx-permissions.service";
var TreeDataService = /** @class */ (function () {
    function TreeDataService(poleService, divisionService, groupService, permissionService) {
        this.poleService = poleService;
        this.divisionService = divisionService;
        this.groupService = groupService;
        this.permissionService = permissionService;
        this.poles = [];
        this.groups = [];
        this.divisions = [];
        this.polesMap = new Map();
        this.polesSubject = new Subject();
        this.divisionsSubject = new Subject();
        this.groupsSubject = new Subject();
        this.treeNodeSubject = new Subject();
    }
    TreeDataService.prototype.loadTreeCollections = function (currentUser) {
        var _this = this;
        this.permissionService.checkForPermissions(['GROUP_CRUD', 'POLE_CRUD', 'DIVISION_CRUD']).then(function (results) {
            if (results[0]) {
                _this.canCRUDGroup = true;
            }
            if (results[1]) {
                _this.canCRUDPole = true;
            }
            if (results[2]) {
                _this.canCRUDDivision = true;
            }
            switch (currentUser.role) {
                case 'MASTER_ADMIN':
                    _this.groupService.getAllGroups().subscribe(function (groups_data) {
                        _this.groups = (groups_data.groups) ? groups_data.groups : [];
                        _this.poleService.getAllPoles().subscribe(function (poles_data) {
                            _this.poles = (poles_data.poles) ? poles_data.poles : [];
                            if (_this.poles) {
                                _this.poles.forEach(function (element) { _this.polesMap.set(element.id, element); });
                            }
                            _this.divisionService.getAllDivisions().subscribe(function (divisions_data) {
                                _this.divisions = (divisions_data.divisions) ? divisions_data.divisions : [];
                                _this.buildTree();
                            });
                        });
                    });
                    break;
                case 'GROUP_ADMIN':
                    _this.groupService.getGroupById(currentUser.group_id).subscribe(function (group_data) {
                        _this.groups = (group_data.group) ? [group_data.group] : [];
                        _this.poleService.getPolesForGroups(_this.groups).subscribe(function (poles_data) {
                            _this.poles = (poles_data.poles) ? poles_data.poles : [];
                            _this.divisionService.getDivisionsForPoles(_this.poles).subscribe(function (divisions_data) {
                                _this.divisions = (divisions_data.divisions) ? divisions_data.divisions : [];
                                _this.buildTree();
                            });
                        });
                    });
                    break;
                case 'POLE_ADMIN':
                    _this.poleService.getPoleById(currentUser.pole_id).subscribe(function (pole_data) {
                        _this.poles = [pole_data.pole];
                        _this.groupService.getGroupById(pole_data.pole.group_id).subscribe(function (group_data) {
                            _this.groups = [group_data.group];
                            _this.divisionService.getDivisionsForPoles(_this.poles).subscribe(function (divisions_data) {
                                _this.divisions = (divisions_data.divisions) ? divisions_data.divisions : [];
                                _this.buildTree();
                            });
                        });
                    });
                    break;
                case 'DIVISION_ADMIN':
                    _this.divisionService.getDivisionById(currentUser.division_id).subscribe(function (division_data) {
                        _this.divisions = [division_data.division];
                        _this.poleService.getPoleById(division_data.division.pole_id).subscribe(function (pole_data) {
                            _this.poles = [pole_data.pole];
                            _this.groupService.getGroupById(pole_data.pole.group_id).subscribe(function (group_data) {
                                _this.groups = [group_data.group];
                                _this.buildTree();
                            });
                        });
                    });
                    break;
                default:
                    break;
            }
        });
    };
    TreeDataService.prototype.buildTree = function () {
        var _this = this;
        var groups_trees = [];
        this.groups.forEach(function (g) {
            var poles_trees = [];
            _this.poles.forEach(function (p) {
                if (p.group_id == g.id) {
                    var divisions_trees_1 = [];
                    _this.divisions.forEach(function (d) {
                        if (d.pole_id == p.id) {
                            var division = {
                                'label': d.name_en,
                                'selectable': (_this.canCRUDDivision) ? true : false,
                                'data': { 'object': d, 'type': Division },
                            };
                            divisions_trees_1.push(division);
                        }
                    });
                    var pole_tree = {
                        'label': p.name_en,
                        'selectable': (_this.canCRUDPole) ? true : false,
                        'children': divisions_trees_1,
                        'expanded': false,
                        'data': { 'object': p, 'type': Pole },
                    };
                    poles_trees.push(pole_tree);
                }
            });
            var group_tree = {
                'label': g.name_en,
                'selectable': (_this.canCRUDGroup) ? true : false,
                'children': poles_trees,
                'expanded': false,
                'data': { 'object': g, 'type': Group },
            };
            groups_trees.push(group_tree);
        });
        // const root_tree: TreeNode = {
        //     'label': 'Groups',
        //     'children': groups_trees,
        //     'selectable': false,
        //     'expanded': false
        // };
        // this._processTreeNodes(groups_trees, root_tree);
        this.treeNodeSubject.next(groups_trees);
    };
    TreeDataService.prototype._processTreeNodes = function (treeNode, parent) {
        for (var _i = 0, treeNode_1 = treeNode; _i < treeNode_1.length; _i++) {
            var node = treeNode_1[_i];
            if (parent != null) {
                node.data.parent = parent;
            }
            if (node.children && node.children.length > 0) {
                this._processTreeNodes(node.children, node);
            }
        }
    };
    TreeDataService.ngInjectableDef = i0.defineInjectable({ factory: function TreeDataService_Factory() { return new TreeDataService(i0.inject(i1.PoleService), i0.inject(i2.DivisionService), i0.inject(i3.GroupService), i0.inject(i4.MyPermissionsService)); }, token: TreeDataService, providedIn: "root" });
    return TreeDataService;
}());
export { TreeDataService };
