import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Company } from '../../../models/company';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';
import 'rxjs/add/operator/debounceTime';
import { CompanyService } from '../../../models_services/company.service';
import { DivisionService } from '../../../models_services/division.service';
import { PoleService } from '../../../models_services/pole.service';
import { GroupService } from '../../../models_services/group.service';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company_edit.component.html',
  styleUrls: ['./company_edit.component.css'],
  encapsulation: ViewEncapsulation.None // this is bug fix , to have the css working
})
export class CompanyEditComponent implements OnInit, OnDestroy {
  company: Company = new Company();
  private subscription: Subscription;
  companyForm: FormGroup = new FormGroup({});
  isNew = true;

  dropdowns = {
    'poles': [],
    'divisions': [],
    'groups': [],
    'selected_poles': [],
    'selected_divisions': [],
    'selected_groups': [],
    'map_groups': new Map(),
    'map_poles': new Map(),
    'map_divisions': new Map(),
  };

  cities_suggestions: string[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private divisionService: DivisionService,
    private poleService: PoleService,
    private groupService: GroupService,
    private formBuilder: FormBuilder,
    private growlService: GrowlService) {
    Date.prototype.toJSON = function () { return moment(this).format(); };
  }

  ngOnInit() {
    this.initForm();
    this.loadTheCompany();
  }

  loadTheCompany() {
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        if (params.hasOwnProperty('id')) {
          this.isNew = false;
          this.companyService.getCompanyById(params['id']).subscribe(
            company_data => {
              this.company = company_data.company;
              this.loadCompanyCollections();
              this.initForm();
              this.loadAllGroups();
            },
            data => this.growlService.showError(data.error.msg_const)
          );
        } else {
          this.initForm();
          this.loadAllGroups();
        }
      }
    );
  }

  loadCompanyCollections() {
    // groups
    this.loadSelectedGroups();
    // poles
    this.loadSelectedPoles();
    // divisions
    this.loadSelectedDivisions();
  }
  loadSelectedPoles() {
    if (this.company.poles) {
      this.dropdowns['selected_poles'] = this.company.poles.map(obj => obj.name_en);
    }
  }
  loadSelectedGroups() {
    if (this.company.groups) {
      this.dropdowns['selected_groups'] = this.company.groups.map(obj => obj.name_en);
    }
  }
  loadSelectedDivisions() {
    if (this.company.divisions) {
      this.dropdowns['selected_divisions'] = this.company.divisions.map(obj => obj.name_en);
    }
  }

  // TODO to create services on the backend
  loadAllGroups() {
    this.groupService.getAllGroups().subscribe(
      (data) => {
        if (data.groups) {
          this.dropdowns['map_groups'] = new Map();
          data.groups.forEach(element => {
            this.dropdowns['groups'].push({ value: element.name_en, label: element.name_en });
            this.dropdowns['map_groups'].set(element.name_en, element);
          });
        }
        this.reloadPoles(false);
      });
  }

  /**
   * It reloads poles after dicision is changed
   * All previously selected poles are deselected
   */
  reloadPoles(clearSelected) {
    const editedCompany = {
      ...this.companyForm.value.main,
    };
    this.dropdowns['map_poles'] = new Map();
    this.dropdowns['poles'] = [];
    if (clearSelected) {
      this.companyForm.get('main').get('selected_poles').patchValue([]);
      this.companyForm.get('main').get('selected_divisions').patchValue([]);
    }
    this.poleService.getPolesForGroups(editedCompany['selected_groups'].map(name_en => this.dropdowns['map_groups'].get(name_en))).subscribe(
      (data: any) => {
        if (data.poles) {
          data.poles.forEach(element => {
            this.dropdowns['poles'].push({ value: element.name_en, label: element.name_en });
            this.dropdowns['map_poles'].set(element.name_en, element);
          });
          this.reloadDivisions(clearSelected);
        }
      });
  }

  /**
   * It reloads divisions after pole is changed.
   * All previously selected divisions are deselected
   */
  reloadDivisions(clearSelected) {
    const editedCompany = {
      ...this.companyForm.value.main,
    };
    this.dropdowns['map_divisions'] = new Map();
    this.dropdowns['divisions'] = [];
    if (clearSelected) {
      this.companyForm.get('main').get('selected_divisions').patchValue([]);
    }
    this.divisionService.getDivisionsForPoles(editedCompany['selected_poles'].map(name_en => this.dropdowns['map_poles'].get(name_en))).subscribe(
      (data: any) => {
        if (data.divisions) {
          data.divisions.forEach(element => {
            this.dropdowns['divisions'].push({ value: element.name_en, label: element.name_en });
            this.dropdowns['map_divisions'].set(element.name_en, element);
          });
        }
      });
  }

  private initForm() {
    this.companyForm = this.formBuilder.group({
      'main': this.formBuilder.group({
        'id': [this.company.id, [Validators.required]],
        'name_en': [this.company.name_en, [Validators.required]],
        'selected_groups': [this.dropdowns['selected_groups'], []],
        'selected_poles': [this.dropdowns['selected_poles'], []],
        'selected_divisions': [this.dropdowns['selected_divisions'], []],
      }),
    });
  }

  onSubmit() { this._onSubmitForm(); }
  onCancel() { this.navigateBack(); }

  _onSubmitForm() {
    const editedCompany = {
      ...this.companyForm.value.main,
    };
    console.log(this.dropdowns['map_poles']);
    console.log(this.dropdowns['map_divisions']);
    console.log(this.dropdowns['map_groups']);
    editedCompany['poles'] = editedCompany['selected_poles'].map(name_en => this.dropdowns['map_poles'].get(name_en));
    editedCompany['divisions'] = editedCompany['selected_divisions'].map(name_en => this.dropdowns['map_divisions'].get(name_en));
    editedCompany['groups'] = editedCompany['selected_groups'].map(name_en => this.dropdowns['map_groups'].get(name_en));
    console.log(editedCompany);

    if (this.isNew && this.company.id === 0) {
      this.companyService.createCompany(editedCompany).subscribe(
        (company: Company) => {
          this.growlService.showInfo('COMPANY_IS_CREATED');
          this.navigateBack();
        },
        (data: any) => { this.growlService.showError(data.error.msg_const); }
      );
    } else {
      editedCompany.id = this.company.id;
      this.companyService.updateCompany(editedCompany).subscribe(
        data => {
          this.growlService.showInfo('COMPANY_IS_UPDATED');
          this.navigateBack();
        },
        (data: any) => {
          console.log(data);
          this.growlService.showError(data.error.msg_const);
        }
      );
    }
  }

  private navigateBack() {
    this.router.navigate(['/home/companies']);
  }

  ngOnDestroy() {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

}
