import { Injectable } from '@angular/core';
import { Location } from '../models/location';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LocationService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAll() {
        return this.httpClient.get<any>(this.config.apiUrl + '/locations');
    }

    getAllLocationsForUserWithParams(params: any, mobileUserId: number) {
        if (!mobileUserId){return null;}
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/locations/mobileuser/' + mobileUserId, {
            params: httpParams,
            observe: 'body'
        });
    }

    getAllLocationsForUsersWithParams(params: any, mobileUserId: number = 0) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/locations/mobileusers' , {
            params: httpParams,
            observe: 'body'
        });
    }

    getById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/locations/' + _id);
    }
   
    create(location: Location) {
        return this.httpClient.post(this.config.apiUrl + '/locations', location);
    }

    update(location: Location) {
        return this.httpClient.put(this.config.apiUrl + '/locations/' + location.id, location);
    }

    delete(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/locations/' + _id);
    }

    getOldestLocation() {
        return this.httpClient.get<any>(this.config.apiUrl + '/locations/oldest_location');
    }

}
