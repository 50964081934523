import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var VigiRegionService = /** @class */ (function () {
    function VigiRegionService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    VigiRegionService.prototype.getAllVigiRegions = function () {
        return this.httpClient.get(this.config.apiUrl + '/vigiregions/all');
    };
    /**
     * returns vigiregions list if any for the vigicountry with the countryId
     */
    VigiRegionService.prototype.getVigiRegionsForCountryId = function (countryId) {
        return this.httpClient.get(this.config.apiUrl + '/vigiregions/country/' + countryId);
    };
    VigiRegionService.prototype.getVigiAreasRegionsForCountryId = function (countryId) {
        return this.httpClient.get(this.config.apiUrl + '/vigiareas/country/' + countryId + '/regions');
    };
    VigiRegionService.prototype.getVigiAreasCitiesForCountryId = function (countryId) {
        return this.httpClient.get(this.config.apiUrl + '/vigiareas/country/' + countryId + '/cities');
    };
    VigiRegionService.prototype.getVigiRegionsWithCoordinatesForCountryId = function (countryId) {
        return this.httpClient.get(this.config.apiUrl + '/vigiregions/with_coord/country/' + countryId);
    };
    // Get by ID
    VigiRegionService.prototype.getVigiRegionById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/vigiregions/' + _id);
    };
    VigiRegionService.prototype.exportRegionsInXLSX = function (selectedCountryId) {
        return this.httpClient.get(this.config.apiUrl + '/vigiregions/country/' + selectedCountryId + '/export_regions_in_xlsx', {
            headers: new HttpHeaders().set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    VigiRegionService.prototype.importRegionsFromXLSX = function (formData, selectedCountryId) {
        console.log(formData);
        return this.httpClient.post(this.config.apiUrl + '/vigiregions/country/' + selectedCountryId + '/import_regions_from_xlsx', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    };
    // Fact Sheet descr methods
    VigiRegionService.prototype.saveDescrForRegion = function (factSheetDescription, regionId) {
        return this.httpClient.put(this.config.apiUrl + '/vigiregions/' + regionId + '/fact_sheet_descr', factSheetDescription);
    };
    VigiRegionService.prototype.getFactSheetDescrForSelectedRegionAndSheetType = function (vigiRegionId, selectedFactSheetTypeId) {
        return this.httpClient.get(this.config.apiUrl + '/vigiregions/' + vigiRegionId + '/fact_sheet_type/' + selectedFactSheetTypeId);
    };
    // END Fact Sheet descr methods
    // DELETE
    VigiRegionService.prototype.deleteVigiRegion = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/vigiregions/' + _id);
    };
    // UPDATE
    VigiRegionService.prototype.updateVigiRegion = function (vc) {
        return this.httpClient.put(this.config.apiUrl + '/vigiregions/' + vc.id, vc);
    };
    // CREATE
    VigiRegionService.prototype.createVigiRegion = function (vc) {
        return this.httpClient.post(this.config.apiUrl + '/vigiregions', vc);
    };
    VigiRegionService.ngInjectableDef = i0.defineInjectable({ factory: function VigiRegionService_Factory() { return new VigiRegionService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: VigiRegionService, providedIn: "root" });
    return VigiRegionService;
}());
export { VigiRegionService };
