import { AppConfig } from '../app.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var LocationService = /** @class */ (function () {
    function LocationService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    LocationService.prototype.getAll = function () {
        return this.httpClient.get(this.config.apiUrl + '/locations');
    };
    LocationService.prototype.getAllLocationsForUserWithParams = function (params, mobileUserId) {
        if (!mobileUserId) {
            return null;
        }
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/locations/mobileuser/' + mobileUserId, {
            params: httpParams,
            observe: 'body'
        });
    };
    LocationService.prototype.getAllLocationsForUsersWithParams = function (params, mobileUserId) {
        if (mobileUserId === void 0) { mobileUserId = 0; }
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/locations/mobileusers', {
            params: httpParams,
            observe: 'body'
        });
    };
    LocationService.prototype.getById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/locations/' + _id);
    };
    LocationService.prototype.create = function (location) {
        return this.httpClient.post(this.config.apiUrl + '/locations', location);
    };
    LocationService.prototype.update = function (location) {
        return this.httpClient.put(this.config.apiUrl + '/locations/' + location.id, location);
    };
    LocationService.prototype.delete = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/locations/' + _id);
    };
    LocationService.prototype.getOldestLocation = function () {
        return this.httpClient.get(this.config.apiUrl + '/locations/oldest_location');
    };
    LocationService.ngInjectableDef = i0.defineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
