import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var TravelService = /** @class */ (function () {
    function TravelService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    TravelService.prototype.getAll = function () {
        return this.httpClient.get(this.config.apiUrl + '/travels');
    };
    TravelService.prototype.getTravelsNotSendToCrisis = function () {
        return this.httpClient.get(this.config.apiUrl + '/travels/not_sent_to_crisis');
    };
    TravelService.prototype.getAllTravelsForUserWithParams = function (params, mobileUserId) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/travels/mobileuser/' + mobileUserId, {
            params: httpParams,
            observe: 'body'
        });
    };
    TravelService.prototype.getTravelsWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/travels/mobileusers', {
            params: httpParams,
            observe: 'body'
        });
    };
    TravelService.prototype.downloadTravelsCSV = function (showCorporateUsersTable) {
        return this.httpClient.get(this.config.apiUrl +
            '/travels/corporate/' + ((showCorporateUsersTable) ? 1 : 0) +
            '/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    TravelService.prototype.getById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/travels/' + _id);
    };
    TravelService.prototype.create = function (travel) {
        return this.httpClient.post(this.config.apiUrl + '/travels', travel);
    };
    TravelService.prototype.update = function (travel) {
        return this.httpClient.put(this.config.apiUrl + '/travels/' + travel.id, travel);
    };
    TravelService.prototype.delete = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/travels/' + _id);
    };
    TravelService.prototype.sendTravelToCrisis = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/travels/' + _id + "/send_to_crisis");
    };
    TravelService.ngInjectableDef = i0.defineInjectable({ factory: function TravelService_Factory() { return new TravelService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: TravelService, providedIn: "root" });
    return TravelService;
}());
export { TravelService };
