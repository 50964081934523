import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export interface TimeFilterInterface {
  startTime: number;
  endTime: number;
}

@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.css']
})
export class TimeFilterComponent implements OnInit {
  @Output() onFilter = new EventEmitter();
  @Input() startDateInput = null;
  @Input() endDateInput = null;
  timeFilterForm: FormGroup = new FormGroup({});
  currentYear: number;
  minYear: number;
  minDate: Date;
  maxDate: Date;

  constructor() {
    Date.prototype.toJSON = function () { return moment(this).format(); };
    this.currentYear = moment().year();
    this.minYear = this.currentYear - 60;

    this.minDate = new Date();
    let prevYear = moment().year() - 1;
    this.minDate.setFullYear(prevYear);
    this.minDate.setHours(0);
    this.minDate.setMinutes(0);

    this.maxDate = new Date();
    let nextYear = moment().year();
    this.maxDate.setFullYear(nextYear);
    this.maxDate.setHours(0);
    this.maxDate.setMinutes(0);
    this.maxDate.setDate((new Date()).getDate() + 1);
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    let groupObject = {};
    console.log(this.startDateInput, " ", this.endDateInput);
    groupObject = {
      dates: new FormGroup({
        startDate: new FormControl((this.startDateInput)? this.startDateInput: null, Validators.required),
        endDate: new FormControl((this.endDateInput)? this.endDateInput: null, Validators.required),
      }, this.dateBeforeDate),
    };
    this.timeFilterForm = new FormGroup(groupObject);
  }

  // FormGroups extend AbstractControl
  dateBeforeDate = (groupControl: AbstractControl): { [key: string]: boolean } => {
    console.log(this.timeFilterForm);
    // let password = group.controls.password;
    // let confirm = group.controls.confirm;
    const startDate = groupControl.get('startDate').value;
    const endDate = groupControl.get('endDate').value;
    if (!startDate || !endDate) {
      return null;
    }
    const startDateTime = startDate.getTime();
    const endDateTime = endDate.getTime();
    return startDateTime < endDateTime ? null : { timeMismatch: true };
  }

  onSubmitTimeFilterForm() {
    const filterValue = this.timeFilterForm.value;
    console.log(filterValue);
    let startDate = (filterValue.dates && filterValue.dates.startDate) ? filterValue.dates.startDate : null;
    let endDate = (filterValue.dates && filterValue.dates.endDate) ? filterValue.dates.endDate : null;

    startDate = moment(startDate).utcOffset(0, true);
    endDate = moment(endDate).utcOffset(0, true);
    // console.log("before conversion", startDate, endDate);
    // console.log(startDate.toUTCString());
    // console.log(moment(startDate).utcOffset(0, true).format())
    
    // if (startDate) {
    //   const startUtcDate = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()));
    //   console.log("after conversion", startUtcDate);
    //   startDate = startUtcDate.getTime();
    // }
    // if (endDate) {
    //   const endUtcDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes(), endDate.getUTCSeconds()));
    //   console.log("after conversion", endUtcDate);
    //   endDate = endUtcDate.getTime();
    // }
    // console.log("after conversion", startDate, endDate);
    this.onFilter.emit({
      startTime: startDate.valueOf(),
      endTime: endDate.valueOf(),
    });
  }

  onClearDates() {
    this.timeFilterForm.reset();
    let dateFormGroup = this.timeFilterForm.get('dates') as FormGroup;
    let startDateFormInput = dateFormGroup.get('startDate');
    let endDateFormInput = dateFormGroup.get('endDate');
    if(this.startDateInput){
      startDateFormInput.setValue(this.startDateInput);
    }
    if(this.endDateInput){
      endDateFormInput.setValue(this.endDateInput);
    }
    if(this.startDateInput || this.endDateInput) {
      const filterValue = this.timeFilterForm.value;
      let startTime = (filterValue.dates && filterValue.dates.startDate) ? filterValue.dates.startDate.getTime() : null;
      let endTime = (filterValue.dates && filterValue.dates.endDate) ? filterValue.dates.endDate.getTime() : null;
      this.onFilter.emit({
        startTime: startTime,
        endTime: endTime,
      });
    } else {
      this.onFilter.emit({
        startTime: null,
        endTime: null,
      });
    }
  }

}
