import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { LazyLoadEvent } from 'primeng/components/common/api';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Airport } from '../../models/airport';
import { MobileUser } from '../../models/mobile-user';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { AppConfig } from '../../app.config';
import { ConfirmDialogsService } from '../../_module_dialog/confirm_dialog/confirm-dialog.service';
import { AirportService } from 'src/app/models_services/airport.service';

@Component({
  selector: 'app-airports-table',
  templateUrl: 'airports-table.component.html',
  styleUrls: ['airports-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AirportsTableComponent implements OnInit {
  current_user: MobileUser;
  airportedUsers: MobileUser[];
  asyncAirports: Observable<Airport[]>;
  count: 0;
  currentPage = 1;
  loading: boolean;

  // server side filter
  sortBy: string;
  orderAsc: string;
  params = {
    page: 1, per_page: 10, order: '-name',
    'name': { operator: '%=', value: '' },
    'city_name': { operator: '%=', value: '' },
    'country': { operator: '%=', value: '' },
    'id': { operator: '%=', value: '' },
    'timezone': { operator: '%=', value: '' },
  };
  selectedAirport: Airport;
  displayDialog: boolean = false;
  displayOnlyAirportedUsersDialog: boolean = false;

  airportTypesSelectItems: SelectItem[] = [];

  constructor(
    private config: AppConfig,
    private confirmDService: ConfirmDialogsService,
    private router: Router,
    private growlService: GrowlService,
    private airportService: AirportService) {
    this.current_user = JSON.parse(localStorage.getItem('current_user'));
  }

  ngOnInit() {
    this.loadAllAirports();
  }

  private loadAllAirports() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.params.page = page;
    this.asyncAirports = this.airportService.getAllWithParams(this.params).pipe(
      tap((data: any) => {
        this.count = data.count;
        this.loading = false;
        this.currentPage = page;
      }),
      map((data: any) => {
        return data.airports;
      })
    );
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    this.params.id.value = event.filters['id'] ? event.filters['id'].value : '';
    this.params.name.value = event.filters['name'] ? event.filters['name'].value : '';
    this.params.city_name.value = event.filters['city_name'] ? event.filters['city_name'].value : '';
    this.params.country.value = event.filters['country'] ? event.filters['country'].value : '';
    this.params.timezone.value = event.filters['timezone'] ? event.filters['timezone'].value : '';
    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    console.log(event.rows);
    console.log(event.first);
    console.log(event.first / event.rows);
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

  // CRUD methods
  removeItem(airport: Airport) {
    // call confirmation dialog
    this.confirmDService.show('Airport delete', 'Would you like to delete this airport ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('deleting airport confirmed: ' + JSON.stringify(airport));
          this.airportService.delete(airport.id).subscribe(
            () => {
              this.loadAllAirports();
              this.growlService.showInfo('Airport is deleted.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
    });
  }

  showInfo(airport: Airport) {
    this.selectedAirport = airport;
    this.displayDialog = true;
  }

  addItem() {
    console.log('addItem');
    this.router.navigate(['/home', 'airport', 'new']);
  }

  editItem(airport: Airport) {
    console.log('editItem: ' + airport.id + ' ' + JSON.stringify(airport));
    this.router.navigate(['/home', 'airport', airport.id, 'edit']);
  }

}
