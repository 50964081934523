<div class="col-md-10 col-md-offset-1">
  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncAlerts | async" [paginator]="true" [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
        paginatorPosition="both" [rows]="25" autoLayout="true" [lazy]="true" [totalRecords]="count" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        [(selection)]="selectedAlerts" dataKey="id"
        [responsive]="true">

        <ng-template pTemplate="caption">
          <button mat-icon-button (click)="downloadHAYsCSV()"
            pTooltip="Download HAYs CSV" >
            <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
          </button>
          <button mat-icon-button (click)="downloadHAYsPdf()"
            pTooltip="Download HAYs PDF" >
            <fa-icon [icon]="faFilePdf" style="font-size: 40px;"></fa-icon>
          </button>
        </ng-template>
        
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'ID' }}</th>
            <th>
              {{ 'Actions' }}
            </th>
            <th>{{ 'Count of receivers' }}</th>
            <th>{{ 'Count of replies' }}</th>
            <th [pSortableColumn]="'time'">
              {{ 'Date created (GMT))'}}
              <p-sortIcon [field]="'time'"></p-sortIcon>
            </th>
          </tr>
          <!-- <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr> -->
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td>
              <span class="ui-column-title">{{ 'ID' }}</span>
              {{ rowData.id }}
            </td>
            <td>
              <button mat-icon-button 
                pTooltip="Show more info" 
                (click)="showAlertedUsersInfo(rowData)">
                  <i class="fa fa-eye" style="font-size: 24px;"></i>
              </button>
            </td>
            <td>
              <span class="ui-column-title">{{ 'Count of receivers' }}</span>
              {{ rowData.how_are_yous_count }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Count of replies' }}</span>
              {{ rowData.replies_count }}
            </td>
            <td>
              <span class="ui-column-title">
                {{ 'Date created (GMT)' }}
              </span>
              {{ rowData.date_created }}
            </td>
            
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <ul>
                <li *ngFor="let a of selectedAlerts" style="text-align: left">
                    ID{{a.id + ' - ' + a.date_created + ((a.type) ? (' - ' + ('ALERT_TYPE.' + a.type | translate)):'')  +  ((a.title) ? (' - ' + a.title) : '')}}
                </li>
            </ul>
            <button *ngIf="selectedAlerts && selectedAlerts.length > 0" 
              (click)="bulkDeleteAlerts(selectedAlerts)" >Delete selected alerts</button>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog header="HAY Info" 
  [contentStyle]="{'max-height':'50vh'}"
  [(visible)]="displayAlertedUsersDialog" >
    <div>
      <h3>Sender</h3>
      <ul>
        <li>
          {{selectedSender.firstname}} {{selectedSender.lastname}} {{selectedSender.email}}
        </li>
      </ul>

      <h3>List of notified users</h3>
      <p-table [value]="selectedHowAreYous" [tableStyle]="{'max-width': '50rem'}">
        <ng-template pTemplate="body" let-c>
          <tr>
            <td>{{c.mobileuser.firstname}} {{c.mobileuser.lastname}}</td>
            <td>{{c.mobileuser.email}}</td>
            <td>{{c.feedback_action_type_str}}</td>
          </tr>
        </ng-template>
      </p-table>
      <!-- <ul *ngIf="selectedHowAreYous">
        <li *ngFor="let c of selectedHowAreYous">
            
        </li>
      </ul> -->
      <div *ngIf="!selectedHowAreYous">
        No users are alerted.
      </div>
    </div>
</p-dialog>
