import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var PoleService = /** @class */ (function () {
    function PoleService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    PoleService.prototype.getAllPoles = function () {
        return this.httpClient.get(this.config.apiUrl + '/poles/all');
    };
    PoleService.prototype.getAllAccessiblePoles = function (groupId, editedUserId) {
        editedUserId = editedUserId ? editedUserId : 0;
        return this.httpClient.get(this.config.apiUrl + '/poles/all_accessible/' + groupId + '/edited_user/' + editedUserId);
    };
    PoleService.prototype.getPolesForGroups = function (groups) {
        return this.httpClient.post(this.config.apiUrl + '/poles/get_by_groups', { 'groups': groups });
    };
    PoleService.prototype.getCountOfUsersForPoles = function (poleIds) {
        return this.httpClient.post(this.config.apiUrl + '/poles/stats_for_users', { 'pole_ids': poleIds });
    };
    // Get by ID
    PoleService.prototype.getPoleById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/poles/' + _id);
    };
    // DELETE
    PoleService.prototype.deletePole = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/poles/' + _id);
    };
    // UPDATE
    PoleService.prototype.updatePole = function (pole) {
        return this.httpClient.put(this.config.apiUrl + '/poles/' + pole.id, pole);
    };
    // CREATE
    PoleService.prototype.createPole = function (pole) {
        return this.httpClient.post(this.config.apiUrl + '/poles', pole);
    };
    PoleService.ngInjectableDef = i0.defineInjectable({ factory: function PoleService_Factory() { return new PoleService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: PoleService, providedIn: "root" });
    return PoleService;
}());
export { PoleService };
