import { AppConfig } from '../app.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var AirportService = /** @class */ (function () {
    function AirportService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    AirportService.prototype.getAllWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/airports', {
            params: httpParams,
            observe: 'body'
        });
    };
    AirportService.prototype.getById = function (airportId) {
        return this.httpClient.get(this.config.apiUrl + '/airports/' + airportId);
    };
    AirportService.prototype.create = function (airport) {
        return this.httpClient.post(this.config.apiUrl + '/airports', airport);
    };
    AirportService.prototype.update = function (airport) {
        return this.httpClient.put(this.config.apiUrl + '/airports/' + airport.id, airport);
    };
    AirportService.prototype.delete = function (id) {
        return this.httpClient.delete(this.config.apiUrl + '/airports/' + id);
    };
    AirportService.ngInjectableDef = i0.defineInjectable({ factory: function AirportService_Factory() { return new AirportService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: AirportService, providedIn: "root" });
    return AirportService;
}());
export { AirportService };
