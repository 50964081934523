import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Airport } from '../models/airport';

@Injectable({ providedIn: 'root' })
export class AirportService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAllWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/airports', {
            params: httpParams,
            observe: 'body'
        });
    }

    getById(airportId: string) {
        return this.httpClient.get<any>(this.config.apiUrl + '/airports/' + airportId);
    }

    create(airport: Airport) {
        return this.httpClient.post(this.config.apiUrl + '/airports', airport);
    }

    update(airport) {
        return this.httpClient.put(this.config.apiUrl + '/airports/' + airport.id, airport);
    }

    delete(id: string) {
        return this.httpClient.delete(this.config.apiUrl + '/airports/' + id);
    }
}
