
        <h3 mat-dialog-title>{{ title }}</h3>
        {{ message }}
        <input type="file" (change)="fileChange($event)" [accept]="fileExtension">
        <div mat-dialog-actions>
            <button type="button" mat-raised-button (click)="dialogRef.close()">Cancel</button>
        </div>

        <p mat-dialog-content>
            <br *ngIf="imageUrl" />
            <img *ngIf="imageUrl" [src]="imageUrl" />
            <ngx-image-gallery  [images]="images"></ngx-image-gallery >
        </p>
    