import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var DomainService = /** @class */ (function () {
    function DomainService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    DomainService.prototype.extractDomainsFromXLSX = function (formData) {
        console.log(formData);
        return this.httpClient.post(this.config.apiUrl + '/domains/extract_from_xlsx', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    };
    DomainService.prototype.getAllEmailDomains = function () {
        return this.httpClient.get(this.config.apiUrl + '/domains/all');
    };
    // Get by ID
    DomainService.prototype.getDomainById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/domains/' + _id);
    };
    // DELETE
    DomainService.prototype.deleteDomain = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/domains/' + _id);
    };
    // UPDATE
    DomainService.prototype.updateDomain = function (domain) {
        return this.httpClient.put(this.config.apiUrl + '/domains/' + domain.id, domain);
    };
    // CREATE
    DomainService.prototype.createDomain = function (domain) {
        return this.httpClient.post(this.config.apiUrl + '/domains', domain);
    };
    DomainService.ngInjectableDef = i0.defineInjectable({ factory: function DomainService_Factory() { return new DomainService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: DomainService, providedIn: "root" });
    return DomainService;
}());
export { DomainService };
