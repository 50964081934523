import { EventEmitter } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var VigiCountryService = /** @class */ (function () {
    function VigiCountryService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this.importFactSheetsEventEmitter = new EventEmitter();
    }
    // getAllVigiCountries() {
    //     return this.httpClient.get<any>(this.config.apiUrl + '/vigicountries/all');
    // }
    VigiCountryService.prototype.getVigiCountriesForGroups = function (groups) {
        return this.httpClient.post(this.config.apiUrl + '/vigicountries/get_by_groups', { 'groups': groups });
    };
    // Get by ID
    VigiCountryService.prototype.getVigiCountryById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/vigicountries/' + _id);
    };
    VigiCountryService.prototype.getVigiCountryByCountryId = function (countryId) {
        return this.httpClient.get(this.config.apiUrl + '/vigicountries/country/' + countryId);
    };
    // Fact Sheet descr methods
    VigiCountryService.prototype.saveDescrForCountry = function (factSheetDescription, selectedCountryId) {
        return this.httpClient.put(this.config.apiUrl + '/vigicountries/country/' + selectedCountryId + '/fact_sheet_descr', factSheetDescription);
    };
    VigiCountryService.prototype.getFactSheetDescrForSelectedCountryAndSheetType = function (selectedCountryId, selectedFactSheetTypeId) {
        return this.httpClient.get(this.config.apiUrl + '/vigicountries/country/' + selectedCountryId + '/fact_sheet_type/' + selectedFactSheetTypeId);
    };
    // END Fact Sheet descr methods
    // DELETE
    VigiCountryService.prototype.deleteVigiCountry = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/vigicountries/' + _id);
    };
    // UPDATE
    VigiCountryService.prototype.updateVigiCountry = function (vc) {
        return this.httpClient.put(this.config.apiUrl + '/vigicountries/' + vc.id, vc);
    };
    // CREATE
    VigiCountryService.prototype.createVigiCountry = function (vc) {
        return this.httpClient.post(this.config.apiUrl + '/vigicountries', vc);
    };
    VigiCountryService.prototype.exportFactSheetsInXLSX = function (selectedCountryId) {
        return this.httpClient.get(this.config.apiUrl + '/vigicountries/' + selectedCountryId + '/export_fact_sheets_in_xlsx', {
            headers: new HttpHeaders().set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    VigiCountryService.prototype.importFactSheetsInXLSX = function (formData, selectedCountryId) {
        console.log(formData);
        return this.httpClient.post(this.config.apiUrl + '/vigicountries/' + selectedCountryId + '/import_fact_sheets_from_xlsx', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    };
    VigiCountryService.ngInjectableDef = i0.defineInjectable({ factory: function VigiCountryService_Factory() { return new VigiCountryService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: VigiCountryService, providedIn: "root" });
    return VigiCountryService;
}());
export { VigiCountryService };
