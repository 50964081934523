import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

import { LazyLoadEvent } from 'primeng/components/common/api';
import { SelectItem } from 'primeng/api';
import { Observable, pipe, combineLatest, concat, of } from 'rxjs';
import { tap, map, bufferCount } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { Alert } from '../../../models/alert';
import { MobileUser } from '../../../models/mobile-user';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { AppConfig } from '../../../app.config';
import { ConfirmDialogsService } from '../../../_module_dialog/confirm_dialog/confirm-dialog.service';
import { AlertService } from '../../../models_services/alert.service';
import { openFileForDownload } from '../../../utils/file';
import { BackendUser } from 'src/app/models/backend-user';
import { faFilePdf, faFileCsv } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-haycampains-table',
  templateUrl: 'haycampains-table.component.html',
  styleUrls: ['haycampains-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HayCampainsTableComponent implements OnInit {
  faFilePdf = faFilePdf;
  faFileCsv = faFileCsv;
  current_user: MobileUser;
  selectedHowAreYous: any[];
  selectedSender: BackendUser = new BackendUser();
  asyncAlerts: Observable<Alert[]>;
  count: 0;
  currentPage = 1;
  loading: boolean;

  // server side filter
  sortBy: string;
  orderAsc: string;
  params = {
    page: 1, per_page: 10, order: '-time',
    'type': { operator: '=', value: '' },
  };
  selectedAlerts: Alert[] = [];
  selectedAlert: Alert;
  displayDialog: boolean = false;
  displayAlertedUsersDialog: boolean = false;

  alertTypesSelectItems: SelectItem[] = [];

  constructor(
    private config: AppConfig,
    private confirmDService: ConfirmDialogsService,
    private router: Router,
    private growlService: GrowlService,
    private alertService: AlertService) {
    this.current_user = JSON.parse(localStorage.getItem('current_user'));
  }

  ngOnInit() {
    this.loadAllAlerts();
    // this.loadAlertTypesDropdown();
  }

  private loadAllAlerts() {
    this.getPage(1);
  }

  // private loadAlertTypesDropdown() {
  //   this.alertService.getAllAlertTypes().subscribe(
  //     (data) => {
  //       this.alertTypesSelectItems = [];
  //       if (data.types) {
  //         this.alertTypesSelectItems.push({ label: 'Select alert type...', value: null });
  //         data.types.forEach(type => {
  //           this.alertTypesSelectItems.push({ label: type.name, value: type.value });
  //         });
  //       }
  //     }
  //   );
  // }

  getPage(page: number) {
    this.params.page = page;
    this.asyncAlerts = this.alertService.getAllHAYWithParams(this.params).pipe(
      tap((data: any) => {
        this.count = data.count;
        this.loading = false;
        this.currentPage = page;
      }),
      map((data: any) => {
        return data.alerts;
      })
    );
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    this.params.type.value = event.filters['type'] ? event.filters['type'].value : 0;
    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    console.log(this.params.type);
    console.log(event.rows);
    console.log(event.first);
    console.log(event.first / event.rows);
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

  // CRUD methods
  removeItem(alert: Alert) {
    // call confirmation dialog
    this.confirmDService.show(
      'Alert delete', 
      'Would you like to delete this alert ?'
    ).subscribe(res => {
        const result = res;
        if (res) {
          console.log('deleting alert confirmed: ' + JSON.stringify(alert));
          this.alertService.delete(alert.id).subscribe(
            () => {
              this.loadAllAlerts();
              this.growlService.showInfo('Alert is deleted.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
    });
  }

  showAlertInfo(alert: Alert){
    this.alertService.show(alert.id).subscribe(
      // () => {
      //   alert.is_hidden = false;
      //   this.growlService.showInfo('The alert is now visible.');
      // },
      // (error_data: any) => {
      //   this.growlService.showError(error_data.error.msg_const); 
      // }
    );
  }

  // downloadAlertsCSV() {
  //   this.alertService.downloadAlertsCSV().subscribe(
  //     (data) => {
  //       openFileForDownload(data);
  //     },
  //     (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
  //   );
  // }

  bulkDeleteAlerts(selectedAlerts) {
    console.log("delete selected alerts");
    this.confirmDService.show('Delete selected alerts', 'Are you sure that you want to delete selected alerts ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('bulk deleting alerts confirmed');
          this.alertService.deleteBulkOfAlerts(selectedAlerts).subscribe(
            () => {
              selectedAlerts.splice(0, selectedAlerts.length);
              this.growlService.showInfo('Alerts are deleted.');
              this.loadAllAlerts();
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }

  showInfo(alert: Alert) {
    this.selectedAlert = alert;
    this.displayDialog = true;
  }

  showAlertedUsersInfo(alert: Alert) {
    console.log(alert);
    this.selectedHowAreYous = alert.how_are_yous;
    this.selectedSender = alert.sender;
    this.displayAlertedUsersDialog = true;
  }

  editItem(editedAlert: Alert) {
    // console.log('editItem: ' + editedAlert.id + ' ' + JSON.stringify(editedAlert));
    // console.log(editedAlert);
    this.router.navigate(['/home', 'alert', editedAlert.id, 'edit']);
  }

  downloadHAYsCSV() {
    this.alertService.downloadHAYsCSV().subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  downloadHAYsPdf() {
    this.alertService.downloadHAYsPdf().subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

}
