<div>
  <p-tabView (onChange)="onTabChange($event)" [style]="{'max-width':'100%'}">
    <p-tabPanel header="SMTP Server" [selected]="false" >
      <div fxLayout="column">
        <div fxLayout="column"><span>Email</span>
          <input id="smtpEmail" [(ngModel)]="smtpEmail" type="email" pInputText required/>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column"><span>Old password</span>
          <input id="smtpPassword" [(ngModel)]="oldSmtpPassword" type="password" pInputText required />
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column"><span>New password</span>
          <input id="smtpPassword" [(ngModel)]="smtpPassword" type="password" pInputText required />
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <p-button [disabled]="!smtpEmail || !oldSmtpPassword || !smtpPassword" 
    *ngIf="tabIndex == 0" label="Save password" type="button" 
    (click)="(smtpEmail && oldSmtpPassword && smtpPassword) ? updateEmailPassword() : null"></p-button>
</div>
