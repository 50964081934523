import { Component, OnInit } from '@angular/core';
import { VigiDescriptionService } from '../../../models_services/vigidescr.service';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { VigiDescr } from '../../../models/vigidescr';

@Component({
  selector: 'app-risk-settings',
  templateUrl: './risk-settings.component.html',
  styleUrls: ['./risk-settings.component.css']
})
export class RiskSettingsComponent implements OnInit {
  vigidescr: VigiDescr;

  constructor(
    private vigiDescriptionService: VigiDescriptionService,
    private growlService: GrowlService
  ) { }

  ngOnInit() {
    this.vigiDescriptionService.getById(1).subscribe(
      (data) => {
        if (data.vigidescr) {
          this.vigidescr = data.vigidescr;
        }
      }
    );
  }

  saveDescr() {
    this.vigiDescriptionService.update(this.vigidescr).subscribe(
      (data) => {
        this.growlService.showInfoText('Changes are saved');
      } ,
      (error_data) => {
        if(error_data.error) {
          this.growlService.showError(error_data.error.msg_const);
        }
      }
    );
  }

  onTabChange(){
    console.log('Tab changed');
  }

}
