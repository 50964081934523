import { Component, OnInit, OnDestroy, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Country } from '../../../../models/country';
import { VigiCountry } from '../../../../models/vigicountry';
import { CountryService } from '../../../../models_services/country.service';
import { GrowlService } from '../../../../_module_dialog/growl_dialog/growl.service';
import { VigiCountryService } from '../../../../models_services/vigicountry.service';
import { VigiDescr } from '../../../../models/vigidescr';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { slideInOutAnimation } from '../../../../_animations/slide-in-out.animation';
import { fadeInAnimation } from '../../../../_animations/fade-in.animation';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { VIGISelectedCountryService } from '../../selected-country.service';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'app-vigi-country-descr',
  templateUrl: './vigi-country-descr.component.html',
  styleUrls: ['./vigi-country-descr.component.css'],
  animations: [
    trigger('slideIn', [
      state('inactive', style({
          transform: 'translateY(10%)',
              opacity: 0
      })),
      state('active', style({
          transform: 'translateY(0%)',
              opacity: 1
      })),
      transition('active => inactive', animate('.6s ease')),
      transition('inactive => active', animate('.6s ease'))
    ])
  ]
})
export class VigiCountryDescrComponent implements OnInit, OnDestroy {
  selectedCountryChangedSubscription: Subscription;

  selectedCountryId;
  vigiDescr: VigiDescr;
  vigiCountry: VigiCountry = new VigiCountry();
  riskLevelsSI: SelectItem[];
  selectedRiskLevel: string;

  constructor(
    private vigiCountryService: VigiCountryService,
    private vigiSelectedCountryService: VIGISelectedCountryService,
    private growlService: GrowlService,
    private countryService: CountryService,
    private appConfig: AppConfig
  ) {
    this.selectedCountryId = this.vigiSelectedCountryService.selectedCountryId;
    console.log('SelectedCountryId:' + this.selectedCountryId);
    this.getVigiCountryByCountry(this.selectedCountryId);

    this.riskLevelsSI = [
      { value: 'LOW_RISK', label: 'Low Risk' },
      { value: 'MODERATE_RISK', label: 'Moderate Risk' },
      { value: 'HIGH_RISK', label: 'High Risk' },
      { value: 'VERY_HIGH_RISK', label: 'Very High Risk' },
      { value: 'WAR_ZONE', label: 'War Zone' },
    ];

    // assets field
    this.formData = new FormData();
    this.timestamp = (new Date()).getTime();
  }

  ngOnInit() {
    this.selectedCountryChangedSubscription = this.vigiSelectedCountryService.countryIdUpdatedEvent.subscribe(
      (val) => {
        this.selectedCountryId = val;
        this.getVigiCountryByCountry(val);

        console.log('onCountryChange');
        this.countryImgUrl = this.appConfig.apiUrl + '/country_images/' + this.selectedCountryId + '/image.jpeg/width/200/height/100/keep_ratio/1';
      }
    )
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.selectedCountryChangedSubscription.unsubscribe();
  }

  getVigiCountryByCountry(countryId: number) {
    console.log('Get descr for country with id:' + countryId);
    this.vigiCountryService.getVigiCountryByCountryId(countryId).subscribe(
      (data) => {
        if (data && data.vigicountry) {
          this.vigiCountry = data.vigicountry;
          this.selectedRiskLevel = this.vigiCountry.risk_level;
        }
      }
    )
  }

  saveVigiCountryDescr(){
    if (!this.selectedCountryId) {
      this.growlService.showInfoText('Please select country first');
      return;
    }
    console.log('Save descr');    
    this.vigiCountry.risk_level = this.selectedRiskLevel;
    this.vigiCountry.country_id = this.selectedCountryId;
    if (this.vigiCountry.id) {
      this.vigiCountryService.updateVigiCountry(this.vigiCountry).subscribe(
        (data) => {
          this.growlService.showInfoText('Changes are saved');
        },
        (error_data: any) => this.growlService.showError(error_data.error.msg_const)
      );
    } else {
      this.vigiCountryService.createVigiCountry(this.vigiCountry).subscribe(
        (data: any) => {
          this.growlService.showInfoText('Changes are saved');
          this.vigiCountry = data.vigicountry;
        },
        (error_data: any) => this.growlService.showError(error_data.error.msg_const)
      )
    }
  }

  // ========================================================================================
  // ================================Assets part of the form=================================
  // ========================================================================================
  // ========================================================================================
  countryDisplayDialog: boolean;
    selected: Country;
    countriesSI: SelectItem[];
    formData: FormData = null;
    sendingContent = false;
    imageError = '';
    countryImgUrl = '';
    timestamp = 0;

    imageChange(event) {
        console.log('imageChange');
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
          this.imageError = '';
          const file: File = fileList[0];
          this.formData.set('image', file, file.name);
        }
    }

    uploadNewImageForCountry() {
        if (!this.selectedCountryId) {
            this.growlService.showErrorText('Please select country ID first');
            return;
        }
        console.log('uploadNewImageForCountry');

        let someOfFilesIsMissing = false;
        if (!this.formData) {
            someOfFilesIsMissing = true;
            this.imageError = 'Image file is required';
        } else {
            if (!this.formData.get('image')) {
                someOfFilesIsMissing = true;
            }
            if (someOfFilesIsMissing) {
                if (!this.formData.get('imageCaptation')) {
                    this.imageError = 'Image file is required';
                }
            }
        }

        if (!someOfFilesIsMissing) {
            this.sendingContent = true;
            this.countryService.saveImageForCountry(this.selectedCountryId, this.formData).subscribe(
                (data: any) => {
                    this.timestamp = (new Date()).getTime();
                    this.growlService.showInfo('Image is saved');
                    this.sendingContent = false;
                },
                (data: any) => {
                    this.sendingContent = false;
                    this.growlService.showError(data.error.msg_const);
                }
            );
        }
    }
    
    onCountryChange() {
        console.log('onCountryChange');
        this.countryImgUrl = this.appConfig.apiUrl + '/country_images/' + this.selectedCountryId + '/image.jpeg/width/200/height/100/keep_ratio/1';
    }

}
