import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { BackendUser } from '../../../models/backend-user';
import { BackendUserService } from '../../../models_services/backend_user.service';
import { Alert } from 'src/app/models/alert';
import { AlertService } from 'src/app/models_services/alert.service';

@Component({
  selector: 'app-alerts-edit',
  templateUrl: './alerts-edit.component.html',
  styleUrls: ['./alerts-edit.component.css']
})
export class AlertsEditComponent implements OnInit, OnDestroy {
  currentUser: BackendUser;
  alert: Alert = new Alert();
  subscription: Subscription;
  alertForm: FormGroup = new FormGroup({});
  isNew = false;
  passwordDivision: AbstractControl;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private backendUserService: BackendUserService,
    private fromBuilder: FormBuilder,
    private growlService: GrowlService,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('current_user'));
  }

  ngOnInit() {
    this.initForm();
    this.loadTheUser();
  }

  loadTheUser() {
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        if (params.hasOwnProperty('id')) {
          this.isNew = false;
          this.alertService.getById(params['id']).subscribe(data => {
              this.alert = data.alert;
              this.initForm();
          });
        }
      }
    );
  }

  private initForm() {
    let alertObject = <any>{};
    alertObject = {
      title: new FormControl((this.alert.title) ? this.alert.title : '', [Validators.required]),
      title_fr: new FormControl((this.alert.title_fr) ? this.alert.title_fr : '', [Validators.required]),
      content: new FormControl((this.alert.content) ? this.alert.content : '', [Validators.required]),
      content_fr: new FormControl((this.alert.content_fr) ? this.alert.content_fr : '', [Validators.required])
    };
    this.alertForm = new FormGroup(alertObject);
  }

  onSubmit() {
    const editedAlert = this.alertForm.value;
    console.log(editedAlert);
    if (this.isNew) {
      // on this page we don't create new alerts
    } else {
      editedAlert.id = this.alert.id;
      this.alertService.update(editedAlert).subscribe(
        data => {
          this.growlService.showInfo('Alert is updated');
          this.navigateBack();
        },
        (error_data: any) => {
          console.log(error_data.error);
          this.growlService.showError(error_data.error.msg_const);
        }
      );
    }
  }

  private navigateBack() {
    this.router.navigate(['/home/notifications']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
