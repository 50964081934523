// Modules
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AppRoutingModule } from '../module_routing/routing.module';
import { MyMaterialModule } from '../_module_my_material/material.module';
import { CommonModule } from '@angular/common';

// services
import { LoggerService } from './logger.service';
import { SpinnerService } from './spinner/spinner.service';
import { DropdownDirective } from './directives/dropdown.directive';

import { SpinnerComponent } from './spinner/spinner.component';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { HttpXhrBackend,  } from '@angular/common/http';
import { Router } from '@angular/router';

import { MyLinkDirective } from './directives/href.directive';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/http.interceptor';
import { AuthService } from '../_services/auth.service';
import { GrowlService } from '../_module_dialog/growl_dialog/growl.service';
import { DatePickerDirective } from './directives/datetime-picker.directive';
import { RequestLoadingInterceptor } from './services/request-loading.interceptor';

@NgModule({
  imports: [
    CommonModule, // we use ngFor :)
    AppRoutingModule,
    MyMaterialModule, // combines all material imports
    HttpClientModule
  ],
  // make it public
  exports: [
    SpinnerComponent, DropdownDirective, SafeHtmlPipe,
    DatePickerDirective
  ],
  declarations: [
    SpinnerComponent, DropdownDirective, SafeHtmlPipe, MyLinkDirective,
    DatePickerDirective
  ],
  providers: [
    LoggerService, SpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthService, SpinnerService, Router]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestLoadingInterceptor,
      multi: true,
      deps: [SpinnerService]
    },
  ]
})
export class CoreModule {
  // Do guard against reimporting of CoreModule and fail fast by adding guard logic.
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
