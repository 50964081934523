import { Component, ViewEncapsulation } from '@angular/core';
import { MyPermissionsService } from './_services/myx-permissions.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: `
<!-- main app container -->
<div fxFlexFill>
    <app-growl></app-growl>
    <app-spinner></app-spinner>
    <router-outlet></router-outlet>
</div>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  
  title = 'VINCI Travel Security';
  constructor(
    private myPermissionsService: MyPermissionsService,
    private translateService: TranslateService
  ) {
    myPermissionsService.definePermisions(localStorage.getItem('role'));

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use('en');
  }

}
