<div>
  <p-dropdown 
    [options]="versionNumbers"
    [(ngModel)]="selectedVersionNumber" 
    (onChange)="onVersionNumbers()" 
    placeholder="Version number"
    optionLabel="name"
    optionValue="value"
    [showClear]="false"
  >
  </p-dropdown>
  <p-button 
    *ngIf="selectedVersionNumber.value == 0" 
    label="Restore backup" 
    type="button" 
    (click)="restoreBackup()"
  >
  </p-button>

  <p-tabView (onChange)="onTabChange($event)" [style]="{'max-width':'100%'}">
    
    <p-tabPanel *ngFor="let appText of appTexts; index as i;" 
    [header]="appText.header" [selected]="appText.selected" >
      <div fxLayout="column">
        <div fxLayout="column"><span>Text EN</span>
          <!-- https://stackoverflow.com/questions/45921488/primeng-quill-editor-custom-toolbar -->

          <p-editor [(ngModel)]="appText.text.text_en" 
            [style]="{'height':'200px'}">
            <p-header>
              <span class="ql-formats">
                <select class="ql-size">
                  <option value="small">Small</option>
                  <option selected></option>
                  <option value="large">Sub-title</option>
                  <option value="huge">Title</option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-bold" aria-label="Bold"></button>
                <button class="ql-italic" aria-label="Italic"></button>
                <button class="ql-underline" aria-label="Underline"></button>
              </span>
              <span class="ql-formats">
                <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
                  <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                </select>
                <span class="ql-format-separator"></span>
                <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
                  <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <select title="Text Alignment" class="ql-align" >
                  <option selected>Text Alignment</option>
                  <option value="center" label="Center"></option>
                  <option value="right" label="Right"></option>
                  <option value="justify" label="Justify"></option>
                </select>            
              </span> 
              <span class="ql-formats">
                <button aria-label="Link" class="ql-link"></button>
                <button aria-label="Image" class="ql-image"></button>
              </span>

            </p-header>
          </p-editor>
        </div>
        <div fxLayout="column"><span>Text FR</span>
          <p-editor #editor_faq_text_fr [(ngModel)]="appText.text.text_fr" 
          [style]="{'height':'200px'}">
            <p-header>
              <span class="ql-formats">
                <select class="ql-size">
                  <option value="small">Small</option>
                  <option selected></option>
                  <option value="large">Sub-title</option>
                  <option value="huge">Title</option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-bold" aria-label="Bold"></button>
                <button class="ql-italic" aria-label="Italic"></button>
                <button class="ql-underline" aria-label="Underline"></button>
              </span>
              <span class="ql-formats">
                <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
                  <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                </select>
                <span class="ql-format-separator"></span>
                <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
                  <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <select title="Text Alignment" class="ql-align" >
                  <option selected>Text Alignment</option>
                  <option value="center" label="Center"></option>
                  <option value="right" label="Right"></option>
                  <option value="justify" label="Justify"></option>
                </select>            
              </span> 
              <span class="ql-formats">
                <button aria-label="Link" class="ql-link"></button>
                <button aria-label="Image" class="ql-image"></button>
              </span>

            </p-header>
          </p-editor>
        </div>
      </div>
    </p-tabPanel>

  </p-tabView>

  <p-button *ngIf="tabIndex == 0" label="Save FAQ" type="button" (click)="updateFAQ()"></p-button>
  <p-button *ngIf="tabIndex == 1" label="Save support" type="button" (click)="updateSupport()"></p-button>
  <p-button *ngIf="tabIndex == 2" label="Save terms and conditions" type="button" (click)="updateTAndC()"></p-button>
  <p-button *ngIf="tabIndex == 3" label="Save Use of Personal Data" type="button" (click)="updatePersonalData()"></p-button>
</div>