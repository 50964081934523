/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pob-map.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "primeng/components/tooltip/tooltip";
import * as i6 from "primeng/components/button/button";
import * as i7 from "@angular/common";
import * as i8 from "../marker-info-dialog/marker-info-dialog.component.ngfactory";
import * as i9 from "../marker-info-dialog/marker-info-dialog.component";
import * as i10 from "@angular/router";
import * as i11 from "../../../models_services/panic.service";
import * as i12 from "../../../app.config";
import * as i13 from "../../../../../node_modules/primeng/components/gmap/gmap.ngfactory";
import * as i14 from "primeng/components/gmap/gmap";
import * as i15 from "./pob-map.component";
import * as i16 from "../../../_module_dialog/growl_dialog/growl.service";
import * as i17 from "../../../models_services/map.service";
import * as i18 from "../../../models_services/backend_user.service";
import * as i19 from "../../../common/application.state";
var styles_PobMapComponent = [i0.styles];
var RenderType_PobMapComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PobMapComponent, data: {} });
export { RenderType_PobMapComponent as RenderType_PobMapComponent };
function View_PobMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"], ["fxLayoutGap", "10px"], ["pTooltip", "Navigate between the markers positions"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(4, 4341760, null, 0, i5.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["fxLayout", "row"], ["fxLayoutGap", "5px"], ["style", "position: absolute; z-index: 2; top:3px; right: 60px;"]], null, null, null, null, null)), i1.ɵdid(6, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(7, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["icon", "pi pi-step-backward"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveToFirstLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 4341760, null, 0, i6.ButtonDirective, [i1.ElementRef], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["icon", "pi pi-caret-left"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.movePrevLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 4341760, null, 0, i6.ButtonDirective, [i1.ElementRef], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["icon", "pi pi-caret-right"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveNextLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 4341760, null, 0, i6.ButtonDirective, [i1.ElementRef], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["icon", "pi pi-step-forward"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveToLastLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 4341760, null, 0, i6.ButtonDirective, [i1.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "10px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 3, 0, currVal_2); var currVal_3 = "Navigate between the markers positions"; _ck(_v, 4, 0, currVal_3); var currVal_4 = "row"; _ck(_v, 6, 0, currVal_4); var currVal_5 = "5px"; _ck(_v, 7, 0, currVal_5); var currVal_6 = "pi pi-step-backward"; _ck(_v, 9, 0, currVal_6); var currVal_7 = "pi pi-caret-left"; _ck(_v, 11, 0, currVal_7); var currVal_8 = "pi pi-caret-right"; _ck(_v, 13, 0, currVal_8); var currVal_9 = "pi pi-step-forward"; _ck(_v, 15, 0, currVal_9); }, null); }
export function View_PobMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PobMapComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-marker-info-dialog", [], null, [[null, "onDialogClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDialogClose" === en)) {
        var pd_0 = (_co.onDialogClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MarkerInfoDialogComponent_0, i8.RenderType_MarkerInfoDialogComponent)), i1.ɵdid(3, 114688, null, 0, i9.MarkerInfoDialogComponent, [i10.Router, i11.PanicService, i12.AppConfig], { selectedBPI: [0, "selectedBPI"], displayMarkerInfoDialog: [1, "displayMarkerInfoDialog"], showPanicModeInfoLink: [2, "showPanicModeInfoLink"] }, { onDialogClose: "onDialogClose" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p-gmap", [["id", "googleMap"]], null, [[null, "onMapReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onMapReady" === en)) {
        var pd_0 = (_co.setMap($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_GMap_0, i13.RenderType_GMap)), i1.ɵdid(5, 8699904, [["gmap", 4]], 0, i14.GMap, [i1.ElementRef, i1.IterableDiffers, i1.ChangeDetectorRef, i1.NgZone], { style: [0, "style"], options: [1, "options"], overlays: [2, "overlays"] }, { onMapReady: "onMapReady" }), i1.ɵpod(6, { "width": 0, "height": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayNavigationArrows; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.selectedBPI; var currVal_2 = _co.displayMarkerInfoDialog; var currVal_3 = _co.showPanicModeInfoLink; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _ck(_v, 6, 0, "100%", "100%"); var currVal_5 = _co.options; var currVal_6 = _co.overlays; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6); }, null); }
export function View_PobMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pob-map", [], null, null, null, View_PobMapComponent_0, RenderType_PobMapComponent)), i1.ɵdid(1, 114688, null, 0, i15.PobMapComponent, [i16.GrowlService, i17.MapService, i18.BackendUserService, i19.StoreService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PobMapComponentNgFactory = i1.ɵccf("app-pob-map", i15.PobMapComponent, View_PobMapComponent_Host_0, { displayNavigationArrows: "displayNavigationArrows", showPanicModeInfoLink: "showPanicModeInfoLink", showPobsEmails: "showPobsEmails", bpiList: "bpiList" }, {}, []);
export { PobMapComponentNgFactory as PobMapComponentNgFactory };
