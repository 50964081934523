import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-swagger-documentation',
  templateUrl: './swagger-documentation.component.html',
  styleUrls: ['./swagger-documentation.component.css']
})
export class SwaggerDocumentationComponent implements OnInit {

  constructor(
    public appConfig: AppConfig
  ) { }

  ngOnInit() {
  }

}
