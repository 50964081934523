<div style="padding:5px" fxLayoutAlign="center">
  <div fxLayout="column">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <h3 class="form_header">{{'MOBILE_USER' | translate}}</h3>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Role</label>
        <p-dropdown [options]="roles" formControlName="role"></p-dropdown>
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Phone</label>
        <input placeholder="Phone" pInputText type="text" id="phone" formControlName="phone">
      </div>
      <span *ngIf="userForm.get('phone').touched && userForm.get('phone').hasError('nomatch')" 
        [ngStyle]="{'color': 'red'}" align="start">
        Phone number can consist only from numbers, spaces, dashes (and optional leading '+')
        <br />
      </span>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Email</label>
        <input placeholder="Email" pInputText type="email" id="email" formControlName="email">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" *ngIf="!user.sponsor_id">
          <label>Group</label>
          <p-dropdown [options]="groupsSelectItems"  formControlName="group_id"
              (onChange)="onGroupChange()" >
          </p-dropdown>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" *ngIf="!user.sponsor_id">
          <label>Pole</label>
          <p-dropdown [options]="polesSelectItems" formControlName="pole_id"
            (onChange)="onPoleChange()">
          </p-dropdown>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" *ngIf="!user.sponsor_id">
          <label>Division</label>
          <p-dropdown [options]="divisionSelectItems" formControlName="division_id">
          </p-dropdown>
      </div>

      <div formGroupName="passwords" fxLayout="column">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
          <label>Password</label>
          <input placeholder="Password" pInputText type="password" id="password" formControlName="password">
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
          <label>Repeat password</label>
          <input placeholder="Repeat password" pInputText type="password" id="confirm" formControlName="confirm">
        </div>
        <span *ngIf="userForm.get('passwords').touched && userForm.get('passwords').hasError('nomatch')" 
          [ngStyle]="{'color': 'red'}" align="start">
          Passwords do not match!
        </span>
      </div>
      <br />
      <button mat-raised-button color="primary" type="submit" [disabled]="!userForm.valid">Save</button>
    </form>

</div>
