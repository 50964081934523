import { MatDialogRef } from '@angular/material';
import { HttpHeaders } from '@angular/common/http';
var UploadFileDialogComponent = /** @class */ (function () {
    function UploadFileDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.images = [];
        this.maxSize = 0;
        this._formData = null;
        this._options = null;
    }
    Object.defineProperty(UploadFileDialogComponent.prototype, "formData", {
        get: function () { return this._formData; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadFileDialogComponent.prototype, "options", {
        get: function () { return this._options; },
        enumerable: true,
        configurable: true
    });
    // https://stackoverflow.com/questions/40214772/file-upload-in-angular-2
    UploadFileDialogComponent.prototype.fileChange = function (event) {
        var fileList = event.target.files;
        if (fileList.length > 0) {
            this._formData = new FormData();
            // =========== for many files upload
            // for (let i = 0; i < fileList.length; i++) {
            //     this._formData.append('files[]', fileList[i]);
            // }
            // =========== for single file upload only
            var file = fileList[0];
            if (this.maxSize) {
                if (file.size > this.maxSize * 1048576) {
                    alert("File is too big! Maximum allowed file size is " + this.maxSize + 'MB');
                    this.dialogRef.close(false);
                    return;
                }
                ;
            }
            this._formData.append('file', file, file.name);
            var _options = new HttpHeaders().set('Content-Type', 'multipart/form-data');
            console.log('File is Uploaded from the upload dialog');
            this.dialogRef.close(true);
        }
    };
    return UploadFileDialogComponent;
}());
export { UploadFileDialogComponent };
