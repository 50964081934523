
export class VigiRegion {
    id: number;
    // Area types: CITY, REGION
    area_type: string;
    name: string; // 50
    name_fr: string; // 50
    vigicountry_id: number;
    center_lat: number;
    center_lon: number;
    km: number;
    risk_level: string;

    country_id: number;

    constructor(countryId){
        this.country_id = countryId;
    }
}
