/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/primeng/components/growl/growl.ngfactory";
import * as i2 from "primeng/components/growl/growl";
import * as i3 from "primeng/components/common/messageservice";
import * as i4 from "@angular/common";
import * as i5 from "./growl.component";
import * as i6 from "./growl.service";
var styles_GrowlComponent = [];
var RenderType_GrowlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GrowlComponent, data: {} });
export { RenderType_GrowlComponent as RenderType_GrowlComponent };
function View_GrowlComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p-growl", [["sticky", "sticky"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.messages = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Growl_0, i1.RenderType_Growl)), i0.ɵdid(2, 4636672, null, 0, i2.Growl, [i0.ElementRef, i0.IterableDiffers, [2, i3.MessageService], i0.NgZone], { value: [0, "value"], sticky: [1, "sticky"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; var currVal_1 = "sticky"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_GrowlComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_GrowlComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GrowlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-growl", [], null, null, null, View_GrowlComponent_0, RenderType_GrowlComponent)), i0.ɵdid(1, 114688, null, 0, i5.GrowlComponent, [i6.GrowlService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GrowlComponentNgFactory = i0.ɵccf("app-growl", i5.GrowlComponent, View_GrowlComponent_Host_0, {}, {}, []);
export { GrowlComponentNgFactory as GrowlComponentNgFactory };
