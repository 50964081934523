import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var initialState = {
    pageTitle: 'KWIK Shelf ',
    authToken: null,
    email: null,
    password: null,
    permissions: null,
    stateId: 0,
    howAreYouPobIds: []
};
/**
 * Any component or service that wants to subscribe to changes to, say, the pageTitle$ value can do so:
    this.storeService.pageTitle$.subscribe(title => { doSomethingWithPageTitle(title);

    next function provides a COMPLETELY NEW STATE object to it.
    This new object is based on the CURRENT state via the TypeScript/JavaScript spread operator,
    but replaces the userProfile part of the state with the value passed in to the setUserProfile function.
    This will then cause the userProfile$ observable from above to fire since this value has changed,
    and any listeners to that Observable will be notified of the change.
 */
var StoreService = /** @class */ (function () {
    function StoreService() {
        this.store$ = new BehaviorSubject(initialState);
        this.howAreYouPobIds$ = this.store$.pipe(map(function (state) { return state.howAreYouPobIds; }));
        this.pageTitle$ = this.store$.pipe(map(function (state) { return state.pageTitle; }));
        this.authToken$ = this.store$.pipe(map(function (state) { return state.authToken; }));
        this.email$ = this.store$.pipe(map(function (state) { return state.email; }));
        this.password$ = this.store$.pipe(map(function (state) { return state.password; }));
        this.permissions$ = this.store$.pipe(map(function (state) { return state.permissions; }));
        this.stateId$ = this.store$.pipe(map(function (state) { return state.stateId; }));
    }
    // ======================= Auth token
    StoreService.prototype.setHowAreYouPobIds = function (howAreYouPobIds) {
        this.store$.next(tslib_1.__assign({}, this.store$.value, { howAreYouPobIds: howAreYouPobIds }));
    };
    Object.defineProperty(StoreService.prototype, "howAreYouPobIds", {
        get: function () {
            return this.store$.value.howAreYouPobIds;
        },
        enumerable: true,
        configurable: true
    });
    // ======================= Page title
    StoreService.prototype.setPageTitle = function (pageTitle) {
        var _this = this;
        setTimeout(function () {
            _this.store$.next(tslib_1.__assign({}, _this.store$.value, { pageTitle: pageTitle }));
        }, 0);
    };
    // ======================= Auth token
    StoreService.prototype.setAuthToken = function (authToken) {
        this.store$.next(tslib_1.__assign({}, this.store$.value, { authToken: authToken }));
    };
    Object.defineProperty(StoreService.prototype, "authToken", {
        get: function () {
            return this.store$.value.authToken;
        },
        enumerable: true,
        configurable: true
    });
    StoreService.prototype.clearAuthToken = function () {
        this.store$.next(tslib_1.__assign({}, this.store$.value, { authToken: null }));
    };
    // ======================= Email
    StoreService.prototype.setEmail = function (email) {
        this.store$.next(tslib_1.__assign({}, this.store$.value, { email: email }));
    };
    Object.defineProperty(StoreService.prototype, "email", {
        get: function () {
            return this.store$.value.email;
        },
        enumerable: true,
        configurable: true
    });
    // ======================= Password
    StoreService.prototype.setPassword = function (password) {
        this.store$.next(tslib_1.__assign({}, this.store$.value, { password: password }));
    };
    Object.defineProperty(StoreService.prototype, "password", {
        get: function () {
            return this.store$.value.password;
        },
        enumerable: true,
        configurable: true
    });
    // ======================= Permissions
    StoreService.prototype.setPermissions = function (permissions) {
        this.store$.next(tslib_1.__assign({}, this.store$.value, { permissions: permissions }));
    };
    Object.defineProperty(StoreService.prototype, "permissions", {
        get: function () {
            return this.store$.value.permissions;
        },
        enumerable: true,
        configurable: true
    });
    // ======================= User profile methods 
    // get userProfileId(): number | undefined {
    //     return this.store$.value.userProfile?.id;
    // }
    // setUserProfile(userProfile: BackendUser) {
    //     this.store$.next({ ...this.store$.value, userProfile, });
    // }
    // get userProfile(): BackendUser | undefined {
    //     return this.store$.value.userProfile;
    // }
    // clearUserProfile() {
    //     this.store$.next({ ...this.store$.value, userProfile: null, });
    // }
    // userAvatarUpdated() {
    //     this.store$.next({
    //         ...this.store$.value,
    //         userProfile: Object.assign(this.store$.value.userProfile, {
    //             avatarFileVersion: new Date().getTime().toString(),
    //         }),
    //     });
    // }
    // ======================= State (location state from address)
    StoreService.prototype.setStateId = function (stateId) {
        var _this = this;
        setTimeout(function () {
            _this.store$.next(tslib_1.__assign({}, _this.store$.value, { stateId: stateId }));
        }, 0);
    };
    Object.defineProperty(StoreService.prototype, "stateId", {
        get: function () {
            return this.store$.value.stateId;
        },
        enumerable: true,
        configurable: true
    });
    StoreService.ngInjectableDef = i0.defineInjectable({ factory: function StoreService_Factory() { return new StoreService(); }, token: StoreService, providedIn: "root" });
    return StoreService;
}());
export { StoreService };
