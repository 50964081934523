<button *ngxPermissionsOnly="['CREATE_AIRPORT']" 
  mat-fab (click)="addItem()" 
  style="position: fixed;bottom: 15px; right: 15px "
  pTooltip="Create new airport">
  <mat-icon>add</mat-icon>
</button>

<div class="col-md-10 col-md-offset-1">
  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncAirports | async" [paginator]="true" 
        [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
        paginatorPosition="both" [rows]="25" autoLayout="true" 
        [lazy]="true" [totalRecords]="count" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        dataKey="id"
        [responsive]="true">
        
        <ng-template pTemplate="header">
          <tr>
            <th>
              {{ 'Actions' }}
            </th>
            <th>{{ 'VTS created'}}</th>
            <th>{{ 'ID'}}</th>
            <th [pSortableColumn]="'name'">{{ 'Name'}}
              <p-sortIcon [field]="'name'"></p-sortIcon>
            </th>
            <th>{{ 'City'}}</th>
            <th>{{ 'Country'}}</th>
            <th>{{ 'Latitude'}}</th>
            <th>{{ 'Longitude'}}</th>
            <th>{{ 'Timezone'}}</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'id', 'equals')">
            </th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'name', 'equals')">
            </th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'city_name', 'equals')">
            </th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'country', 'equals')">
            </th>
            <th></th>
            <th></th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'timezone', 'equals')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td>
              <span class="ui-column-title">{{ 'Actions' }}</span>
              <button mat-icon-button pTooltip="Delete airport" 
                  (click)="removeItem(rowData)"
                  *ngIf="!rowData.deleted">
                  <i class="fa fa-trash" style="font-size: 24px;"></i>
              </button>
              <button mat-icon-button pTooltip="Edit airport" (click)="editItem(rowData)">
                <i class="fa fa-edit" style="font-size: 24px;"></i>
              </button>
            </td>
            <!-- VTS created -->
            <td>
              <span class="ui-column-title">{{ 'VTS created' }}</span>
              <span *ngIf="rowData.is_custom_created" style="color:green">
                <strong>Yes</strong>
              </span>
              <span *ngIf="!rowData.is_custom_created">No</span>
            </td>
            <!-- ID -->
            <td>
              <span class="ui-column-title">{{ 'ID' }}</span>
              {{ rowData.id }}
            </td>
            <!-- Name -->
            <td>
              <span class="ui-column-title">{{ 'Name' }}</span>
              {{ rowData.name }}
            </td>
            <!-- City -->
            <td>
              <span class="ui-column-title">{{ 'City' }}</span>
              {{ rowData.city_name }}
            </td>
            <!-- Country -->
            <td>
              <span class="ui-column-title">{{ 'Country' }}</span>
              {{ rowData.country }}
            </td>
            <!-- Latitude -->
            <td>
              <span class="ui-column-title">{{ 'Latitude' }}</span>
              {{ rowData.latitude }}
            </td>
            <!-- Longitude -->
            <td>
              <span class="ui-column-title">{{ 'Longitude' }}</span>
              {{ rowData.longitude }}
            </td>
            <!-- Timezone -->
            <td>
              <span class="ui-column-title">{{ 'Timezone' }}</span>
              {{ rowData.timezone }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

