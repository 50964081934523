import { MatDialogRef } from '@angular/material';
var InputConfirmDialogComponent = /** @class */ (function () {
    function InputConfirmDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this._text = '';
    }
    Object.defineProperty(InputConfirmDialogComponent.prototype, "text", {
        get: function () { return this._text; },
        enumerable: true,
        configurable: true
    });
    return InputConfirmDialogComponent;
}());
export { InputConfirmDialogComponent };
