<ngc-float-button icon="more_vert" 
  style="position: fixed; bottom: 15px; right: 15px; z-index:1; " 
  pTooltip="Options">
  <ngc-float-item-button 
    *ngxPermissionsOnly="['CREATE_VIGI_REGIONS']" 
    pTooltip="Create new area" 
    icon="add" 
    (click)="addRegion()" content="">
  </ngc-float-item-button>

  <ngc-float-item-button 
    *ngxPermissionsOnly="['CREATE_VIGI_REGIONS']"
    pTooltip="Export fact sheets for selected country"
    icon="file_download" 
    (click)="exportFactSheets()" content="">
  </ngc-float-item-button>
  
  <ngc-float-item-button 
    *ngxPermissionsOnly="['DELETE_VIGI_REGIONS']" 
    pTooltip="Import fact sheets for selected country"
    icon="file_upload" 
    (click)="importFactSheets()" content="">
  </ngc-float-item-button>
</ngc-float-button>

<h3 *ngIf="!regions || regions.length==0" >
  Please create area first in order to edit its fact sheets!
</h3>

<div fxLayout="column" *ngIf="regions && regions.length > 0" >

  <div fxLayout="row" fxLayout.lt-sm="column">
    <p-dropdown
      [options]="regionsModifiedCopy"  
      optionLabel="name"
      [(ngModel)]="selectedVigiRegion" (onChange)="onVigiRegionChange()" 
      placeholder="Select area">
    </p-dropdown>
    <p-dropdown [options]="factSheetTypesSI"
      [(ngModel)]="selectedFactSheetTypeId" (onChange)="onFactSheetTypeChange()" 
      placeholder="Select sheet type">
    </p-dropdown>
  </div>

  <br />
  <div fxLayout="column" fxLayoutGap="5px" *ngIf="selectedVigiRegion && selectedFactSheetTypeId">
    <span>Description EN</span>
    <p-editor [(ngModel)]="descr_en" [style]="{'height':'150px'}"></p-editor>
    <br />
    <span>Description FR</span>
    <p-editor [(ngModel)]="descr_fr" [style]="{'height':'150px'}"></p-editor>
  </div>

  <p-button *ngIf="selectedVigiRegion && selectedFactSheetTypeId"
    label="Save" type="button" (click)="saveFactSheetDescr()"></p-button>

</div>


<p-dialog appendTo="body" header="Details" [(visible)]="regionDisplayDialog" [responsive]="true" 
    showEffect="fade" [modal]="true" [width]="600">
    <div class="ui-g ui-fluid" *ngIf="region">
      <div class="ui-g-12">
        <h2 *ngIf="!region.id">Create new 
          <p-dropdown 
            id="area_type"
            [(ngModel)]="region.area_type"
            appendTo="body" 
            [options]="areaTypes">
          </p-dropdown>
        </h2>
        <h2 *ngIf="region.id">Edit {{region.area_type}}</h2>

        <div fxLayout="column">
          <div fxLayout="row">
              <label for="name">Name EN</label>
              <input pInputText id="name" [(ngModel)]="region.name" />
          </div>
          <div fxLayout="row">
            <label for="name_fr">Name FR</label>
            <input pInputText id="name_fr" [(ngModel)]="region.name_fr" />
          </div>
          <div fxLayout="row">
              <label for="lat">Latitude</label>
              <input type="number" pInputText id="lat" [(ngModel)]="region.center_lat" />
          </div>
          <div fxLayout="row">
              <label for="lng">Longitude</label>
              <input type="number" pInputText id="lng" [(ngModel)]="region.center_lon" />
          </div>
          <div fxLayout="row">
              <label for="km">Kilometers</label>
              <input type="number" pInputText id="km" [(ngModel)]="region.km" />
          </div>
          <div class="ui-g-4">
              <button type="button" 
              pButton
              [disabled]="(!region.km || !region.center_lon || !region.center_lat || !region.name || !region.area_type) ? true : false" 
              (click)="saveRegion()" 
              label="Save">
          </button>
          </div>
        </div>
      </div>
    </div>
    <p-table [value]="regions" selectionMode="single" [scrollable]="true" scrollHeight="200px"
        [(selection)]="selectedRegion" (onRowSelect)="onRegionRowSelect($event)">
        <ng-template pTemplate="header">
            <tr><th colspan="2">Created areas</th></tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData >
            <tr [pSelectableRow]="rowData">
                <td>{{rowData.name}}</td>
                <td style="width: 60px" *ngxPermissionsOnly="['DELETE_VIGI_REGIONS']" >
                  <button type="button" pButton icon="fa fa-trash" (click)="deleteRegion(rowData)">
                  </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>
