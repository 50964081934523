import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminLog } from '../models/admin_log';

@Injectable({providedIn: 'root'})
export class AdminLogsService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAllWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/admin_logs', {
            params: httpParams,
            observe: 'body'
        });
    }

}
