import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MarkerWithLabel = require('markerwithlabel')(google.maps);
import * as MarkerClusterer from "@google/markerclusterer";
import { Subject } from 'rxjs';
var MapService = /** @class */ (function () {
    function MapService(httpClient) {
        this.httpClient = httpClient;
        this.bpiIndex = 0;
        this.markers = [];
        this.drawnShapes = [];
        this.polygonDrawingEndSubject = new Subject();
        this.clusterStyles = [{
                url: 'assets/img/zone_circle_icons/zone35.png',
                height: 35,
                width: 35,
                opt_anchor: [16, 0],
                opt_textColor: '#888888',
                textColor: '#FFFFFF'
            },
            {
                url: 'assets/img/zone_circle_icons/zone45.png',
                height: 45,
                width: 45,
                opt_anchor: [24, 0],
                opt_textColor: '#888888',
                textColor: '#FFFFFF'
            },
            {
                url: 'assets/img/zone_circle_icons/zone55.png',
                height: 55,
                width: 55,
                opt_anchor: [32, 0],
                opt_textColor: '#888888',
                textColor: '#FFFFFF'
            }];
    }
    MapService.prototype.setMarkers = function (value) { this.markers = value; };
    MapService.prototype.getMarkers = function () { return this.markers; };
    MapService.prototype.setDrawnShapes = function (value) { this.drawnShapes = value; };
    MapService.prototype.getDrawnShapes = function () { return this.drawnShapes; };
    MapService.prototype.nullMapService = function () {
        this.setMarkers([]);
        this.setDrawnShapes([]);
    };
    MapService.prototype.populateMapWithPath = function (map, markersInfoMap, overlays, oms, markerCluster, bpiList, handleOverlayClick, showPobsEmails) {
        console.log(map);
        var bounds = new google.maps.LatLngBounds();
        var coords = [];
        overlays.length = 0;
        this.addBPIsMarkerAsOverlay(bpiList, coords, oms, overlays, markersInfoMap, showPobsEmails);
        if (overlays && overlays.length > 0) {
            this.extendBoundsAccordingMarkers(overlays, bounds);
            setTimeout(function () {
                map.fitBounds(bounds); // Map object used directly
                if (map.getZoom() > 9) {
                    map.setZoom(9); // Because fit Bounds changes the zoom level
                }
                ;
            }, 1000);
        }
        // this.drawPolyLineBetweenMarkers(overlays, coords);
        markerCluster = this.addOverlaysToCluster(map, overlays, markerCluster);
        return markerCluster;
    };
    MapService.prototype.addOverlaysToCluster = function (map, overlays, markerCluster) {
        if (markerCluster) {
            markerCluster.clearMarkers();
        }
        var markerOverlays = overlays.filter(function (x) { return x instanceof MarkerWithLabel; });
        markerCluster = new MarkerClusterer(map, markerOverlays, { styles: this.clusterStyles, maxZoom: 13 });
        return markerCluster;
    };
    MapService.prototype.drawPolyLineBetweenMarkers = function (overlays, coords) {
        // create polyline between all markers
        var polyline = new google.maps.Polygon({
            paths: coords,
            strokeOpacity: 0.7,
            strokeWeight: 5,
            fillColor: '#FF0000',
            fillOpacity: 0.35
        });
        overlays.push(polyline);
    };
    MapService.prototype.createCircleOverlay = function (region) {
        var circleOptions = {
            center: new google.maps.LatLng({ lat: +region.center_lat, lng: +region.center_lon }),
            strokeColor: '#116199',
            fillColor: '#2271cc',
            fillOpacity: 0.7,
            radius: region.km * 1000
        };
        var circle = new google.maps.Circle();
        circle.setOptions(circleOptions);
        return circle;
    };
    MapService.prototype.extendBoundsAccordingMarkers = function (overlays, bounds) {
        // set bounds, to have all markers in the visible part of the map
        overlays.forEach(function (overlay) {
            if (overlay instanceof google.maps.Marker) {
                bounds.extend(overlay.getPosition());
            }
        });
    };
    MapService.prototype.addVigiRegionsMarkerAsOverlay = function (vigiRegions, coords, oms, overlays, markersInfoMap) {
        var _this = this;
        var count = 1;
        if (!vigiRegions || vigiRegions.length == 0) {
            return;
        }
        vigiRegions.forEach(function (i) {
            var image = '';
            // var image = null;
            // if(i.deviceStatus) {
            //   switch (i.deviceStatus) {
            //     case DeviceStatus.ACTIVATED_STARTED_NORMAL: {
            //       if (i.memberCategory == 'EXPATRIATE') { 
            //         image = "../../../../assets/img/poi_icons/POIgreenE.png";
            //       } else {
            //         image = "../../../../assets/img/poi_icons/POIgreenT.png";
            //       }
            //       break;
            //     }
            //     case DeviceStatus.ACTIVATED_STOPED: {
            //       if (i.memberCategory == 'EXPATRIATE') { 
            //         image = "../../../../assets/img/poi_icons/POIredE.png";
            //       } else {
            //         image = "../../../../assets/img/poi_icons/POIredT.png";
            //       }
            //       break;
            //     }
            //     case DeviceStatus.DEVICES_NO_RECENT_LOCATIONS: {
            //       if (i.memberCategory == 'EXPATRIATE') {
            //         image = "../../../../assets/img/poi_icons/POIyellowE.png";
            //       } else {
            //         image = "../../../../assets/img/poi_icons/POIyellowT.png";
            //       }
            //       break;
            //     }
            //     case DeviceStatus.DEVICES_DEACTIVATED: {
            //       if (i.memberCategory == 'EXPATRIATE') {
            //         image = "../../../../assets/img/poi_icons/POIgreyE.png";
            //       } else {
            //         image = "../../../../assets/img/poi_icons/POIgreyT.png";
            //       }
            //       break;
            //     }
            //     case DeviceStatus.ACTIVATED_STARTED_QUICK: {
            //       if (i.memberCategory == 'EXPATRIATE') {
            //         image = "../../../../assets/img/poi_icons/POIorangeE.png";
            //       } else {
            //         image = "../../../../assets/img/poi_icons/POIorangeT.png";
            //       }
            //       break;
            //     }
            //     case DeviceStatus.ACTIVATED_STARTED_REAL_TIME: {
            //       if (i.memberCategory == 'EXPATRIATE') {
            //         image = "../../../../assets/img/poi_icons/POIblueE.png";
            //       } else {
            //         image = "../../../../assets/img/poi_icons/POIblueT.png";
            //       }
            //       break;
            //     }
            //     default:  "";
            //   }
            // } else {
            //   if(i.memberCategory == 'EXPATRIATE') {
            //     image = '../../../../assets/img/poi_icons/POIwhiteE.png';
            //   } else {
            //     image = '../../../../assets/img/poi_icons/POIwhiteT.png';
            //   }
            // }
            var coord = new google.maps.LatLng({ lat: +i.center_lat, lng: +i.center_lon });
            // keep the coords to create polyline later
            coords.push(coord);
            // This is how we would create basic google marker
            // let marker: google.maps.Marker = new google.maps.Marker({
            //   icon: image,
            //   position: {lat: +i.latitude, lng: +i.longitude},
            //   title: count + '.' + time,
            //   draggable: false,
            //   label: count + ''
            // });
            var marker = new MarkerWithLabel({
                position: { lat: +i.center_lat, lng: +i.center_lon },
                latLng: new Array(+i.center_lat, +i.center_lon),
                draggable: false,
                icon: image,
                labelContent: count + '.' + i.name,
                labelAnchor: new google.maps.Point(22, 0),
                labelClass: "labels",
                labelStyle: { opacity: 0.90 },
                title: '' //wmap.map.markers[i].getTitle()
            });
            var circle = _this.createCircleOverlay(i);
            overlays.push(circle);
            // add marker
            oms.addMarker(marker);
            overlays.push(marker);
            markersInfoMap.set(count, i);
            count++;
        });
    };
    MapService.prototype.addBPIsMarkerAsOverlay = function (bpiList, coords, oms, overlays, markersInfoMap, showPobsEmails) {
        var _this = this;
        var count = 1;
        if (!bpiList || bpiList.length == 0) {
            return;
        }
        bpiList.forEach(function (i) {
            console.log('BPI', i);
            console.log('Type', i.type);
            //var image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
            var image = null;
            var color = null;
            var imageTypeName = null;
            var now = Date.now();
            var timeDifference = now - i.location_time;
            var hours6InMilliSeconds = 3600000 * 6;
            var hours12InMilliSeconds = 3600000 * 12;
            var hours48InMilliSeconds = 3600000 * 48;
            if (timeDifference <= hours6InMilliSeconds) {
                color = 'green';
            }
            else if (timeDifference <= hours12InMilliSeconds) {
                color = 'orange';
            }
            else if (timeDifference <= hours48InMilliSeconds) {
                color = 'red';
            }
            else if (timeDifference > hours48InMilliSeconds) {
                color = 'gray';
            }
            switch (i.type) {
                case 'I_AM_FINE':
                    imageTypeName = 'mapiamfineicon';
                    break;
                case 'SOS_GEOLOCATION':
                    imageTypeName = 'mapsosicon';
                    break;
                case 'PANIC_GEOLOCATION':
                    imageTypeName = 'mappanicicon';
                    break;
                case 'MANUAL_CHECK_IN':
                    imageTypeName = 'manual';
                    break;
                case 'BACKGROUND_CHECK_IN':
                    imageTypeName = 'mapbackgroundlocationicon';
                    break;
                case 'COUNTRY_GEOFENCING_CHECKIN':
                    imageTypeName = 'mapbackgroundlocationicon';
                    break;
                case 'TRAVEL_ARRIVAL_CHECK_IN':
                    imageTypeName = 'mapcheckinicon';
                    break;
                case 'COUNTRY_CHANGE_DETECTION':
                    imageTypeName = 'mapcheckinicon';
                    break;
                default:
                    imageTypeName = '';
                    break;
            }
            if (!color || !imageTypeName) {
                image = null;
            }
            else {
                image = 'assets/img/poi_base32/' + imageTypeName + '32' + color + '.png';
            }
            var time = moment.utc(i.location_time).format("DD/MM/YYYY HH:mm:ss");
            var coord = new google.maps.LatLng({ lat: +i.latitude, lng: +i.longitude });
            // keep the coords to create polyline later
            coords.push(coord);
            // This is how we would create basic google marker
            // let marker: google.maps.Marker = new google.maps.Marker({
            //   icon: image,
            //   position: {lat: +i.latitude, lng: +i.longitude},
            //   title: count + '.' + time,
            //   draggable: false,
            //   label: count + ''
            // });
            var marker = new MarkerWithLabel({
                position: { lat: +i.latitude, lng: +i.longitude },
                latLng: new Array(+i.latitude, +i.longitude),
                draggable: false,
                icon: image,
                labelContent: count + (showPobsEmails ? ('.' + i.email) : '') + '.' + time,
                labelAnchor: new google.maps.Point(22, 0),
                labelClass: "labels",
                labelStyle: { opacity: 0.90 },
                title: '' //wmap.map.markers[i].getTitle()
            });
            // add 
            _this.markers.push(marker);
            oms.addMarker(marker);
            overlays.push(marker);
            markersInfoMap.set(count, i);
            count++;
        });
    };
    // move between markers
    MapService.prototype.moveNextLocation = function (vigiRegions) {
        console.log("Move next");
        if (vigiRegions != null && vigiRegions.length > 1) {
            if (this.bpiIndex < (vigiRegions.length - 1)) {
                this.bpiIndex++;
            }
            return this.setMapCenter(vigiRegions);
        }
    };
    MapService.prototype.movePrevLocation = function (vigiRegions) {
        console.log("Move prev");
        if (vigiRegions != null && vigiRegions.length > 1) {
            if (this.bpiIndex > 0) {
                this.bpiIndex--;
            }
            return this.setMapCenter(vigiRegions);
        }
    };
    MapService.prototype.moveToLastLocation = function (vigiRegions) {
        console.log("Move to Last");
        if (vigiRegions != null && vigiRegions.length > 0) {
            this.bpiIndex = vigiRegions.length - 1;
            return this.setMapCenter(vigiRegions);
        }
    };
    MapService.prototype.moveToFirstLocation = function (vigiRegions) {
        console.log("Move to First");
        if (vigiRegions != null && vigiRegions.length > 0) {
            this.bpiIndex = 0;
            return this.setMapCenter(vigiRegions);
        }
    };
    MapService.prototype.setMapCenter = function (vigiRegions) {
        this.centerLat = +vigiRegions[this.bpiIndex].center_lat;
        this.centerLng = +vigiRegions[this.bpiIndex].center_lon;
        console.log("  Lat:" + this.centerLat + " Lng:" + this.centerLng + "\n");
        return { lat: this.centerLat, lng: this.centerLng };
        //addGlobalMessage(FacesMessage.SEVERITY_INFO, "Location __" + (bpiList.length - this.currentCenterIdx) + "__" 
        //+ " Date:" + df.format(new Date(bpiList.get(this.currentCenterIdx).getLocationTime())));
    };
    // ========================= draw poligon =======================
    MapService.prototype.initDrawingManager = function (map) {
        var _this = this;
        // Initialize drawing manager
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON]
            }
        });
        this.drawingManager.setMap(map);
        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', function (event) {
            _this.drawnShapes.push(event.overlay);
            if (event.type == google.maps.drawing.OverlayType.POLYGON
                ||
                    event.type == google.maps.drawing.OverlayType.RECTANGLE) {
                var polygon_1 = event.overlay;
                var polygonBounds = polygon_1.getPath().getArray();
                // Filter markers within polygon bounds
                var markersInsidePolygon = _this.markers.filter(function (marker) { return google.maps.geometry.poly.containsLocation(marker.getPosition(), polygon_1); });
                _this.polygonDrawingEndSubject.next(markersInsidePolygon); // Emit the result
            }
        });
    };
    MapService.prototype.deactivateDrawingManager = function () {
        this.drawingManager.setDrawingMode(null);
    };
    MapService.prototype.clearDrawingManager = function () {
        this.drawingManager.setMap(null); // Remove the drawing manager
        for (var i = 0; i < this.drawnShapes.length; i++) {
            this.drawnShapes[i].setMap(null); // Remove each drawn shape from the map
        }
        this.drawnShapes = []; // Clear the array
    };
    MapService.prototype.getPolygonDrawingEndObservable = function () {
        return this.polygonDrawingEndSubject.asObservable();
    };
    MapService.ngInjectableDef = i0.defineInjectable({ factory: function MapService_Factory() { return new MapService(i0.inject(i1.HttpClient)); }, token: MapService, providedIn: "root" });
    return MapService;
}());
export { MapService };
