import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { VIGISelectedCountryService } from '../../selected-country.service';
import { GrowlService } from '../../../../_module_dialog/growl_dialog/growl.service';
import { FactSheetTypeService } from '../../../../models_services/fact_sheet_types.service';
import { VigiCountryService } from '../../../../models_services/vigicountry.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-countries-sheets',
  templateUrl: './countries-sheets.component.html',
  styleUrls: ['./countries-sheets.component.css']
})
export class CountriesSheetsComponent implements OnInit, OnDestroy {
  selectedCountryChangedSubscription: Subscription;
  @Input()
  factSheetTypesSI: SelectItem[];
  selectedCountryId: number;
  selectedFactSheetTypeId: number;

  descr_en: string;
  descr_fr: string;

  constructor(
    private vigiSelectedCountryService: VIGISelectedCountryService,
    private growlService: GrowlService,
    private factSheetTypeService: FactSheetTypeService,
    private vigiCountryService: VigiCountryService
  ) { 
    this.selectedCountryId = this.vigiSelectedCountryService.selectedCountryId;
    this.getAllFactSheetTypes();
  }

  ngOnInit() {
    this.selectedCountryChangedSubscription = this.vigiSelectedCountryService.countryIdUpdatedEvent.subscribe(
      (val: number) => {
        this.selectedCountryId = val;
        if (this.selectedFactSheetTypeId) {
          this.getFactSheetDescrForSelectedCountryAndSheetType();
        }
      }
    )
    this.vigiCountryService.importFactSheetsEventEmitter.subscribe(
      ()=>this.getFactSheetDescrForSelectedCountryAndSheetType()
    );

    console.log('FACT Sheet');
    console.log(this.factSheetTypesSI);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.selectedCountryChangedSubscription.unsubscribe();
  }

  getAllFactSheetTypes() {
    this.factSheetTypeService.getAllFactSheetTypes().subscribe(
      (data) => {
        this.factSheetTypesSI = []; 
        data.fact_sheet_types.forEach(f => {
          this.factSheetTypesSI.push({label: f.name_en, value: f.id});
        });
        if(this.factSheetTypesSI && this.factSheetTypesSI.length > 0) {
          this.selectedFactSheetTypeId = this.factSheetTypesSI[0].value;
          this.getFactSheetDescrForSelectedCountryAndSheetType();
        }
      }
    );
  }

  saveFactSheetDescr(){
    let factSheetDescription = {      
      'fact_sheet_type_id': this.selectedFactSheetTypeId,
      'descr_en': this.descr_en,
      'descr_fr': this.descr_fr,
      'country_id': this.selectedCountryId
    }
    this.vigiCountryService.saveDescrForCountry(factSheetDescription, this.selectedCountryId).subscribe(
      (data) => {
        this.growlService.showInfoText('Country description has been updated.')
      },
      (error_data: any) => {
        if(error_data && error_data.data) {
          this.growlService.showError(error_data.error.msg_const);
        } else {
          this.growlService.showErrorText('Server text');
        }
      }
    );
  }

  onFactSheetTypeChange(){
    console.log('Sheet type change');
    this.getFactSheetDescrForSelectedCountryAndSheetType();
  }

  getFactSheetDescrForSelectedCountryAndSheetType(){
    console.log('Get vigi country descr for country and type');
    this.vigiCountryService.getFactSheetDescrForSelectedCountryAndSheetType(
      this.selectedCountryId, this.selectedFactSheetTypeId).subscribe(
        (data) => {
          if(data.fact_sheet_descr) {
            this.descr_en = data.fact_sheet_descr.descr_en;
            this.descr_fr = data.fact_sheet_descr.descr_fr;
          } else {
            this.descr_en = '';
            this.descr_fr = '';
          }
        }
      );
  }

}
