<h3 fxLayoutAlign="left center">Generic Facts</h3>

<p-dropdown [options]="countriesSI"
    [(ngModel)]="selectedCountryId" (onChange)="onCountryChange()" 
    placeholder="Select country">
</p-dropdown>
<hr>

<div fxLayout="row" fxLayout.lt-sm="column"  fxLayoutGap="10px">
  <app-countries-sheets fxFlex="50" fxLayoutAlign="start start"></app-countries-sheets>
  <app-regions-sheets fxFlex="50" fxLayoutAlign="start start"></app-regions-sheets>
</div>
