/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/flex-layout/extended";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./alert.component";
import * as i5 from "./alert.service";
var styles_AlertComponent = [];
var RenderType_AlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "alert": 0, "alert-success": 1, "alert-danger": 2 }), i0.ɵdid(3, 933888, null, 0, i2.DefaultClassDirective, [i0.ElementRef, i3.StyleUtils, i3.MediaMarshaller, i0.IterableDiffers, i0.KeyValueDiffers, i0.Renderer2, [6, i1.NgClass]], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(4, { "alert": 0, "alert-success": 1, "alert-danger": 2 }), (_l()(), i0.ɵted(5, null, ["\n", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.message, (_co.message.type === "success"), (_co.message.type === "error")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 4, 0, _co.message, (_co.message.type === "success"), (_co.message.type === "error")); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.message.text; _ck(_v, 5, 0, currVal_2); }); }
export function View_AlertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i0.ɵdid(1, 114688, null, 0, i4.AlertComponent, [i5.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i0.ɵccf("app-alert", i4.AlertComponent, View_AlertComponent_Host_0, {}, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
