import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

@Component({
    selector: 'app-input-confirm-dialog',
    templateUrl: 'input-confirm-dialog.html',
})
/** The component is a basic html markup of the actual look of our dialog.
 * The dialog will be placed dead centre in a container with an overlay.
 *
 * Note that ConfirmDialogComponent has been added to the entryComponents array.
 * You need to add any component that is dynamically
 * generated by the component factory resolver to this array.
 */
export class InputConfirmDialogComponent {

    public title: string;
    public message: string;
    _text = '';

    constructor(public dialogRef: MatDialogRef<InputConfirmDialogComponent>) {
    }

    get text(): string { return this._text; }

}
