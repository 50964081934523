<button mat-fab (click)="addItem()" 
  style="position: fixed;bottom: 15px; right: 15px "
  pTooltip="Create new tips">
  <mat-icon>add</mat-icon>
</button>

<h3 fxLayoutAlign="left center">Tips for safe travel abroad</h3>
<div *ngIf="tips">
  <p-tabView (onChange)="onTabChange($event)" [style]="{'max-width':'100%'}">
    <p-tabPanel *ngFor="let tip of tips; let first = first;" 
      [header]="tip.name_en" [selected]="first ? true: false" >
      <div fxLayout="column">
        <div fxLayout="column">
          <span>Tab name used on backend</span>
          <input type="text" pInputText [(ngModel)]="tip.name_en" />
        </div>
        <div fxLayout="column">
          <span>Title EN</span>
          <input type="text" pInputText [(ngModel)]="tip.title_en" />
        </div>
        <div fxLayout="column">
          <span>Description EN</span>
          <p-editor [(ngModel)]="tip.text_en" [style]="{'height':'200px'}"></p-editor>
        </div>
        <div fxLayout="column">
          <span>Title FR</span>
          <input type="text" pInputText [(ngModel)]="tip.title_fr" />
        </div>
        <div fxLayout="column">
          <span>Description FR</span>
          <p-editor [(ngModel)]="tip.text_fr" [style]="{'height':'200px'}"></p-editor>
        </div>
        <div fxLayout="row">
          <p-button label="Save" type="button" 
            (click)="saveTip(tip)"></p-button>
          &nbsp;
          <p-button label="Delete" type="button" 
            (click)="deleteTip(tip)"></p-button>
        </div>
      </div>
    </p-tabPanel>
    <div style="height: 200px;"></div>
  </p-tabView>

  <div style="margin-top: -200px; padding:0.30em" >
    <p-card>
      <h2>Upload tip image in JPEG format</h2>
      <div style="display: inline-block">
          <input type="file" (change)="imageChange($event)" placeholder="Upload tip image" accept=".jpeg,.jpg">
          <mat-error *ngIf="imageError">{{ imageError }}</mat-error>
      </div>
      <div>
        <div style="float: left;">
          <img [src]="tipImgUrl + '?' + timestamp" *ngIf="tipImgUrl" alt=" &nbsp;There is not image uploaded for this tip." />
        </div>
        <button mat-raised-button color="primary" 
          type="button" style="float:left; margin-top: 66px; margin-left:10px;" 
            (click)="uploadNewImageForTip()">
            Upload image 
        </button>
        <div class="clear"></div>
      </div>
    </p-card>
  </div>
  
</div>
