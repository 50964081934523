<div style="padding:5px" fxLayoutAlign="center">
  <div fxLayout="column">

    <form [formGroup]="airportForm" (ngSubmit)="onSubmit()">
      <h3 class="form_header">Airport form</h3>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Airport ID (3 letters)</label>
        <input placeholder="Airport 3 letters code" 
          pInputText type="text"
          id="id" formControlName="id">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Name</label>
        <input placeholder="Name" pInputText type="text" 
          id="name" formControlName="name">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>City</label>
        <input placeholder="City" pInputText type="text" 
          id="city_name" formControlName="city_name">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Country</label>
        <input placeholder="Country" pInputText type="text" 
          id="country" formControlName="country">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Country code (2 letters)</label>
        <input placeholder="Country code" pInputText type="text" 
          id="country_code" formControlName="country_code">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Latitude</label>
        <input placeholder="Latitude" pInputText type="number" 
          id="latitude" formControlName="latitude">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Longitude</label>
        <input placeholder="Longitude" pInputText type="number" 
          id="longitude" formControlName="longitude">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Timezone</label>
        <input placeholder="Europe/Paris" pInputText type="text" 
          id="timezone" formControlName="timezone">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Is VTS created?</label>
        <p-checkbox formControlName="is_custom_created"
          binary="true"></p-checkbox>
      </div>

      <button mat-raised-button color="primary" type="submit" 
        [disabled]="!airportForm.valid">Save</button>
    </form>

  </div>
</div>
