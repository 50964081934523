import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var CountryService = /** @class */ (function () {
    function CountryService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    CountryService.prototype.getAll = function () {
        return this.httpClient.get(this.config.apiUrl + '/countries');
    };
    CountryService.prototype.getAllWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/countries', {
            params: httpParams,
            observe: 'body'
        });
    };
    CountryService.prototype.getById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/countries/' + _id);
    };
    CountryService.prototype.getCountrySubscribedUsers = function (countryId) {
        return this.httpClient.get(this.config.apiUrl + '/countries/' + countryId + '/subscribed_users');
    };
    CountryService.prototype.create = function (country) {
        return this.httpClient.post(this.config.apiUrl + '/countries', country);
    };
    CountryService.prototype.update = function (entity) {
        return this.httpClient.put(this.config.apiUrl + '/countries/' + entity.id, entity);
    };
    CountryService.prototype.delete = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/countries/' + _id);
    };
    CountryService.prototype.saveImageForCountry = function (countryId, formData) {
        console.log(formData);
        return this.httpClient.post(this.config.apiUrl + '/countries/' + countryId + '/image', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    };
    CountryService.ngInjectableDef = i0.defineInjectable({ factory: function CountryService_Factory() { return new CountryService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: CountryService, providedIn: "root" });
    return CountryService;
}());
export { CountryService };
