import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var ApplicationService = /** @class */ (function () {
    function ApplicationService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    ApplicationService.prototype.getApplicationConfigName = function () {
        return this.httpClient.get(this.config.apiUrl + '/application/config_name');
    };
    // htmls GET start
    ApplicationService.prototype.getFAQ = function (versionNumber) {
        return this.httpClient.get(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/faq');
    };
    ApplicationService.prototype.getSupport = function (versionNumber) {
        return this.httpClient.get(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/support');
    };
    ApplicationService.prototype.getTAndC = function (versionNumber) {
        return this.httpClient.get(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/tandc');
    };
    ApplicationService.prototype.getPersonalDataUse = function (versionNumber) {
        return this.httpClient.get(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/personal_data_use');
    };
    // htmls GET END
    ApplicationService.prototype.getSmtpSettings = function () {
        return this.httpClient.get(this.config.apiUrl + '/application/smtp_settings');
    };
    // htmls updates start
    ApplicationService.prototype.updateFAQ = function (appText, versionNumber) {
        return this.httpClient.put(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/faq', appText);
    };
    ApplicationService.prototype.updateSupport = function (appText, versionNumber) {
        return this.httpClient.put(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/support', appText);
    };
    ApplicationService.prototype.updateTAndC = function (appText, versionNumber) {
        return this.httpClient.put(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/tandc', appText);
    };
    ApplicationService.prototype.updatePersonalData = function (appText, versionNumber) {
        return this.httpClient.put(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/personal_data_use', appText);
    };
    // htmls updates END
    ApplicationService.prototype.restoreFromBackup = function () {
        return this.httpClient.post(this.config.apiUrl + '/application/restore_from_backup', {});
    };
    ApplicationService.prototype.updateEmailPassword = function (email, password) {
        return this.httpClient.put(this.config.apiUrl + '/application/sender_email_password', { password: password, email: email });
    };
    // locationExpirityDays
    ApplicationService.prototype.getLocationsExpirityTime = function () {
        return this.httpClient.get(this.config.apiUrl + '/application/location_expirity_days');
    };
    ApplicationService.prototype.updateLocationsExpirityTime = function (locationExpirityDays) {
        return this.httpClient.put(this.config.apiUrl + '/application/location_expirity_days', { location_expirity_days: locationExpirityDays });
    };
    // travelsExpirityDays
    ApplicationService.prototype.getTravelsExpirityTime = function () {
        return this.httpClient.get(this.config.apiUrl + '/application/travel_expirity_days');
    };
    ApplicationService.prototype.updateTravelsExpirityTime = function (travelExpirityDays) {
        return this.httpClient.put(this.config.apiUrl + '/application/travel_expirity_days', { travel_expirity_days: travelExpirityDays });
    };
    // showAdminLogInfo
    ApplicationService.prototype.getAdminLogsExpirityTime = function () {
        return this.httpClient.get(this.config.apiUrl + '/application/admin_logs_expirity_days');
    };
    ApplicationService.prototype.updateAdminLogsExpirityTime = function (adminLogsExpirityDays) {
        return this.httpClient.put(this.config.apiUrl + '/application/admin_logs_expirity_days', { admin_logs_expirity_days: adminLogsExpirityDays });
    };
    ApplicationService.ngInjectableDef = i0.defineInjectable({ factory: function ApplicationService_Factory() { return new ApplicationService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: ApplicationService, providedIn: "root" });
    return ApplicationService;
}());
export { ApplicationService };
