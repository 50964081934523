<div style="padding:5px" fxLayoutAlign="center">
  <div fxLayout="column">

    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <h3 class="form_header">{{'BACKEND_USER' | translate}}</h3>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Name</label>
        <input placeholder="Name" pInputText type="text" id="name" formControlName="firstname">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Surname</label>
        <input placeholder="Surname" pInputText type="text" id="lastname" formControlName="lastname">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Phone</label>
        <input placeholder="Phone" pInputText type="phone" id="phone" formControlName="phone">
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Email</label>
        <input placeholder="Email" type="email" pInputText type="text" id="email" formControlName="email">
      </div>
      <span *ngIf="!userForm.get('email').valid && userForm.get('email').touched" [ngStyle]="{'color': 'red'}" 
        align="start">
        Please insert valid email address!
      </span>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <label>Role</label>
        <p-dropdown [options]="roles" formControlName="role" (onChange)="onRoleChange()" ></p-dropdown>
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" *ngIf="renderGroupsDropdown">
          <label>Group</label>
          <p-dropdown [options]="groupsSelectItems"  formControlName="group_id"
              (onChange)="onGroupChange()">
          </p-dropdown>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" *ngIf="renderPolesDropdown">
          <label>Pole</label>
          <p-dropdown [options]="polesSelectItems" formControlName="pole_id"
            (onChange)="onPoleChange()">
          </p-dropdown>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" *ngIf="renderDivisionDropdown">
          <label>Division</label>
          <p-dropdown [options]="divisionSelectItems" formControlName="division_id">
          </p-dropdown>
      </div>
      
      <div formGroupName="passwords"  fxLayout="column">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
          <label>Password</label>
          <input placeholder="Password" pInputText type="password" id="password" formControlName="password">
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
          <label>Repeat password</label>
          <input placeholder="Repeat password" pInputText type="password" id="confirm" formControlName="confirm">
        </div>
        <span *ngIf="userForm.get('passwords').touched && userForm.get('passwords').hasError('nomatch')" 
          [ngStyle]="{'color': 'red'}" align="start">
          Passwords do not match!
        </span>
      </div>

      <button mat-raised-button color="primary" type="submit" [disabled]="!userForm.valid">Save</button>
    </form>

  </div>
</div>
