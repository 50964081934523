import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import { HttpResponse } from '@angular/common/http';

export function openFileForDownload(data: HttpResponse<Blob>) {
    const content_type = data.headers.get('Content-type');
    const x_filename = data.headers.get('x-filename');
    saveAs(data.body, x_filename);
}

export function saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}