import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var FactSheetTypeService = /** @class */ (function () {
    function FactSheetTypeService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    FactSheetTypeService.prototype.getAllFactSheetTypes = function () {
        return this.httpClient.get(this.config.apiUrl + '/fact_sheets_types/all');
    };
    FactSheetTypeService.ngInjectableDef = i0.defineInjectable({ factory: function FactSheetTypeService_Factory() { return new FactSheetTypeService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: FactSheetTypeService, providedIn: "root" });
    return FactSheetTypeService;
}());
export { FactSheetTypeService };
