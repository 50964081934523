import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, pipe, combineLatest, concat, of } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TravelService } from '../../../models_services/travel.service';
import { LazyLoadEvent } from 'primeng/components/common/api';
import { tap, map, bufferCount } from 'rxjs/operators';
import { MobileUserService } from '../../../models_services/mobile-users.service';
import { MobileUser } from '../../../models/mobile-user';
import { Travel } from '../../../models/travel';
import { VIGISelectedCountryService } from '../../risk_assessment/selected-country.service';
import { openFileForDownload } from '../../../utils/file';
import { GrowlService } from 'src/app/_module_dialog/growl_dialog/growl.service';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mobile-user-trips',
  templateUrl: './mobile-user-trips.component.html',
  styleUrls: ['./mobile-user-trips.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MobileUserTripsComponent implements OnInit {
  faFileCsv = faFileCsv;
  user: MobileUser;
  itIsSingleUserPage = false;
  userId = 0;
  showCorporateUsersTable = 1;
  asyncTravels: Observable<Travel[]>;
  subscription: Subscription;
  count: 0;
  currentPage = 1;
  loading: boolean;
  displayDialog: boolean = false;

  dialogCallback = null;
  travelData = null;

  sortProperties: any[];
  sortBy: string;
  orderAsc: string;
  params:any = {}; 
  waitingParameterResolve = true;

  options = {
    center: {lat: 48.830772399902344, lng: 2.8097193241119385},
    zoom: 9
  };
  overlays = [];
  map: google.maps.Map;

  constructor(
    private route: ActivatedRoute,
    private travelService: TravelService,
    private userService: MobileUserService,
    private router: Router,
    private vigiSelectedCountryService: VIGISelectedCountryService,
    private growlService: GrowlService
  ) { }

  ngOnInit() {
    try {
      this.itIsSingleUserPage = !!parseInt(this.route.snapshot.paramMap.get('id'));
      if(this.itIsSingleUserPage) {
        this.userId = parseInt(this.route.snapshot.paramMap.get('id'));
      }
    } catch (error) {
      console.log('Users locations page');
    }
    if(this.itIsSingleUserPage) {
      this.params = {
        page: 1, per_page: 10, 
        order: 'departs_at',
      };
    } else {
      this.params = {
        page: 1, per_page: 10,
        order: 'departs_at',
        email: undefined,
        firstname: undefined,
        lastname: undefined,
        is_external_contractor: 0,
      };
    }
    this.options = {
      center: {lat: 36.890257, lng: 30.707417},
      zoom: 12
    };
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        
        if (params.hasOwnProperty('id')) {
          this.userService.getById(params['id']).subscribe(data => {
            this.waitingParameterResolve = false;
            console.log(data.user);
            this.user = data.user;
            this.loadTravels();
          });
        } else {
          this.waitingParameterResolve = false;
          this.loadTravels();
        }
      }
    );
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        if (params.hasOwnProperty('id')) {
          this.userService.getById(params['id']).subscribe(data => {
            console.log(data.user);
            this.user = data.user;
            this.loadTravels();
          });
        }
      }
    );
  }

  setMap(event) {  
    this.map = event.map;
    this.map.setCenter(this.options.center);
    this.dialogCallback(this.travelData);
  }

  private loadTravels() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.params.page = page;
    let userId = (this.user) ? this.user.id  : 0;

    if(userId != 0) {
      this.asyncTravels = this.travelService.getAllTravelsForUserWithParams(this.params, this.user.id).pipe(
        tap((data: any) => {
          this.count = data.count;
          this.loading = false;
          this.currentPage = page;
        }),
        map( (data: any) => {
          return data.travels;
        })
      );
    } else {
      this.asyncTravels = this.travelService.getTravelsWithParams(this.params).pipe(
        tap((data: any) => {
          this.count = data.count;
          this.loading = false;
          this.currentPage = page;
        }),
        map( (data: any) => {
          return data.travels;
        })
      );
    }
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    if (this.waitingParameterResolve) {
      return;
    }
    this.params.email = event.filters['email'] ? event.filters['email'].value : undefined;
    this.params.firstname = event.filters['firstname'] ? event.filters['firstname'].value : undefined;
    this.params.lastname = event.filters['lastname'] ? event.filters['lastname'].value : undefined;
    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

  handleOverlayClick(event) {
    console.log(event.overlay) // event.map, event.overlay, event.originalEvent
  }

  showDialog() {
    this.displayDialog = true;
  }

  onDepartsCountryClick(travelData) {
    console.log('onDepartsCountryClick');
    this.vigiSelectedCountryService.setCountryId(travelData.departs_country.id);
    this.router.navigate(
      ['/home/risk_assessment/vigi_country', { outlets: { primary: null,  vigi: ['vigi_country_map'] } }]
    );
  }

  onArrivesCountryClick(travelData) {
    this.vigiSelectedCountryService.setCountryId(travelData.arrives_country.id);
    this.router.navigate(
      ['/home/risk_assessment/vigi_country', { outlets: { primary: null,  vigi: ['vigi_country_map'] } }]
    );
  }

  onAirportClick(airport, travelData) {
    if (this.map) {
      const latitude = airport.latitude;
      const longitude = airport.longitude;
      let center = new google.maps.LatLng(latitude, longitude);
      const marker = new google.maps.Marker({
        position: {lat: latitude, lng: longitude}, 
        title: airport.country + ':' + airport.city_name  
      });
      this.overlays.push(marker);
      this.map.setCenter(center);
    } else {
      this.travelData = travelData;
      this.dialogCallback = this.onAirportClick;
    }
    this.showDialog();
  }

  showCorporateUsers() {
    if (!this.showCorporateUsersTable) {
      this.showCorporateUsersTable = 1;
      this.params.is_external_contractor = 0;
      this.getPage(1);
    }
  }

  showSponsoredUsers() {
    if (this.showCorporateUsersTable) {
      this.showCorporateUsersTable = 0;
      this.params.is_external_contractor = 1;
      this.getPage(1);
    }
  }

  downloadTravelsCSV() {
    this.travelService.downloadTravelsCSV(this.showCorporateUsersTable).subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

}
