import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var GroupService = /** @class */ (function () {
    function GroupService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    GroupService.prototype.getAllGroups = function () {
        return this.httpClient.get(this.config.apiUrl + '/groups/all');
    };
    GroupService.prototype.getAllAccessibleGroups = function () {
        return this.httpClient.get(this.config.apiUrl + '/groups/all_accessible');
    };
    GroupService.prototype.getAllAccessibleGroupsWithInner = function () {
        return this.httpClient.get(this.config.apiUrl + '/groups/all_accessible/with_inner');
    };
    // Get by ID
    GroupService.prototype.getGroupById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/groups/' + _id);
    };
    GroupService.prototype.getCountOfUsersForGroups = function (groupIds) {
        return this.httpClient.post(this.config.apiUrl + '/groups/stats_for_users', { 'group_ids': groupIds });
    };
    // DELETE
    GroupService.prototype.deleteGroup = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/groups/' + _id);
    };
    // UPDATE
    GroupService.prototype.updateGroup = function (group) {
        return this.httpClient.put(this.config.apiUrl + '/groups/' + group.id, group);
    };
    // CREATE
    GroupService.prototype.createGroup = function (group) {
        return this.httpClient.post(this.config.apiUrl + '/groups', group);
    };
    // Download all groups, division and pole names into csv
    GroupService.prototype.downloadUsersCSV = function () {
        return this.httpClient.get(this.config.apiUrl + '/groups/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    GroupService.prototype.exportAccessibleGroupsCSV = function () {
        return this.httpClient.get(this.config.apiUrl
            + '/groups/all_accessible/with_inner/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    GroupService.ngInjectableDef = i0.defineInjectable({ factory: function GroupService_Factory() { return new GroupService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: GroupService, providedIn: "root" });
    return GroupService;
}());
export { GroupService };
