<h3 mat-dialog-title>{{ title }}</h3>
<div >
    {{ message }}
    
    <mat-form-field style="width:360px">
        <input [(ngModel)]="_text" matInput type="text" id="_text" >
    </mat-form-field>
    
    <div>
        <button type="button" mat-raised-button  (click)="dialogRef.close(true)">OK</button>
        <button type="button" mat-button (click)="dialogRef.close()">Cancel</button>
    </div>
</div>
