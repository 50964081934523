<div class="col-md-10 col-md-offset-1">
  <button pButton type="button" label="Corporate" *ngIf="!itIsSingleUserPage"
    [ngClass]="{'ui-button-secondary': ((showCorporateUsersTable == 0)? true: false)}"
    (click)="showCorporateUsers()">
  </button>
  <button pButton type="button" label="Sponsored" *ngIf="!itIsSingleUserPage"
    [ngClass]="{'ui-button-secondary': ((showCorporateUsersTable == 1)? true: false)}"
    (click)="showSponsoredUsers()">
  </button>

  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>

      <p-card header="User info" [style]="{width: '360px'}" [hidden]="!itIsSingleUserPage">
        <div>
          <label><b>Given name:</b></label>
           {{ user?.firstname }}
          <br />
          <label><b>Surname:</b></label>
           {{ user?.lastname }}
          <br />
          <label><b>Email:</b></label>
          {{ user?.email }}
        </div>
      </p-card>
      
      <p-table #dt [value]="asyncLocations | async" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]"
        paginatorPosition="both" [rows]="10" autoLayout="true" [lazy]="true" [totalRecords]="count" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        [(selection)]="selectedLocation" selectionMode="single" dataKey="id"
        (onRowSelect)="onRowSelect()" [responsive]="true">
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
              <td [attr.colspan]="8"> No records found </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'firstname'">{{ 'Given name'}}
              <p-sortIcon [field]="'firstname'"></p-sortIcon>
            </th>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'lastname'">{{ 'Surname'}}
              <p-sortIcon [field]="'lastname'"></p-sortIcon>
            </th>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'email'">{{ 'Email'}}
              <p-sortIcon [field]="'email'"></p-sortIcon>
            </th>
            <th>{{ 'Country'}}</th>
            <th>{{ 'City'}}</th>
            <th>{{ 'Location type'}}</th>
            <th>{{ 'Latitude'}}</th>
            <th>{{ 'Longitude'}}</th>
            <th>{{ 'Battery level'}}</th>
            <th [pSortableColumn]="'time'">
              {{ 'Time (GMT)'}} 
              <p-sortIcon [field]="'time'"></p-sortIcon></th>
            <th>{{ 'Regions in country'}}</th>
          </tr>
          <tr>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'firstname', 'equals')">
            </th>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'lastname', 'equals')">
            </th>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'equals')">
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td [hidden]="itIsSingleUserPage">
              <span class="ui-column-title">{{ 'Given name' }}</span>
              {{ rowData.firstname }}
            </td>
            <td [hidden]="itIsSingleUserPage">
              <span class="ui-column-title">{{ 'Surname' }}</span>
              {{ rowData.lastname }}
            </td>
            <td [hidden]="itIsSingleUserPage">
              <span class="ui-column-title">{{ 'VTS Email' }}</span>
              {{ rowData.email }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Country' }}</span>
              {{ rowData.country }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'City' }}</span>
              {{ rowData.city }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Location type' }}</span>
              {{ rowData.type_str }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Latitude' }}</span>
              {{ rowData.latitude }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Longitude' }}</span>
              {{ rowData.longitude }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Battery level' }}</span>
              {{ rowData.battery_level }}
              <span *ngIf="rowData.battery_level">%</span>
            </td>
            <td>
              <span class="ui-column-title">{{ 'Time (GMT)' }}</span>
              {{ rowData.time }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Regions in country' }}</span>
              <span *ngFor="let region of rowData.regions; let isLast=last">
                {{region.name}}{{isLast ? '' : ', '}}
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>


<p-dialog header="Location" [(visible)]="displayDialog" [style]="{width: '600px', height: '400px'}">
  <p-gmap id="googleMap" #gmap [options]="options" 
    [style]="{'width':'600px','height':'394px'}" 
    [overlays]="overlays" (onMapReady)="setMap($event); " 
    (onOverlayClick)="handleOverlayClick($event)">
</p-gmap>
</p-dialog>



