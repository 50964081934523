import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppText } from '../models/app-text';


@Injectable({providedIn: 'root'})
export class ApplicationService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getApplicationConfigName(){
        return this.httpClient.get<any>(this.config.apiUrl + '/application/config_name');
    }


    // htmls GET start
    getFAQ(versionNumber) {
        return this.httpClient.get<any>(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/faq');
    }

    getSupport(versionNumber) {
        return this.httpClient.get<any>(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/support');
    }

    getTAndC(versionNumber) {
        return this.httpClient.get<any>(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/tandc');
    }

    getPersonalDataUse(versionNumber) {
        return this.httpClient.get<any>(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/personal_data_use');
    }
    // htmls GET END


    getSmtpSettings() {
        return this.httpClient.get<any>(this.config.apiUrl + '/application/smtp_settings');
    }

    // htmls updates start
    updateFAQ(appText: AppText, versionNumber) {
        return this.httpClient.put(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/faq', appText);
    }

    updateSupport(appText: AppText, versionNumber) {
        return this.httpClient.put(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/support', appText);
    }

    updateTAndC(appText: AppText, versionNumber) {
        return this.httpClient.put(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/tandc', appText);
    }

    updatePersonalData(appText: AppText, versionNumber) {
        return this.httpClient.put(this.config.apiUrl + '/application/app_text_versions/' + versionNumber + '/personal_data_use', appText);
    }
    // htmls updates END

    restoreFromBackup() {
        return this.httpClient.post(this.config.apiUrl + '/application/restore_from_backup', {});
    }

    updateEmailPassword(email: string, password: string) {
        return this.httpClient.put(this.config.apiUrl + '/application/sender_email_password', 
        {password: password, email: email});
    }

    // locationExpirityDays
    getLocationsExpirityTime() {
        return this.httpClient.get<any>(this.config.apiUrl + '/application/location_expirity_days');
    }
    updateLocationsExpirityTime(locationExpirityDays) {
        return this.httpClient.put(this.config.apiUrl + '/application/location_expirity_days', 
        {location_expirity_days: locationExpirityDays});
    }
    // travelsExpirityDays
    getTravelsExpirityTime() {
        return this.httpClient.get<any>(this.config.apiUrl + '/application/travel_expirity_days');
    }
    updateTravelsExpirityTime(travelExpirityDays) {
        return this.httpClient.put(this.config.apiUrl + '/application/travel_expirity_days', 
        {travel_expirity_days: travelExpirityDays});
    }
    // showAdminLogInfo
    getAdminLogsExpirityTime() {
        return this.httpClient.get<any>(this.config.apiUrl + '/application/admin_logs_expirity_days');
    }
    updateAdminLogsExpirityTime(adminLogsExpirityDays) {
        return this.httpClient.put(this.config.apiUrl + '/application/admin_logs_expirity_days', 
        {admin_logs_expirity_days: adminLogsExpirityDays});
    }

    // getSwaggerUI() {
    //     return this.httpClient.get(this.config.apiUrl + '/swagger-ui/', {
    //         headers: new HttpHeaders().set('Accept', 'text/plain' ),
    //         observe: 'response',
    //         responseType: 'text'
    //     });
    // }

}
