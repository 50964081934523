<h3>Risk level definitions</h3>
<div *ngIf="vigidescr">
  <p-tabView (onChange)="onTabChange()" [style]="{'max-width':'100%'}">
    <p-tabPanel header="Low Risk" [selected]="true" [headerStyle]="{'background-color':'skyblue'}">
      <div fxLayout="column">
        <div fxLayout="column"><span>Description EN</span>
          <p-editor [(ngModel)]="vigidescr.b_descr_en" [style]="{'height':'200px'}"></p-editor>
        </div>
        <div fxLayout="column"><span>Description FR</span>
          <p-editor [(ngModel)]="vigidescr.b_descr_fr" [style]="{'height':'200px'}"></p-editor>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Moderate Risk" [headerStyle]="{'background-color':'yellow'}">
      <div fxLayout="column">
        <div fxLayout="column"><span>Description EN</span>
          <p-editor [(ngModel)]="vigidescr.y_descr_en" [style]="{'height':'200px'}"></p-editor>
        </div>
        <div fxLayout="column"><span>Description FR</span>
          <p-editor [(ngModel)]="vigidescr.y_descr_fr" [style]="{'height':'200px'}"></p-editor>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="High Risk" [headerStyle]="{'background-color':'orange'}">
      <div fxLayout="column">
        <div fxLayout="column"><span>Description EN</span>
          <p-editor [(ngModel)]="vigidescr.o_descr_en" [style]="{'height':'200px'}"></p-editor>
        </div>
        <div fxLayout="column"><span>Description FR</span>
          <p-editor [(ngModel)]="vigidescr.o_descr_fr" [style]="{'height':'200px'}"></p-editor>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Very High Risk" [headerStyle]="{'background-color':'red'}">
      <div fxLayout="column">
        <div fxLayout="column"><span>Description EN</span>
          <p-editor [(ngModel)]="vigidescr.r_descr_en" [style]="{'height':'200px'}"></p-editor>
        </div>
        <div fxLayout="column"><span>Description FR</span>
          <p-editor [(ngModel)]="vigidescr.r_descr_fr" [style]="{'height':'200px'}"></p-editor>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="War Zone" [headerStyle]="{'background-color':'black', 'color': 'white'}">
      <div fxLayout="column">
        <div fxLayout="column"><span>Description EN</span>
          <p-editor [(ngModel)]="vigidescr.k_descr_en" [style]="{'height':'200px'}"></p-editor>
        </div>
        <div fxLayout="column"><span>Description FR</span>
          <p-editor [(ngModel)]="vigidescr.k_descr_fr" [style]="{'height':'200px'}"></p-editor>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <p-button label="Save" type="button" (click)="saveDescr()"></p-button>
</div>
