import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var DivisionService = /** @class */ (function () {
    function DivisionService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    DivisionService.prototype.getAllDivisions = function () {
        return this.httpClient.get(this.config.apiUrl + '/divisions/all');
    };
    DivisionService.prototype.getAllAccessibleDivisions = function (poleId, editedUserId) {
        editedUserId = editedUserId ? editedUserId : 0;
        return this.httpClient.get(this.config.apiUrl + '/divisions/all_accessible/' + poleId + '/edited_user/' + editedUserId);
    };
    DivisionService.prototype.getDivisionsForPoles = function (poles) {
        return this.httpClient.post(this.config.apiUrl + '/divisions/get_by_poles', { 'poles': poles });
    };
    DivisionService.prototype.getCountOfUsersForDivisions = function (divisionIds) {
        return this.httpClient.post(this.config.apiUrl + '/divisions/stats_for_users', { 'division_ids': divisionIds });
    };
    // Get by ID
    DivisionService.prototype.getDivisionById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/divisions/' + _id);
    };
    // DELETE
    DivisionService.prototype.deleteDivision = function (_id) {
        return this.httpClient.delete(this.config.apiUrl + '/divisions/' + _id);
    };
    // UPDATE
    DivisionService.prototype.updateDivision = function (division) {
        return this.httpClient.put(this.config.apiUrl + '/divisions/' + division.id, division);
    };
    // CREATE
    DivisionService.prototype.createDivision = function (division) {
        return this.httpClient.post(this.config.apiUrl + '/divisions', division);
    };
    DivisionService.ngInjectableDef = i0.defineInjectable({ factory: function DivisionService_Factory() { return new DivisionService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: DivisionService, providedIn: "root" });
    return DivisionService;
}());
export { DivisionService };
