<button mat-fab (click)="downloadLogsCSV()" style="position: fixed;bottom: 15px; right: 15px "
  pTooltip="Download logs csv">
  <mat-icon>file_download</mat-icon>
</button>

<div class="col-md-10 col-md-offset-1">
  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncSSOLogs | async" [paginator]="true" [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
        paginatorPosition="both" [rows]="25" autoLayout="true" [lazy]="true" [totalRecords]="count" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        [(selection)]="selectedSSOLogs" dataKey="id"
        [responsive]="true">
        
        <ng-template pTemplate="header">
          <tr>
            <th>
              <p-tableHeaderCheckbox style="float:left"></p-tableHeaderCheckbox>
              {{ 'Actions' }}
            </th>
            <th [pSortableColumn]="'time'">{{ 'Date created (GMT))'}}<p-sortIcon [field]="'time'"></p-sortIcon></th>
            <th>{{ 'Message'}}</th>
            <th>{{ 'SSO EMail'}}</th>
            <th>{{ 'Same as VTS email?'}}</th>
            <th>
              {{ 'Has password?'}}
              <button mat-icon-button pTooltip="Has password info" 
                (click)="showHasPasswordInfo()">
                <i class="fa fa-info" style="font-size: 24px;"></i>
              </button>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData">
            <td>
              <span class="ui-column-title">{{ 'Actions' }}</span>
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              &nbsp;
              <button mat-icon-button pTooltip="Delete log" (click)="removeItem(rowData)">
                  <i class="fa fa-trash" style="font-size: 24px;"></i>
              </button>
              <button mat-icon-button pTooltip="Link SSO email" (click)="showDropDownOfCorporateUsers(rowData, rowIndex)">
                <i class="fa fa-link" style="font-size: 24px;"></i>
              </button>
              <div fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="10px" 
                *ngIf="showDropDownForRow == rowIndex">
                <label>In the dropdown below there are corporate users that still don't have successful SSO authorization.</label>
                <label>You can choose one of the emails and link it to the email from the "SSO email" column: <b>{{rowData.sso_email}}</b></label>
                <br>
                <p-dropdown [options]="usersSelectItems"  
                  appendTo="body"
                  [(ngModel)]="userId">
                </p-dropdown>
                <button mat-raised-button color="primary" type="button" 
                (click)="linkCorporateUserToSSOEmail(rowData)">
                  Link
                </button>
              </div>
            </td>
            <td>
              <span class="ui-column-title">{{ 'Date created (GMT)' }}</span>
              {{ rowData.date_created }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Message' }}</span>
              {{ rowData.text }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'SSO email' }}</span>
              {{ rowData.sso_email }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Same as VTS email?' }}</span>
              {{checkSSOEmailSameAsAppEmail(rowData.sso_email, rowData.signup_email)}}
            </td>
            <td>
              <span *ngIf="rowData.has_password">Yes</span>
              <span *ngIf="!rowData.has_password">No</span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <ul>
                <li *ngFor="let l of selectedSSOLogs" style="text-align: left">
                    Date {{l.date_created}} - SSO email: {{l.sso_email}}
                </li>
            </ul>
            <button *ngIf="selectedSSOLogs && selectedSSOLogs.length > 0" 
              (click)="bulkDeleteselectedSSOLogs(selectedSSOLogs)">Delete selected logs</button>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>


<p-dialog header="Has password info" [(visible)]="showHasPasswordInfoDialog" [style]="{width: '50vw'}">
  <p>
    In almost all cases the value must be 'No'. 
    Possible reason for showing 'Yes', could be if the user is using an other device where the previous successful 
    SSO login authorization is not saved. 
    Other reason could be stolen SSO credentials, when the attacker uses same email as the SSO email, 
    while the email is different for the app.
    The password is autogenerated password and is used only on VTS side. It doesn't have connection with the password used on SSO.
  </p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="showHasPasswordInfoDialog=false" 
      label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
