<div class="col-md-10 col-md-offset-1">
  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncAlerts | async" [paginator]="true" [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
        paginatorPosition="both" [rows]="25" autoLayout="true" [lazy]="true" [totalRecords]="count" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        [(selection)]="selectedAlerts" dataKey="id"
        [responsive]="true">

        <ng-template pTemplate="caption">
          <button mat-icon-button (click)="downloadAlertsCSV()"
            pTooltip="Download alerts CSV" >
            <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
          </button>
          <button mat-icon-button (click)="downloadAlertsPdf()"
            pTooltip="Download alerts PDF" >
            <fa-icon [icon]="faFilePdf" style="font-size: 40px;"></fa-icon>
          </button>
        </ng-template>
        
        <ng-template pTemplate="header">
          <tr>
            <th>
              <p-tableHeaderCheckbox style="float:left"></p-tableHeaderCheckbox>
              {{ 'Actions' }}
            </th>
            <th [pSortableColumn]="'time'">{{ 'Date created (GMT))'}}<p-sortIcon [field]="'time'"></p-sortIcon></th>
            <th>{{ 'Type'}}</th>
            <th>{{ 'Title'}}</th>
            <th>{{ 'Content'}}</th>
            <th>{{ 'Sender'}}</th>
            <th>{{ 'Info'}}</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>
              <span (click)="$event.stopPropagation()">
                <p-dropdown appendTo="body" id="alertTypesDropdown"
                  [options]="alertTypesSelectItems" 
                  (onChange)="dt.filter($event.value, 'type', 'equals')">
                </p-dropdown>
              </span>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td>
              <span class="ui-column-title">{{ 'Actions' }}</span>
              <p-tableCheckbox [disabled]="rowData.deleted"
                [value]="rowData">
              </p-tableCheckbox>
              &nbsp;
              <button mat-icon-button pTooltip="Delete alert" 
                  (click)="removeItem(rowData)"
                  *ngIf="!rowData.deleted">
                  <i class="fa fa-trash" style="font-size: 24px;"></i>
              </button>
              <i *ngIf="rowData.deleted"
                pTooltip="This alert is deleted" 
                class="fa fa-close" style="font-size: 24px; color:red">
              </i>
              &nbsp;
              <button mat-icon-button 
                *ngIf="!rowData.is_hidden && !rowData.deleted"
                pTooltip="This action will hide the alert on mobile devices" 
                (click)="hideAlert(rowData)">
                  <i class="fa fa-eye"  style="font-size: 24px;"></i>
              </button>
              <button mat-icon-button 
                *ngIf="rowData.is_hidden && !rowData.deleted"
                pTooltip="This action will show the alert on mobile devices" 
                (click)="showAlert(rowData)">
                  <i class="fa fa-eye-slash" style="font-size: 24px;"></i>
              </button>
              <button 
                mat-icon-button pTooltip="Edit alert" (click)="editItem(rowData)">
                  <i class="fa fa-edit" ></i>
              </button>
            </td>
            <td>
              <span class="ui-column-title">{{ 'Date created (GMT)' }}</span>
              {{ rowData.date_created }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Type' }}</span>
              {{ rowData.type ? ('ALERT_TYPE.' + rowData.type | translate) : '' }}
              <span *ngIf="rowData.type == 'LOCATION_SWITCH'" >
                (
                  Previous country: {{rowData.last_location_country_name}} 
                - New country: {{rowData.new_location_country_name}}
                )
              </span>
            </td>
            <td>
              <span class="ui-column-title">{{ 'Title' }}</span>
              {{ rowData.title }}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Content' }}</span>
              <span [innerHTML]="rowData.content"></span>
            </td>
            <td>
              <span class="ui-column-title">{{ 'Sender' }}</span>
              {{ rowData.sender?.firstname }} {{rowData.sender?.lastname}}
            </td>
            <td>
              <span class="ui-column-title">{{ 'Info' }}</span>
              <button mat-icon-button pTooltip="Show info" 
                (click)="showInfo(rowData)">
                <i class="fa fa-info-circle" style="font-size: 24px;"></i>
              </button>
              <button mat-icon-button pTooltip="Show alerted users" 
                (click)="showAlertedUsersInfo(rowData)">
                <i class="fa fa-users" style="font-size: 24px;"></i>
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <ul>
                <li *ngFor="let a of selectedAlerts" style="text-align: left">
                    ID{{a.id + ' - ' + a.date_created + ((a.type) ? (' - ' + ('ALERT_TYPE.' + a.type | translate)):'')  +  ((a.title) ? (' - ' + a.title) : '')}}
                </li>
            </ul>
            <button *ngIf="selectedAlerts && selectedAlerts.length > 0" 
              (click)="bulkDeleteAlerts(selectedAlerts)" >Delete selected alerts</button>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>


<p-dialog header="Alert info" [(visible)]="displayDialog" >

  <div [ngSwitch]="true">
    <div *ngSwitchCase="((selectedAlert && selectedAlert.alerted_divisions) ? true: false)">
      <h3>List of alerted divisions</h3>
      <ul>
        <li *ngFor="let division of selectedAlert.alerted_divisions">
          {{division.name_en}}
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="((selectedAlert && selectedAlert.alerted_mobileusers) ? true: false)">
      <h3>List of alerted users</h3>
      <ul>
        <li *ngFor="let mobileuser of selectedAlert.alerted_mobileusers">
          {{mobileuser.firstname}} {{mobileuser.lastname}}
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="((selectedAlert && selectedAlert.alerted_subsciption_countries) ? true: false)">
      <h3>List of alerted subscription countries</h3>
      <ul>
        <li *ngFor="let country of selectedAlert.alerted_subsciption_countries">
          {{country.name}}
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="((selectedAlert && selectedAlert.alerted_country_localizations) ? true: false)">
      <h3>List of alerted country localization</h3>
      <ul>
        <li *ngFor="let country of selectedAlert.alerted_country_localizations">
          {{country.name}}
        </li>
      </ul>
    </div>
    <div *ngSwitchDefault>
      <h3>No additional info found for that alert.</h3>
      <div *ngIf="selectedAlert && selectedAlert.type == 'TEST'">There is not info for this TEST alert</div>
    </div>
  </div>
</p-dialog>

<p-dialog header="Push notificated users" 
  [contentStyle]="{'max-height':'50vh'}"
  [(visible)]="displayOnlyAlertedUsersDialog" >
    <div>
      <h3>List of notified users</h3>
      <ul *ngIf="alertedUsers">
        <li *ngFor="let c of alertedUsers">
          {{c.firstname}} {{c.lastname}} {{c.email}}
        </li>
      </ul>
      <div *ngIf="!alertedUsers">
        No users are alerted.
      </div>
    </div>
</p-dialog>
