import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VigiRegion } from '../models/vigiregion';
import { Domain } from '../models/domain';

@Injectable({providedIn: 'root'})
export class DomainService {

    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    extractDomainsFromXLSX(formData: FormData) {
        console.log(formData);
        return this.httpClient.post(
            this.config.apiUrl + '/domains/extract_from_xlsx', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    }

    getAllEmailDomains() {
        return this.httpClient.get<any>(this.config.apiUrl + '/domains/all');
    }

    // Get by ID
    getDomainById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/domains/' + _id);
    }

    // DELETE
    deleteDomain(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/domains/' + _id);
    }

    // UPDATE
    updateDomain(domain: Domain) {
        return this.httpClient.put(this.config.apiUrl + '/domains/' + domain.id, domain);
    }
    // CREATE
    createDomain(domain: Domain) {
        return this.httpClient.post(this.config.apiUrl + '/domains', domain);
    }

}
