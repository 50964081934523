export class Airport {
    id: string;
    country_id: number;
    name: string;
    city_name: string;
    country: string;
    country_code: string;
    latitude: number;
    longitude: number;
    timezone: string;
    picture_url: string;  // this field is for the avatar/image of the airport
    photo_updated: number;
    is_custom_created: boolean;
}
