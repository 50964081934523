import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../_services/auth.service';
import { GrowlService } from '../_module_dialog/growl_dialog/growl.service';
import { AppConfig } from '../app.config';
import { BackendUser } from '../models/backend-user';
import { BackendUserService } from '../models_services/backend_user.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css'],
})
/** The login component uses the authentication service to login.
 *  It automatically logs the user out when it initializes (ngOnInit) so
 * !!! the login page can also be used to logout. */
export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthService,
        private growlService: GrowlService,
        private config: AppConfig,
        private backendUserService: BackendUserService) { }

    ngOnInit() {
        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    login() {
        this.loading = true;
        this.authenticationService.login(this.model.email, this.model.password)
            .subscribe(
            data => {
                this.loading = false;
                console.log('Login data: ' + data);
                console.log('Return url: ' + this.returnUrl);
                if (!data.user.confirmed) {
                    this.backendUserService.sendResetPasswordEmail(data.user.email).subscribe(
                        (data) => {
                            this.growlService.showWarnText(
                                'Email with reset password link has been sent to you, because you tried to log for first time'
                                ,'Warning',true)
                            this.authenticationService.logout();
                            console.log(data);
                        },
                        error_data => console.log(error_data)
                    );
                }
                else {
                    this.router.navigate([this.returnUrl]);
                }
            },
            error_data => {
                console.log('Login error');
                console.log(error_data.error);
                if (error_data.error.msg_const) { 
                    this.growlService.showError(error_data.error.msg_const);
                } else { 
                    this.growlService.showError('SERVER_CONNECTION_ERROR'); 
                }
                this.loading = false;
            });
    }

    recoverPassword() {
        this.authenticationService.getRecoverPasswordPath().subscribe(
            data => {
                window.location.href = this.config.siteUrl + data.path;
            },
            error => {
                if (error.msg_const) { this.growlService.showError(error.msg_const); }
            }
        );
    }

}
