import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var BPIService = /** @class */ (function () {
    function BPIService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    BPIService.prototype.getBasicPobInfoForCountry = function (countryName) {
        return this.httpClient.post(this.config.apiUrl + '/bpi/for_country', { 'country_name': countryName });
    };
    BPIService.prototype.getBasicPobInfoForAllUsers = function (userType) {
        return this.httpClient.post(this.config.apiUrl + '/bpi/all_users', {
            'user_type': userType
        });
    };
    BPIService.prototype.getBasicPobInfoForAllUsersWithTimeFilter = function (userType, startTime, endTime, limit, timeSortOrder) {
        var maxOverTime = true;
        if (startTime || endTime) {
            maxOverTime = false;
        }
        return this.httpClient.post(this.config.apiUrl + '/bpi/all_users/time_filter', {
            'start_time': startTime,
            'end_time': endTime,
            'limit': limit,
            'time_sort_order': timeSortOrder,
            'max_over_time': maxOverTime,
            'user_type': userType
        });
    };
    BPIService.prototype.getBasicPobLocations = function (backendUserId, userType, startTime, endTime, limit, timeSortOrder) {
        return this.httpClient.post(this.config.apiUrl + '/bpi/locations', {
            'pob_id': backendUserId,
            'start_time': startTime,
            'end_time': endTime,
            'limit': limit,
            'time_sort_order': timeSortOrder,
            'user_type': userType,
        });
    };
    BPIService.prototype.getBasicPobLocationsWithMaxOverLocationTime = function (backendUserId, userType, startTime, endTime, limit, timeSortOrder, maxOverTime) {
        return this.httpClient.post(this.config.apiUrl + '/bpi/user/time_filter', {
            'pob_id': backendUserId,
            'start_time': startTime,
            'end_time': endTime,
            'limit': limit,
            'time_sort_order': timeSortOrder,
            'max_over_time': maxOverTime,
            'user_type': userType
        });
    };
    BPIService.prototype.getBasicPobPanicLocationsWithMaxOverLocationTime = function (userType, startTime, endTime, limit, timeSortOrder, maxOverTime, pobId, filterForPanicStatus) {
        if (pobId === void 0) { pobId = 0; }
        if (filterForPanicStatus === void 0) { filterForPanicStatus = true; }
        return this.httpClient.post(this.config.apiUrl + '/bpi/user/time_filter', {
            'start_time': startTime,
            'end_time': endTime,
            'limit': limit,
            'time_sort_order': timeSortOrder,
            'max_over_time': maxOverTime,
            'filter_for_panic_status': filterForPanicStatus,
            'user_type': userType,
            'pob_id': pobId
        });
    };
    BPIService.ngInjectableDef = i0.defineInjectable({ factory: function BPIService_Factory() { return new BPIService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: BPIService, providedIn: "root" });
    return BPIService;
}());
export { BPIService };
