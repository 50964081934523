<div fxLayoutAlign="center center" fxFlexFill>
<mat-card class="login-card">
    <mat-toolbar color='primary'>
        <img src="assets/img/logo/logo-vinci-transparent.png" style="margin-top: 8px"
            alt="Logo VINCI" width="332" height="80">
    </mat-toolbar>

    <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
        <mat-card-content>
            <div [ngClass]="{ 'has-error': f.submitted && !email.valid }" >
                <mat-form-field style="width: 100%" color='primary'>
                    <input matInput placeholder="Email" type="text" name="email"
                        [(ngModel)]="model.email" #email="ngModel" required />
                </mat-form-field>
                <div *ngIf="f.submitted && !email.valid" class="help-block">Email is required</div>
            </div>
            <div [ngClass]="{ 'has-error': f.submitted && !password.valid }" >
                <mat-form-field style="width: 100%">
                    <input matInput placeholder="Password" type="password" name="password" 
                        [(ngModel)]="model.password" #password="ngModel" required />
                </mat-form-field>
                <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
            </div>
        </mat-card-content>
        <div>
            <button mat-raised-button [disabled]="loading">Login</button>
            <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            <a (click)="recoverPassword()" mat-raised-button>Recover password</a>
        </div>
    </form>
</mat-card>
</div>
