<div fxLayout="column" fxFlexFill>
  <mat-toolbar color="primary" style="color:#666" class="toolbar" 
    [ngClass]="production?'production': 'staging'">
    <button type="button" mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <a class="navbar-brand" [routerLink]="['/']"><span class="hiddenOnSM">VINCI Travel Security</span></a>
    <!-- This fills the remaining space of the current row -->
    <button *ngIf="displayBackButton" mat-fab (click)="returnBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span class="fill-remaining-space" 
      style="display:block;margin:0 0 0 5%; text-align: center" id="page_header">
      {{header}} 
      <span *ngIf="storeService.email" style="color: green;">({{storeService.email}})</span>
    </span>
    <div class="nav navbar-nav navbar-right" style="vertical-align: top;">
      <button id="top_right_speed_dial"  mat-mini-fab (click)="logout()" [routerLink]="['/login']">
        <mat-icon>exit_to_app</mat-icon>
      </button>
      <div fxLayout="column" style="float: right; margin-right:84px;">
        <img src="assets/img/logo/logo-vinci-transparent.png" 
          style="margin-top: 8px" id="logo"
          alt="Logo VINCI" width="166" height="40">
        <div id="welcome_and_name">
          Hi {{current_user.firstname}} {{current_user.lastname}}!
        </div>
      </div>
    </div>
  </mat-toolbar>
  <mat-sidenav-container fxFlex>
    <!-- https://github.com/angular/flex-layout/wiki/MediaObserver -->
    <mat-sidenav #sidenav mode="side" align="start"  [opened]="!mediaObserver.isActive('xs')">
      <mat-nav-list>
        <ng-container *ngFor="let item of activeMenuItems; index as i" >
          <mat-list-item *ngIf="item.visible"
            routerLinkActive="active" 
            class="menuitem"
            (click)="clickMenuItem()"
          >
            <button mat-icon-button [routerLink]="item.link">
              <mat-icon *ngIf="item.svg" svgIcon="menu:{{item.svg}}" class="primary-color" ></mat-icon>
              <i *ngIf="!item.svg" class="{{item.fas_class}}" style="font-size: 24px;"></i>
            </button>
            <a *ngIf="!item.outlet_link" 
              mat-line 
              [routerLink]="item.link">
              {{item.name}}&nbsp;
            </a>
            <a *ngIf="item.outlet_link" 
              mat-line 
              [routerLink]="[item.link, { outlets: { primary: null,  vigi: ['vigi_country_descr'] } }]"
              >
              {{item.name}}&nbsp;
            </a>
          </mat-list-item>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>
    <!-- primary content -->
    <div fxLayout="column" fxFill>
      <app-alert></app-alert>
      <div fxFlex style="padding: 5px;">
        <router-outlet></router-outlet>   
      </div>
    </div>
  </mat-sidenav-container>
</div>
