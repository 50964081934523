import { GDomain } from './gdomain';
import { Pole } from './pole';

export class Group {
    id: number;
    poles: Pole[];
    name_en: string;
    // domain: string;
    gdomains: GDomain[];
    validation_email: string;

    sftp_date_updated: string;
    sftp_not_success_date: string;
    last_sftp_was_success: number;

    constructor(id: number) {
        this.id = id;
        this.gdomains = [];
    }

}
