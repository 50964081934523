import { Injectable, EventEmitter } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VigiCountry } from '../models/vigicountry';
import { VigiDescr } from '../models/vigidescr';

@Injectable({providedIn: 'root'})
export class VigiCountryService {
    importFactSheetsEventEmitter = new EventEmitter();
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    // getAllVigiCountries() {
    //     return this.httpClient.get<any>(this.config.apiUrl + '/vigicountries/all');
    // }

    getVigiCountriesForGroups(groups){
        return this.httpClient.post(this.config.apiUrl + '/vigicountries/get_by_groups', {'groups': groups});
    }

    // Get by ID
    getVigiCountryById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/vigicountries/' + _id);
    }

    getVigiCountryByCountryId(countryId: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/vigicountries/country/' + countryId);
    }

    // Fact Sheet descr methods
    saveDescrForCountry(factSheetDescription, selectedCountryId: number) {
        return this.httpClient.put(
            this.config.apiUrl + '/vigicountries/country/' + selectedCountryId + '/fact_sheet_descr', factSheetDescription);
    }
    getFactSheetDescrForSelectedCountryAndSheetType(selectedCountryId, selectedFactSheetTypeId) {
        return this.httpClient.get<any>(this.config.apiUrl + '/vigicountries/country/' + selectedCountryId + '/fact_sheet_type/' + selectedFactSheetTypeId);
    }
    // END Fact Sheet descr methods


    
    // DELETE
    deleteVigiCountry(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/vigicountries/' + _id);
    }

    // UPDATE
    updateVigiCountry(vc: VigiCountry) {
        return this.httpClient.put(this.config.apiUrl + '/vigicountries/' + vc.id, vc);
    }

    // CREATE
    createVigiCountry(vc: VigiCountry) {
        return this.httpClient.post(this.config.apiUrl + '/vigicountries', vc);
    }

    exportFactSheetsInXLSX(selectedCountryId: number) {
        return this.httpClient.get(
            this.config.apiUrl + '/vigicountries/' + selectedCountryId + '/export_fact_sheets_in_xlsx',  {
            headers: new HttpHeaders().set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    importFactSheetsInXLSX(formData: FormData, selectedCountryId: number) {
        console.log(formData);
        return this.httpClient.post(
            this.config.apiUrl + '/vigicountries/' + selectedCountryId + '/import_fact_sheets_from_xlsx', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    }

}
