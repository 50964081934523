import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MobileUser } from '../../models/mobile-user';
import { MobileUserService } from '../../models_services/mobile-users.service';
import { MatDialog, MatDialogRef, MatIconRegistry } from '@angular/material';
import { ConfirmDialogsService } from '../../_module_dialog/confirm_dialog/confirm-dialog.service';
import { Router } from '@angular/router';

import { AppConfig } from '../../app.config';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { LazyLoadEvent } from 'primeng/components/common/api';
import { openFileForDownload } from '../../utils/file';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { GroupService } from '../../models_services/group.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Group } from 'src/app/models/group';
import { Country } from 'src/app/models/country';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mobile-users',
  templateUrl: 'mobile-users.component.html',
  styleUrls: ['mobile-users.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MobileUsersComponent implements OnInit {
  faFileCsv = faFileCsv;

  production = true;
  current_user: MobileUser;
  selectedMobileUser: MobileUser;
  asyncUsers: Observable<MobileUser[]>;
  count: 0;
  currentPage = 1;
  loading: boolean;
  showCorporateUsersTable = true;
  displayInfoDialog: boolean = false;
  displayAlertedCountriesDialog: boolean = false;
  infoDialogStr = '';
  alertedCountries: Country[];

  // server side filter
  sortProperties: any[];
  sortBy: string;
  orderAsc: string;
  params = {
    page: 1, per_page: 10, order: '-date_created',
    'phone': { operator: '%', value: '' },
    'firstname': { operator: '%', value: '' },
    'lastname': { operator: '%', value: '' },
    'email': { operator: '%', value: '' },
    'showNotRegistered': false,
    'showDeleted': false,
    'showCorporateUsers': true,
    'group_id': { operator: '=', value: ''},
    'pole_id': { operator: '=', value: ''},
    'division_id': { operator: '=', value: ''},
  };
  selectedMobileUsers: MobileUser[] = [];

  groups: Group[];
  groupsSelectItems: SelectItem[] = [];
  polesSelectItems: SelectItem[] = [];
  divisionsSelectItems: SelectItem[] = [];
  groupIdGroupObjectMap: Map<number, Group>;

  // Dialogs
  showDeviceInfoDialog: boolean;

  constructor(private userService: MobileUserService,
    private config: AppConfig,
    private confirmDService: ConfirmDialogsService,
    private router: Router,
    private growlService: GrowlService,
    private groupService: GroupService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,) {
    this.current_user = JSON.parse(localStorage.getItem('current_user'));
    this.production = this.config.production;
    iconRegistry.addSvgIconInNamespace('actions', 'realtimemap',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/actions_svg/clock_poi.svg'));
  }

  ngOnInit() {
    this.sortProperties = [
      { field: 'phone', name: 'Phone' },
      { field: 'firstname', name: 'Given name' },
      { field: 'lastname', name: 'Surname' },
    ];
    this.sortBy = 'id';
    this.orderAsc = '';
    this.loadAllUsers();
    this.loadGroupsDropdown();
  }

  private loadAllUsers() {
    this.params.pole_id.value='';
    this.params.group_id.value='';
    this.params.division_id.value='';
    this.getPage(1);
  }

  private loadGroupsDropdown() {
    this.groupService.getAllAccessibleGroupsWithInner().subscribe(
      (data) => {
        this.groups = data.groups;
        this.groupsSelectItems = [];
        if (data.groups) {
          this.groupsSelectItems.push({ label: 'Select group...', value: null });
          this.groupIdGroupObjectMap = new Map<number, Group>();
          data.groups.forEach(group => {
            this.groupsSelectItems.push({ label: group.name_en, value: group.id });
            this.groupIdGroupObjectMap.set(group.id, group);
          });
        }
      }
    );
  }

  getPage(page: number) {
    this.params.page = page;
    this.asyncUsers = this.userService.getAllWithParams(this.params).pipe(
      tap((data: any) => {
        this.count = data.count;
        this.loading = false;
        this.currentPage = page;
      }),
      map(data => {
        this.reinitPoles();
        this.reinitDivisions();
        return data.users;
      })
    );
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    this.params.phone.value = event.filters['phone'] ? event.filters['phone'].value : '';
    this.params.firstname.value = event.filters['firstname'] ? event.filters['firstname'].value : '';
    this.params.lastname.value = event.filters['lastname'] ? event.filters['lastname'].value : '';
    this.params.email.value = event.filters['email'] ? event.filters['email'].value : '';
    this.params.group_id.value = event.filters['group_id'] ? event.filters['group_id'].value : '';
    this.params.pole_id.value = event.filters['pole_id'] ? event.filters['pole_id'].value : '';
    this.params.division_id.value = event.filters['division_id'] ? event.filters['division_id'].value : '';
    this.params.showDeleted = event.filters['showDeleted'] ? event.filters['showDeleted'].value : false;

    if (!this.params.group_id.value) {
      this.params.group_id.value = '';
      this.params.pole_id.value = '';
      this.params.division_id.value = '';
    } else {
      let groupIdInFilter = Number.parseInt(this.params.group_id.value);
      let poles = this.groupIdGroupObjectMap.get(groupIdInFilter).poles;
      if (!poles) {
        this.params.pole_id.value = '';
        this.params.division_id.value = '';
      }
    }

    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

  // CRUD methods
  removeItem(user: MobileUser) {
    // call confirmation dialog
    this.confirmDService.show('User delete', 'Would you like to delete this user ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('deleting user confirmed: ' + JSON.stringify(user));
          this.userService.delete(user.id).subscribe(
            () => {
              this.loadAllUsers();
              this.growlService.showInfo('User is deleted.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }
  
  addItem() {
    console.log('addItem');
    this.router.navigate(['/home', 'mobile_user', 'new']);
  }

  editItem(editedUser: MobileUser) {
    console.log('editItem: ' + editedUser.id + ' ' + JSON.stringify(editedUser));
    console.log(editedUser);
    this.router.navigate(['/home', 'mobile_user', editedUser.id, 'edit']);
  }

  downloadUsersCSV() {
    this.userService.downloadUsersCSV(
      this.showCorporateUsersTable, 
      this.params.showNotRegistered
    ).subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  bulkDeleteMobileUsers(selectedMobileUsers) {
    console.log("delete selected Mobile users");
    this.confirmDService.show('Delete selected users', 'Are you sure that you want to delete selected users ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('bulk deleting users confirmed');
          this.userService.deleteBulkOfUsers(selectedMobileUsers).subscribe(
            () => {
              this.loadAllUsers();
              this.growlService.showInfo('Users are deleted.');
              selectedMobileUsers.splice(0, selectedMobileUsers.length);
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }

  navigateMobileUserTravels(user: MobileUser) {
    this.router.navigate(['/home', 'mobile_user', user.id, 'trips']);
  }

  navigateMobileUserLocations(user: MobileUser) {
    this.router.navigate(['/home', 'mobile_user', user.id, 'locations']);
  }

  showDeviceInfo(user: MobileUser) {
    this.selectedMobileUser = user;
    this.showDeviceInfoDialog = true;
  }

  showUnblockUserDialog(user: MobileUser) {
    this.confirmDService.show('Unblock user', 'Are you sure that you want to unblock this user? After this action he can use again his credentials.')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('unblock user');
          this.userService.unblockUser(user.id).subscribe(
            () => {
              // this.loadAllUsers();
              user.blocked = false;
              this.growlService.showInfo('Users is unblocked successfully.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }

  showBlockUserDialog(user: MobileUser) {
    this.confirmDService.show('Block user', 'Are you sure that you want to block this user? After this action he won\'t be able to access his mobile app')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('unblock user');
          this.userService.blockUser(user.id).subscribe(
            () => {
              // this.loadAllUsers();
              user.blocked = true;
              this.growlService.showInfo('Users is blocked successfully.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }

  showCorporateUsers() {
    if (!this.showCorporateUsersTable) {
      this.showCorporateUsersTable = true;
      // fetch the corporate users
      this.params.showCorporateUsers = this.showCorporateUsersTable;
      this.loadAllUsers();
    }
  }

  showSponsoredUsers() {
    if (this.showCorporateUsersTable) {
      this.showCorporateUsersTable = false;
      // fetch the corporate users
      this.params.showCorporateUsers = this.showCorporateUsersTable;
      this.loadAllUsers();
    }
  }

  // ================
  showIncompleteRegs(event) {
    if (event) {
      this.params.showNotRegistered = true;
      this.params.showDeleted = false;
    } else {
      this.params.showNotRegistered = false;
    }
    this.loadAllUsers();
  }

  showDeletedRegs(event) {
    if (event) {
      this.params.showNotRegistered = false;
      this.params.showDeleted = true;
    } else {
      this.params.showDeleted = false;
    }
    this.loadAllUsers();
  }
  
  showEmailConfirmationDialog(user: MobileUser) {
    this.confirmDService.show('Confirm user email', 'Are you sure that you want to confirm user email? ')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('unblock user');
          this.userService.confirmUserEmail(user.id).subscribe(
            () => {
              user.status = user.status.replace('e', 'E');
              this.growlService.showInfo('Email is confirmed successfully.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }

  showSponsorConfirmationDialog(user: MobileUser) {
    this.confirmDService.show('Confirm Sponsored User', 
    'Are you sure that you want to confirm this sponsored user?')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('unblock user');
          this.userService.confirmUserSponsor(user.id).subscribe(
            () => {
              user.status = user.status.replace('s', 'S');
              this.growlService.showInfo('Sponsored user is confirmed successfully.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }

  showIncompleteRegInfo(user: MobileUser) {
    this.infoDialogStr = '';
    if (user.status.indexOf('e') > -1) {
      this.infoDialogStr = this.infoDialogStr + 'This user has not confirmed his email address. \n';
    }
    if (user.status.indexOf('s') > -1) {
      this.infoDialogStr = this.infoDialogStr + 'The registration has not been confirmed through the link sent to the Pole Validation email. \n';
    }
    if (user.status.indexOf('p') > -1) {
      this.infoDialogStr = this.infoDialogStr + 'Phone number is not confirmed. \n';
    }
    if (user.status.indexOf('d') > -1) {
      this.infoDialogStr = this.infoDialogStr + 'User has not been authorized succesfully with SSO. \n';
    }
    if (user.status.length < 3) {
      this.infoDialogStr = this.infoDialogStr + 'This user has not completed all steps from the registration. \n';
      if (user.status.indexOf('p') < 0  && user.status.indexOf('P') < 0) {
        this.infoDialogStr = this.infoDialogStr + 'No phone number provided. \n';
      }
      if (user.status.indexOf('e') < 0  && user.status.indexOf('E') < 0) {
        this.infoDialogStr = this.infoDialogStr + 'No email provided. \n';
      }
      if (user.status.indexOf('s') < 0  && user.status.indexOf('S') < 0 && user.status.indexOf('d') < 0  && user.status.indexOf('D') < 0) {
        this.infoDialogStr = this.infoDialogStr + 'No division or sponsor provided. \n';
      }
    }
    this.showInfoDialog();
  }

  showInfoDialog() {
    this.displayInfoDialog = true;
  }

  showCountrySubscriptionInfo(user: MobileUser) {
    this.selectedMobileUser = user;
    this.userService.getAlertedCountries(user.id).subscribe(
      (data: any) => {
        this.alertedCountries = data.countries;
        this.displayAlertedCountriesDialog = true;
      },
      (error_data: any) => { 
        this.growlService.showError(error_data.error.msg_const); 
      }
    );
  }

  downloadUserInfo(user: MobileUser) {
    this.userService.downloadUserInfo(
      user.id
    ).subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  showRealTimeMap(user: MobileUser) {
    console.log("Show real time map");

    this.router.navigate(['/home', 'mobile_user', user.id, 'time_map']);
  }

  showGlobalMap() {
    console.log('Show global map');
    this.router.navigate(['/home', 'map_all']);
  }

  onPhoneVerifyChange(user: MobileUser, event){
    let question = null;
    let successMessage = null;
    let status = null;
    if(event.source.checked) {
      question = 'Are you sure that you want to validate user\'s phone?'
      status = 'P';
      successMessage = 'Phone validation is completed.';
    } else {
      question = 'Are you sure that you want to remove user\'s phone validation?'
      status = 'p';
      successMessage = 'Phone validation is removed.';
    }
    this.confirmDService.show('Phone validation', question).subscribe(res => {
        const result = res;
        if (res) {
          this.userService.changeStatus(status, user.id).subscribe(
            (data) => {this.growlService.showInfo(successMessage);},
            (error_data: any) => { 
              event.source.checked = !event.source.checked;
              this.growlService.showError(error_data.error.msg_const); 
            }
          );
        } else {
          event.source.checked = !event.source.checked;
        }
      });
  }

  onEmailVerifyChange(user: MobileUser, event){
    let question = null;
    let successMessage = null;
    let status = null;
    if(event.source.checked) {
      question = 'Are you sure that you want to validate user\'s email?'
      status = 'E';
      successMessage = 'Email validation is completed.';
    } else {
      question = 'Are you sure that you want to remove user\'s email validation?'
      status = 'e';
      successMessage = 'Email validation is removed.';
    }
    this.confirmDService.show('Email validation', question).subscribe(res => {
        const result = res;
        if (res) {
          this.userService.changeStatus(status, user.id).subscribe(
            (data) => {
              this.growlService.showInfo(successMessage);
            },
            (error_data: any) => { 
              event.source.checked = !event.source.checked;
              this.growlService.showError(error_data.error.msg_const); 
            }
          );
        } else {
          event.source.checked = !event.source.checked;
        }
      });
  }

  onSponsorDevisionVerifyChange(user: MobileUser, event) {
    let question = null;
    let successMessage = null;
    let status = null;
    let title = null;
    if(event.source.checked) {
      if (user.is_sponsored) {
        title = 'Sponsor validation';
        question = 'Are you sure that you want to validate user\'s sponsor?'
        status = 'S';
        successMessage = 'Sponsor validation is completed.';
      } else {
        title = 'SSO validation';
        question = 'Are you sure that you want to validate user\'s SSO?'
        status = 'D';
        successMessage = 'SSO validation is completed.';
      }
    } else {
      if (user.is_sponsored) {
        title = 'Sponsor validation';
        question = 'Are you sure that you want to remove sponsor validation?'
        status = 's';
        successMessage = 'Sponsor validation is completed.';
      } else {
        title = 'SSO validation';
        question = 'Are you sure that you want to remove SSO validation?'
        status = 'd';
        successMessage = 'SSO validation is removed.';
      }
    }
    this.confirmDService.show(title, question).subscribe(res => {
        const result = res;
        if (res) {
          this.userService.changeStatus(status, user.id).subscribe(
            (data) => {this.growlService.showInfo(successMessage);},
            (error_data: any) => { 
              event.source.checked = !event.source.checked;
              this.growlService.showError(error_data.error.msg_const); 
            }
          );
        } else {
          event.source.checked = !event.source.checked;
        }
      });
  }



  // ======================================= FILTERS ========================

  // Pole
  private reinitPoles() {
    let groupIdInFilter = 0;
    if (this.params.group_id.value) {
      groupIdInFilter = Number.parseInt(this.params.group_id.value);
    }
    if (groupIdInFilter != 0) {
      this.polesSelectItems = [{label:'All', value: null}];
      if (this.groupIdGroupObjectMap.get(groupIdInFilter).poles) {
        this.groupIdGroupObjectMap.get(groupIdInFilter).poles.forEach(element => {
          this.polesSelectItems.push({label: element.name_en, value: element.id});
        });
      }
    }
    else {
      this.polesSelectItems = [{label:'Select group first', value: null}];
    }
  }

  getPoleItems(groupId: number) {
    let poleSelectItems: SelectItem[] = [];
    if (groupId != 0 && this.groupIdGroupObjectMap.size > 0) {
      poleSelectItems = [{label:'...', value: null}];
      if (this.groupIdGroupObjectMap.get(groupId).poles) {
        this.groupIdGroupObjectMap.get(groupId).poles.forEach(element => {
          poleSelectItems.push({label: element.name_en, value: element.id});
        });
      }
    }
    return poleSelectItems;
  }

  getPoleValue(groupId : number, poleId: number) {
    let poleValue = '';
    if (groupId && poleId && this.groupIdGroupObjectMap && 
        this.groupIdGroupObjectMap.size > 0) {
      let groupPoles = this.groupIdGroupObjectMap.get(groupId).poles;
      if (groupPoles && groupPoles.length > 0) {
        groupPoles.forEach(element => {
          if (element.id === poleId) {
            poleValue = element.name_en;
          }
        });
      }
    }
    return poleValue;
  }

  // Division =====================================
  private reinitDivisions() {
    let groupIdInFilter = 0;
    let poleIdInFilter = 0;
    if (this.params.group_id.value) {
      groupIdInFilter = Number.parseInt(this.params.group_id.value);
    }
    if (this.params.pole_id.value) {
      poleIdInFilter = Number.parseInt(this.params.pole_id.value);
    }
    if (groupIdInFilter != 0) {
      if (poleIdInFilter != 0) {
        this.divisionsSelectItems = [{label:'All', value: null}];
        if (this.groupIdGroupObjectMap.get(groupIdInFilter).poles) {
          this.groupIdGroupObjectMap.get(groupIdInFilter).poles.forEach(g => {
            if (g.id === poleIdInFilter) {
              if (g.divisions) {
                g.divisions.forEach(t => {
                  this.divisionsSelectItems.push({label: t.name_en, value: t.id});
                });
              }
            }
          });
        }
      } else {
        this.divisionsSelectItems = [{label:'Select pole first', value: null}];
      }
    }
    else {
      this.divisionsSelectItems = [{label:'Select group first', value: null}];
    }
  }

  getDivisionValue(groupId: number, poleId: number, divisionId: number) {
    let divisionValue = '';
    if (groupId && poleId && divisionId && 
      this.groupIdGroupObjectMap && 
      this.groupIdGroupObjectMap.size > 0) {
      let groupPoles = this.groupIdGroupObjectMap.get(groupId).poles;
      if (groupPoles && groupPoles.length > 0) {
        groupPoles.forEach(g => {
          if (g.id === poleId) {
            if (g.divisions) {
              g.divisions.forEach(t => {
                if (t.id === divisionId) {
                  divisionValue = t.name_en;
                }
              });
            }
          }
        });
      }
    }
    return divisionValue;
  }

  getDivisionItems(groupId: number, poleId: number) {
    let divisionsSelectItems: SelectItem[] = [];
    if (groupId && poleId 
      && this.groupIdGroupObjectMap 
      && this.groupIdGroupObjectMap.size > 0) {
      divisionsSelectItems = [{label:'...', value: null}];
      if (this.groupIdGroupObjectMap.get(groupId).poles) {
        this.groupIdGroupObjectMap.get(groupId).poles.forEach(g => {
          if (g.id === poleId) {
            if (g.divisions) {
              g.divisions.forEach(t => {
                divisionsSelectItems.push({label: t.name_en, value: t.id});
              });
            }
          }
        });
      }
    }
    return divisionsSelectItems;
  }
  // Division END =====================================
}
