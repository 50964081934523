import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogsService } from 'src/app/_module_dialog/confirm_dialog/confirm-dialog.service';
import { GrowlService } from 'src/app/_module_dialog/growl_dialog/growl.service';
import { ApplicationService } from 'src/app/models_services/application.service';

@Component({
  selector: 'app-escrow-time',
  templateUrl: './escrow-time.component.html',
  styleUrls: ['./escrow-time.component.css']
})
export class EscrowTimeGeolocationComponent implements OnInit {
  locForm: FormGroup;
  traForm: FormGroup;
  alogForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private applicationService: ApplicationService,
    private growlService: GrowlService, 
    private confirmDialogsService: ConfirmDialogsService
  ) {
    this.locForm = this.fb.group({
      locationExpirityDays: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
    });
    this.traForm = this.fb.group({
      travelExpirityDays: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
    });
    this.alogForm = this.fb.group({
      adminLogExpirityDays: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
    });
  }
  

  ngOnInit() {
    this.applicationService.getLocationsExpirityTime().subscribe(
      (data: any) => {
        this.locForm.patchValue({
          locationExpirityDays: data.location_expirity_days
        });
      }
    );
    this.applicationService.getTravelsExpirityTime().subscribe(
      (data: any) => {
        this.traForm.patchValue({
          travelExpirityDays: data.travel_expirity_days
        });
      }
    );
    this.applicationService.getAdminLogsExpirityTime().subscribe(
      (data: any) => {
        this.alogForm.patchValue({
          adminLogExpirityDays: data.admin_logs_expirity_days
        });
      }
    );
  }

  onLocExpirySubmit() {
    if (this.locForm.valid) {
      console.log('Form submitted successfully!');
      console.log('Location Expiry days:', this.locForm.value.locationExpirityDays);
      // show confirmation pop up
      this.confirmDialogsService.show(
        'Location expiration change', 
        'Locations will be deleted from the Platform after the ' + this.locForm.value.locationExpirityDays + ' day(s) delay has expired. Do you confirm?',
        "Yes", "No"
      )
      .subscribe(res => {
        if (res) {
          this.updateLocationsExpirityTime();
        }
      });
    }
  }

  updateLocationsExpirityTime() {
    this.applicationService.updateLocationsExpirityTime(
      this.locForm.value.locationExpirityDays
    ).subscribe(
      (data: any) => {
        this.growlService.showInfoText('Expiration delay changed');
      }
    );
  }

  // Travel escrow
  onTraExpirySubmit() {
    if (this.traForm.valid) {
      console.log('Form submitted successfully!');
      console.log('Travel Expiry days:', this.traForm.value.travelExpirityDays);
      // show confirmation pop up
      this.confirmDialogsService.show(
        'Travel expiration change', 
        'Travels will be deleted from the Platform after the ' + this.traForm.value.travelExpirityDays + ' day(s) delay has expired. Do you confirm?',
        "Yes", "No"
      )
      .subscribe(res => {
        if (res) {
          this.updateTravelsExpirityTime();
        }
      });
    }
  }

  updateTravelsExpirityTime() {
    this.applicationService.updateTravelsExpirityTime(
      this.traForm.value.travelExpirityDays
    ).subscribe(
      (data: any) => {
        this.growlService.showInfoText('Expiration delay changed');
      }
    );
  }

  // Admin Logs
  onAdminLogsExpirySubmit() {
    if (this.alogForm.valid) {
      console.log('Form submitted successfully!');
      console.log('Admin logs expiry days:', this.alogForm.value.adminLogExpirityDays);
      // show confirmation pop up
      this.confirmDialogsService.show(
        'Admin logs expiration change', 
        'Admin logs will be deleted from the Platform after the ' + this.alogForm.value.adminLogExpirityDays + ' day(s) delay has expired. Do you confirm?',
        "Yes", "No"
      )
      .subscribe(res => {
        if (res) {
          this.updateAdminLogsExpirityTime();
        }
      });
    }
  }

  updateAdminLogsExpirityTime() {
    this.applicationService.updateAdminLogsExpirityTime(
      this.alogForm.value.adminLogExpirityDays
    ).subscribe(
      (data: any) => {
        this.growlService.showInfoText('Expiration delay changed');
      }
    );
  }
}
