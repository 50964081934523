import { Observable } from 'rxjs/Observable';
import { InputConfirmDialogComponent } from './input-confirm-dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { Injectable } from '@angular/core';

@Injectable()
/**
 * The service will act as a wrapper for MdDialog to
 * simplify the process of calling the dialog and subscribing to it.
 */
export class InputConfirmDialogsService {

    constructor(private dialog: MatDialog) { }

    public show(title: string, message: string): MatDialogRef<InputConfirmDialogComponent> {
        let dialogRef: MatDialogRef<InputConfirmDialogComponent>;
        dialogRef = this.dialog.open(InputConfirmDialogComponent, {
            width: '400px',
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        return dialogRef; // emits event when the dialog is closed
    }
}
