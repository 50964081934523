import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../models_services/application.service';
import { AppText } from '../../models/app-text';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'app-backendsettigns',
  templateUrl: './backend_settings.component.html',
  styleUrls: ['./backend_settings.component.css']
})
export class BackendSettingsComponent implements OnInit {
  oldPassword: string;
  oldSmtpPassword: string = '';
  smtpPassword: string = '';
  smtpEmail: string = '';
  tabIndex = 0;

  constructor(
    private applicationService: ApplicationService,
    private growlService: GrowlService, 
    public appConfig: AppConfig
  ) { }

  ngOnInit() {
    this.applicationService.getSmtpSettings().subscribe(
      (data: any) => {
        this.oldPassword = data.password;
        this.smtpEmail = data.email;
      }
    );
  }

  onTabChange(event) {
    this.tabIndex = event.index;
  }

  updateEmailPassword(){
    if(this.oldPassword != this.oldSmtpPassword){
      this.growlService.showErrorText("Old password doesn't match!");
    } else {
      this.applicationService.updateEmailPassword(this.smtpEmail, this.smtpPassword).subscribe(
        (data)=> {
          this.growlService.showInfoText('SMTP preferences is updated');
        }
      );
    }
  }

}
