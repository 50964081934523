// Some code
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ApplicationState {
    pageTitle: string;
    authToken: string;
    email: string;
    password: string;
    permissions: string[] | any;
    stateId: number;
    howAreYouPobIds: any[];
}

const initialState: ApplicationState = {
    pageTitle: 'KWIK Shelf ',
    authToken: null,
    email: null,
    password: null,
    permissions: null,
    stateId: 0,
    howAreYouPobIds: []
};

/**
 * Any component or service that wants to subscribe to changes to, say, the pageTitle$ value can do so:
    this.storeService.pageTitle$.subscribe(title => { doSomethingWithPageTitle(title); 

    next function provides a COMPLETELY NEW STATE object to it. 
    This new object is based on the CURRENT state via the TypeScript/JavaScript spread operator, 
    but replaces the userProfile part of the state with the value passed in to the setUserProfile function. 
    This will then cause the userProfile$ observable from above to fire since this value has changed, 
    and any listeners to that Observable will be notified of the change.
 */
@Injectable({
    providedIn: 'root',
})
export class StoreService {
    private readonly store$ = new BehaviorSubject<ApplicationState>(initialState);

    readonly howAreYouPobIds$ = this.store$.pipe(map((state) => state.howAreYouPobIds));
    readonly pageTitle$ = this.store$.pipe(map((state) => state.pageTitle));
    readonly authToken$ = this.store$.pipe(map((state) => state.authToken));
    readonly email$ = this.store$.pipe(map((state) => state.email));
    readonly password$ = this.store$.pipe(map((state) => state.password));
    readonly permissions$ = this.store$.pipe(map((state) => state.permissions));
    readonly stateId$ = this.store$.pipe(map((state) => state.stateId));
    
    // ======================= Auth token
    setHowAreYouPobIds(howAreYouPobIds: any[]) {
        this.store$.next({ ...this.store$.value, howAreYouPobIds, });
    }
    get howAreYouPobIds(): any[] {
        return this.store$.value.howAreYouPobIds;
    }
    // ======================= Page title
    setPageTitle(pageTitle: string) {
        setTimeout(() => {
            this.store$.next({ ...this.store$.value, pageTitle, });
        }, 0);
    }
    // ======================= Auth token
    setAuthToken(authToken: string) {
        this.store$.next({ ...this.store$.value, authToken, });
    }
    get authToken(): string | undefined {
        return this.store$.value.authToken;
    }
    clearAuthToken() {
        this.store$.next({ ...this.store$.value, authToken: null, });
    }
    // ======================= Email
    setEmail(email: string) {
        this.store$.next({ ...this.store$.value, email, });
    }
    get email(): string | undefined {
        return this.store$.value.email;
    }
    // ======================= Password
    setPassword(password: string) {
        this.store$.next({ ...this.store$.value, password, });
    }
    get password(): string | undefined {
        return this.store$.value.password;
    }
    // ======================= Permissions
    setPermissions(permissions: any) {
        this.store$.next({ ...this.store$.value, permissions, });
    }
    get permissions(): any | undefined {
        return this.store$.value.permissions;
    }
    // ======================= User profile methods 
    // get userProfileId(): number | undefined {
    //     return this.store$.value.userProfile?.id;
    // }
    // setUserProfile(userProfile: BackendUser) {
    //     this.store$.next({ ...this.store$.value, userProfile, });
    // }
    // get userProfile(): BackendUser | undefined {
    //     return this.store$.value.userProfile;
    // }
    // clearUserProfile() {
    //     this.store$.next({ ...this.store$.value, userProfile: null, });
    // }
    // userAvatarUpdated() {
    //     this.store$.next({
    //         ...this.store$.value,
    //         userProfile: Object.assign(this.store$.value.userProfile, {
    //             avatarFileVersion: new Date().getTime().toString(),
    //         }),
    //     });
    // }
    // ======================= State (location state from address)
    setStateId(stateId: number) {
        setTimeout(() => {
            this.store$.next({ ...this.store$.value, stateId, });
        }, 0);
    }
    get stateId(): number | undefined {
        return this.store$.value.stateId;
    }
}
