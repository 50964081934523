import { Component, OnInit } from '@angular/core';
import { GrowlService } from './growl.service';
import { Growl } from './growl';
import { Message } from 'primeng/primeng';

@Component({
    selector: 'app-growl',
    templateUrl: 'growl.component.html'
})
// The growl component passes growl messages to the template whenever a message
// is received from the growl service. It does this by subscribing to the growl 
// service's getMessage() method which returns an Observable.
export class GrowlComponent implements OnInit {
    messages: Message[] = [];

    constructor(private growlService: GrowlService) { }

    ngOnInit() {
        this.growlService.getMessage().subscribe((message: Message) => {
            if (message) { 
                let time = 10000;
                if (message.severity === 'error') {
                    time = 35000;
                }
                this.messages.push(message);
                 var that = this;
                // remove the first element from the array
                setTimeout(function () { that.messages.shift(); }, time);
            }
        });
        for (let message of this.messages) {
            console.log(message);
        }
    }
}
