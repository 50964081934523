import { FormControl, AbstractControl } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';

export const PhoneWithPlusAndSpaceValidator = (): ValidatorFn => {
  return (divisionControl: AbstractControl): { [key: string]: boolean } => {
    console.log(divisionControl.value);
    if (divisionControl.value) {
      const pattern = /^([\+]?){1}([0-9]){1}([\-0-9 ]+)([0-9]){1}$/;
      if (divisionControl.value.match(pattern)) {
        return null;
      } else {
        return { nomatch: true };
      }
    }
  };

};
