import { Group } from './group';
import { Division } from './division';

export class Pole {
    id: number;
    divisions: Division[];
    group_id: number;
    group: Group;
    name_en: string;
    auto_validating_mobile_users: boolean;
    vinci_code: string;

    constructor(id: number) {
        this.id = id;
    }
}
