import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { BackendUser } from '../models/backend-user';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { MyPermissionsService } from '../_services/myx-permissions.service';
import 'rxjs/add/operator/filter';
import { MediaObserver} from '@angular/flex-layout';
import { AuthService } from '../_services/auth.service';
import { AppConfig } from '../app.config';
import { ApplicationService } from '../models_services/application.service';
import { GrowlService } from '../_module_dialog/growl_dialog/growl.service';
import { MobileUserService } from '../models_services/mobile-users.service';
import { StoreService } from '../common/application.state';

class MenuItem {
  name: string;
  link: string;
  header?: string;
  svg: any;
  outlet_link?: string;
  fas_class: string;
  subMenu?: MenuItem[];
  visible: boolean;
}

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css'],
})
export class HomeComponent implements OnInit {
  menu_items: MenuItem[] = [];
  activeMenuItems: MenuItem[] = [];
  activeUrl = '/home/mobile_users';
  firstLevelIsActive = true
  first_level_menu_items: MenuItem[] = [];
  second_level_risk_assessment: MenuItem[] = [];
  second_level_user_pages: MenuItem[] = [];
  id: number;
  // third_level_fact_sheets: MenuItem[] = [];
  production = true;

  header = '';  // this string is reflecting the page we are on, put in the top
  displayBackButton = false;

  current_user: BackendUser;
  spin = true;
  fixed = false;
  idIsSet = true;

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private _permissionService: MyPermissionsService,
    public mediaObserver: MediaObserver,
    private authService: AuthService,
    private appConfig: AppConfig,
    private applicationService: ApplicationService,
    private growlService: GrowlService,
    private mobileService: MobileUserService,
    public storeService: StoreService
  ) {
    this.getApplicationConfigName();
    this.current_user = JSON.parse(localStorage.getItem('current_user'));
    iconRegistry.addSvgIconInNamespace('menu', 'timemap',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/actions_svg/clock_poi.svg'));
    iconRegistry.addSvgIconInNamespace('menu', 'app_settings',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/menu_icons_svg/app_settings.svg'));
    iconRegistry.addSvgIconInNamespace('menu', 'console',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/menu_icons_svg/console.svg'));

    this.setMenu();
    // this.setHeader(this.router.url);

    this.router.events
      .filter(event => event instanceof NavigationStart)
      .subscribe((event: NavigationStart) => {
        console.log('NavigationStart: ' + event.url);
        if (event.url == '/move_up') {
          if(this.idIsSet) {
            this.idIsSet = false;
            this.storeService.setEmail(null);
            this.setMenu();
          } else {
            this.activeMenuItems = this.menu_items;
            this.firstLevelIsActive = true;
          }
          // basically this line stops the navigation to new route
          router.navigateByUrl(router.url, {  replaceUrl: true });
        } else{
          const smallScreen = window.innerWidth < 901 ? true : false;
          if (smallScreen) { this.sidenav.opened = false;}
          if (event) {
            this.activeUrl = event.url;
            // this.setHeader();
            // Routes with IDs
            if (event.url.indexOf('/home/mobile_user/') > -1 
              && (event.url.indexOf('/trips') > -1 
              || event.url.indexOf('/locations') > -1
              || event.url.indexOf('/time_map') > -1)) {
              let newId = parseInt(event.url.split('/')[3]);
              if (this.id !== newId) {
                this.mobileService.getById(newId).subscribe(data => {
                  if(data && data.user && data.user.email) {
                    this.storeService.setEmail(data.user.email);
                  }
                })
              }
              this.id = newId;
              this.idIsSet = true;
              // if (isNaN(this.id)) {
              //   // stop the navigation
              //   router.navigateByUrl(router.url, {  replaceUrl: true });
              //   // show message
              //   this.growlService.showInfoText("Please select mobile user first!");
              //   router.navigateByUrl('/home/mobile_users', {  replaceUrl: true });
              //   this.firstLevelIsActive = false;
              // }
            }

            this.setMenu();
            this.setMenuItems();
          }
        }
      });
  }

  getApplicationConfigName(){
    this.applicationService.getApplicationConfigName().subscribe(
      (data) => {
        if(data) {
          if(data.config_name == 'staging') {
            this.production = false;
          }
        }
      }
    );
  }

  ngOnInit() {
  }

  private setMenu() {
    if(isNaN(this.id)) {
      this.idIsSet = false;
    }
    this._permissionService.checkForPermissions(
      [
        'MENU_BACKENDUSERS', //0
        'MENU_MOBILEUSERS', //1
        'MENU_PUSH_NOTIFICATION', // 2
        'MENU_RISK_ASSESSMENT',  //3
        'MENU_APP_SETTINGS', //4
        'MENU_BACKEND_SETTINGS', //5
        'MENU_STATISTICS', // 6
        'MENU_SSO_LOGS', // 7
        'MENU_ADMIN_LOGS', //8
      ]).then(
      results => {
        this.menu_items = [];
        if (results[0]) { // "Backend users"
          this.menu_items.push({ 
            visible: true,
            name: 'Vinci', 
            header: 'Vinci', 
            link: '/home/backend_users', 
            svg: '', 
            fas_class: 'fa fa-users',
            subMenu: [
              { visible: true, name: '', link: '/move_up', svg: null, fas_class: 'fa fa-angle-up' },
              { header: 'Backend Users', visible: true, name: 'Backend Users', link: '/home/backend_users', svg: '', fas_class: 'fa fa-users' },
              { header: 'Entities', visible: true, name: 'Entities', link: '/home/properties', svg: '', fas_class: 'fa fa-table' },
            ]
          });
        }
        if (results[1]) { // "Mobile users"
          this.menu_items.push({
            visible: true, 
            name: 'Mobile users',
            header: 'Mobile users',
            link: '/home/mobile_users',
            svg: '',
            fas_class: 'fa fa-id-badge',
            subMenu: [
              { visible: true, name: '', link: '/move_up', svg: null, fas_class: 'fa fa-angle-up' },
              { visible: true, name: 'List', link: '/home/mobile_users', svg: null, fas_class: 'fa fa-id-badge' },

              { visible: !this.idIsSet, name: 'Users Locations', link: '/home/mobile_users_locations', svg: '', fas_class: 'fa fa-list-alt' },
              { visible: !this.idIsSet, name: 'Global Map', link: '/home/map_all', svg: '', fas_class: 'fa fa-map' },
              { visible: !this.idIsSet, name: 'Global Panic Map', link: '/home/mobile_panic_mode_map', svg: '', fas_class: 'fa fa-exclamation-triangle' },
              { visible: !this.idIsSet, name: 'Global “How are you?” Log', link: '/home/how_are_you_logs', svg: '', fas_class: 'fa fa-question' },
              { visible: !this.idIsSet, name: 'Global Travel list', link: '/home/mobile_users_trips', svg: '', fas_class: 'fa fa-plane' },

              { visible: this.idIsSet, name: 'User Locations', link: '/home/mobile_user/' + this.id + '/locations', svg: '', fas_class: 'fa fa-list-alt' },
              { visible: this.idIsSet, name: 'User Map', link: '/home/mobile_user/' + this.id + '/time_map', svg: '', fas_class: 'fa fa-map' },
              { visible: this.idIsSet, name: 'User Panic Map', link: '/home/mobile_user/' + this.id + '/mobile_panic_mode_map', svg: '', fas_class: 'fa fa-exclamation-triangle' },
              { visible: this.idIsSet, name: 'User “How are you?” Log', link: '/home/mobile_user/' + this.id + '/how_are_you_logs', svg: '', fas_class: 'fa fa-question' },
              { visible: this.idIsSet, name: 'User Travel list', link: '/home/mobile_user/' + this.id + '/trips', svg: '', fas_class: 'fa fa-plane' },
            ]
          });
        }
        if (results[2]) { // "Alerts"
          this.menu_items.push({
            visible: true, 
            name: 'Alerts',
            header: 'Alerts',
            link: '/home/push_notification',
            svg: '',
            fas_class: 'fa fa-paper-plane',
            subMenu: [
              { visible: true, name: '', link: '/move_up', svg: null, fas_class: 'fa fa-angle-up' },
              { visible: true, name: 'Send alerts', link: '/home/push_notification', svg: null, fas_class: 'fa fa-bell' },
              { visible: true, name: 'History', link: 'notifications', svg: '', fas_class: 'fa fa-list' },
              { visible: true, name: 'HAY Campains', link: 'haycampains', svg: '', fas_class: 'fa fa-list' },
            ]
          });
        }
        if (results[3]) {  // Risk assessment
          this.menu_items.push({ 
            visible: true, 
            name: 'Risk assessment', 
            header: 'Risk assessment', 
            link: '/home/risk_assessment/fact_sheets',
            svg: '',
            fas_class: 'fa fa-exclamation-triangle',
            subMenu: [
              { visible: true, name: '', link: '/move_up', svg: null, fas_class: 'fa fa-angle-up' },
              { header: 'VIGI Country', visible: true, name: 'VIGI Country', link: '/home/risk_assessment/vigi_country', outlet_link: 'vigi_country_descr', svg: '', fas_class: 'fa fa-globe' },
              { header: 'Risk Levels', visible: true, name: 'Risk Levels', link: '/home/risk_assessment/risk_settings', svg: '', fas_class: 'fa fa-bar-chart' },
              { header: 'Travel Tips', visible: true, name: 'Travel Tips', link: '/home/risk_assessment/tips', svg: '', fas_class: 'fa fa-tripadvisor' },
              { header: 'Fact Sheets', visible: true, name: 'Fact Sheets', link: '/home/risk_assessment/fact_sheets', svg: '', fas_class: 'fa fa-table' },
            ]
          });
        }
        if (results[4]) {  // App settings
          this.menu_items.push({ 
            visible: true, 
            name: 'App Settings', 
            header: 'App Settings',
            link: '/home/appsettings',
            svg: 'app_settings',
            fas_class: 'fa fa-cogs',
            subMenu: [
              { visible: true, name: '', link: '/move_up', svg: null, fas_class: 'fa fa-angle-up' },
              { header: 'FAQ', visible: true, name: 'FAQ', link: '/home/alerts/send', svg: '', fas_class: '' },
              { header: 'Support', visible: true, name: 'Support', link: '/home/alerts/how-are-you', svg: '', fas_class: '' },
              { header: 'Tutorial', visible: true, name: 'Tutorial', link: '/home/alerts/history', svg: '', fas_class: '' },
              { header: 'Use of personal data', visible: true, name: 'Use of personal data', link: '/home/alerts/history', svg: '', fas_class: '' },
              { header: 'Terms & Conditions', visible: true, name: 'Terms & Conditions', link: '/home/alerts/history', svg: '', fas_class: '' },
            ]
          });
        }
        if (results[5]) { // "Backend Settings"
          this.menu_items.push({
            visible: true, 
            name: 'Backend Settings',
            header: 'Backend Settings',
            link: '/home/airports',
            svg: '',
            fas_class: 'fa fa-cogs',
            subMenu: [
              { visible: true, name: '', link: '/move_up', svg: null, fas_class: 'fa fa-angle-up' },
              { visible: true, name: 'Airport database', link: '/home/airports', svg: '', fas_class: 'fa fa-plane' },
              { visible: true, name: 'Domain whitelist', link: '/home/domains', svg: '', fas_class: '' },
              // { visible: true, name: 'SMTP Server', link: '/home/backendsettings', svg: '', fas_class: '' },
              { visible: true, name: 'Escrow Time', link: '/home/geolocation_escrow_time', svg: '', fas_class: '' },
              { visible: true, name: 'Swagger documentation', link: '/home/swagger_documentation', svg: '', fas_class: 'fa fa-book' },
            ]
          });
        }
        if (results[6]) { // "Statistics"
          this.menu_items.push({
            visible: true, 
            name: 'Statistics',
            header: 'Statistics', 
            link: '/home/statistics',
            svg: '',
            fas_class: 'fa fa-pie-chart',
            subMenu: []
          });
        }
        // if (results[7]) { // "Travels"
        //   this.menu_items.push({
        //     visible: idIsSet, 
        //     name: 'Travels',
        //     header: 'Travels', 
        //     link: '/home/mobile_user/' + this.id + '/trips',
        //     svg: '',
        //     fas_class: 'fa fa-plane',
        //     subMenu: [
        //       { visible: true, name: '', link: '/move_up', svg: null, fas_class: 'fa fa-angle-up' },
              
        //       { header: 'Airport database', visible: true, name: 'Airport database', link: '/home/airports', svg: '', fas_class: 'fa fa-plane' },
        //     ]
        //   });
        // }
        if (results[7]) { // "Logs"
          var subMenu = [
            { visible: true, name: '', link: '/move_up', svg: null, fas_class: 'fa fa-angle-up' },
            { header: 'SSO Logs', visible: true, name: 'SSO Logs', link: '/home/sso_logs', svg: 'console', fas_class: 'fa fa-cogs' },
            { header: 'Crisis 1h logs', visible: true, name: 'Crisis 1h logs', link: '/home/crisis_logs', svg: 'console', fas_class: 'fa fa-cogs' },
            
          ];
          if (results[8]) {
            subMenu.push({ header: 'Admin actions logs', visible: true, name: 'Admin logs', link: '/home/admin_logs', svg: 'console', fas_class: 'fa fa-cogs' });
          }
          this.menu_items.push({
            visible: true, 
            name: 'Logs',
            header: 'Logs', 
            link: '/home/sso_logs',
            svg: '',
            fas_class: 'fa fa-terminal',
            subMenu: subMenu
          });
        }

        // if (results[menu_index++]) { 
        //   this.first_level_menu_items.push({ name: 'Domains', link: '/home/domains', svg: null, fas_class: 'fa fa-at' }); 
        // }
        this.setMenuItems();
      }
    );
  }

  setMenuItems() {
    if(this.activeUrl) {  // if we have active url then search what is active
      // iterate all subitems and if the link belongs to second level, then switch to it
      // otherwise keep the the first level as active
      let foundSubItem = false;
      for (let index = 0; index < this.menu_items.length; index++) {
        if (foundSubItem) {
          break;
        }
        const mi = this.menu_items[index];
        if(mi.subMenu && mi.subMenu.length > 0) {
          for (let k = 0; k < mi.subMenu.length; k++) {
            const smi = mi.subMenu[k];
            if(this.activeUrl.indexOf(smi.link) > -1) {
              this.activeMenuItems = mi.subMenu;
              this.header = mi.header;  // the 0 is navigation up, and it is empty
              this.firstLevelIsActive = false;
              foundSubItem = true;
              break;
            }
          }
        }
      }
      if(!foundSubItem) {
        this.activeMenuItems = this.menu_items;
        this.firstLevelIsActive = true;
        // then the active item is first level item so try to find the url
        for (let index = 0; index < this.menu_items.length; index++) {
          const mi = this.menu_items[index];
          if(this.activeUrl.indexOf(mi.link) > -1) {
            this.header = mi.header;
          }
        }
      }
    } else {  // otherwise just set the first menu level
      this.activeMenuItems = this.menu_items;
    }
  }

  clickMenuItem() {
    // This function is called only when the navigation from router is not fired
    if(this.firstLevelIsActive) {
      this.setMenuItems();
    }
  }

  // setHeader() {
  //   let url = this.activeUrl;
  //   this.displayBackButton = false;
  //   if (!url && url.length === 0) {
  //     this.header = '';
  //     return;
  //   } else if (url.indexOf('backend_users') > -1) { this.header = 'Backend Users';
  //   } else if (url.indexOf('backend_user') > -1) {
  //     if (url.indexOf('edit') > -1) { this.header = 'Edit member';
  //     } else if (url.indexOf('new') > -1) { this.header = 'Create new member'; }

  //   } else if (url.indexOf('mobile_users') > -1) { this.header = 'Mobile users';
  //   } else if (url.indexOf('map_all') > -1) { this.header = 'Global map';
  //   } else if (url.indexOf('mobile_user') > -1) {
  //     if (url.indexOf('edit') > -1) { this.header = 'Edit mobile user';
  //     } else if (url.indexOf('new') > -1) { this.header = 'Create new mobile user';
  //     } else if (url.indexOf('trips') > -1) { this.header = 'Mobile user trips';
  //     } else if (url.indexOf('locations') > -1) { this.header = 'Mobile user locations';
  //     } else if (url.indexOf('time_map') > -1) { this.header = 'Mobile user map';
  //     } else if (url.indexOf('trips') > -1) { this.header = 'Mobile user trips'; }
  //   } 
  //   else if (url.indexOf('properties') > -1) { 
  //     this.header = 'Entities';
  //   }
  //   else if (url.indexOf('risk_assessment') > -1) {
  //     if (url.indexOf('fact_sheets') > -1) {
  //       this.header = 'Fact sheets';
  //     } else if(url.indexOf('vigi_country') > -1) {
  //         this.header = "VIGI Country";
  //     } else if(url.indexOf('risk_setting') > -1) {
  //         this.header = "Risk levels";
  //     } else if(url.indexOf('tips') > -1) {
  //         this.header = "Tips";
  //     }
  //   } else if (url.indexOf('domains') > -1) {
  //     this.header = 'Domains';
  //   } else if (url.indexOf('statistics') > -1) {
  //     this.header = 'Statistics';
  //   } else if (url.indexOf('push_notification') > -1) {
  //     this.header = 'Send alert';
  //   } else if (url.indexOf('notifications') > -1) {
  //     this.header = 'Alert history';
  //   } else if (url.indexOf('appsettings') > -1) {
  //     this.header = 'App settings';
  //   } else if (url.indexOf('airports') > -1) {
  //     this.header = 'Airports';
  //   }
  //   else if (url.indexOf('sso_logs') > -1 ) {
  //     this.header = 'SSO Log';
  //   }
  // }

  returnBack() {
    window.history.back();
  }
  // http://stackoverflow.com/questions/39271654/routerlinkactive-for-routerlink-with-parameters-dynamic
  // isRouteActive(instruction: any[] ): boolean {
  //   return this.router.isActive(this.router.createUrlTree(instruction), true);
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.configureSideNav();
  }

  configureSideNav() {
    const smallScreen = window.innerWidth < 901 ? true : false;
    if (!smallScreen) {
      this.sidenav.mode = 'side';
      this.sidenav.opened = true;
    } else {
      this.sidenav.mode = 'over';
      this.sidenav.opened = false;
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
