import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { BasicPobInfo } from '../../../models/bpi';
import { Router } from '@angular/router';
import { PanicService } from 'src/app/models_services/panic.service';
import { LocationDetails } from 'src/app/models/location_details';
import { AppConfig } from 'src/app/app.config';
import { faFilePdf, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { Location } from 'src/app/models/location';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { MobileUser } from 'src/app/models/mobile-user';
import { Alert } from 'src/app/models/alert';
import { exportPanicInfoPDF } from 'src/app/common/pdf_export';

@Component({
  selector: 'app-marker-info-dialog',
  templateUrl: './marker-info-dialog.component.html',
  styleUrls: ['./marker-info-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MarkerInfoDialogComponent implements OnInit {
  @Input() selectedBPI: BasicPobInfo;
  @Input() displayMarkerInfoDialog: boolean;
  @Input() showPanicModeInfoLink: boolean = false;
  @Output() onDialogClose = new EventEmitter();

  location: Location;
  locationDetails: LocationDetails;
  mobileUser: MobileUser = null;
  alert: Alert = null;
  displayPanicInfoDialog: boolean = false;
  faFilePdf = faFilePdf;

  constructor(
    private router: Router,
    private panicService: PanicService,
    public appConfig: AppConfig
  ) {
  }

  ngOnInit() {
  }

  onHide() {
    console.log("POI info is closed");
    this.onDialogClose.emit();
  }

  navigateToUserEditPage(pobId: number){
    this.router.navigate(['/home/mobile_user', pobId, 'edit']);
  }

  showPanicModeInfoDialog(selectedBPI){
    console.log(selectedBPI);
    console.log(selectedBPI.location_id);
    // Get location panic information
    this.panicService.getLocationPanicInfo(selectedBPI.location_id).subscribe(
      (data: any) => { 
        this.location = data.location;
        this.locationDetails = data.location_details;
        console.log(this.locationDetails);
        this.mobileUser = data.mobile_user;
        this.alert = data.alert;
        this.displayPanicInfoDialog = true;
        this.displayMarkerInfoDialog = false;
      }
    );
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  exportPanicInfo() {
    exportPanicInfoPDF(this.mobileUser, this.locationDetails, this.location, this.appConfig)
    // const doc = new jsPDF('p', 'mm', [210,279]);
    // let head = ['', ''];
    // let body = [];
    // let row = [];

    // let firstColumnLables = [
    //   'Given name', 'Surname name', 'Time', 'Email', 
    //   'Alert type', 'Panic type', 'Latitude', 'Longitude', 'Country',
    //   'Baterry level', 'Description', 
    // ]
    // let secondColumnValues = [
    //   (this.mobileUser) ? this.mobileUser.firstname :'', 
    //   (this.mobileUser) ? this.mobileUser.lastname :'', 
    //   (this.locationDetails) ? (this.locationDetails.date_created + ' (UTC)' ): "",
    //   (this.mobileUser) ? this.mobileUser.email :'', 
    //   this.location.type, 
    //   (this.locationDetails) ? this.locationDetails.type_of_panic :'',
    //   this.location.latitude, 
    //   this.location.longitude, 
    //   this.location.country_code,
    //   this.location.battery_level, 
    //   (this.locationDetails) ? this.locationDetails.description :'',
    // ]

    // for (let index = 0; index < firstColumnLables.length; index++) {
    //   row = [];
    //   const label = firstColumnLables[index];
    //   const value = secondColumnValues[index];
    //   row.push(label);
    //   row.push(value);
    //   body.push(row);
    // }

    // (doc as any).autoTable({
    //   head: [head],
    //   body: body,
    // })
    // let imgPath1 = null;
    // let imgPath2 = null;
    // let imgPath3 = null;

    // if (this.locationDetails.image_path) {
    //   imgPath1 = this.appConfig.mobileUrl + '/static/' + this.locationDetails.image_path;
    //   let img1 = new Image();
    //   img1.src = imgPath1;
    //   console.log("Image1:" + img1.naturalHeight + "," + img1.naturalWidth);
    //   let ratio = img1.naturalWidth / 190;
    //   doc.addPage('1');
    //   doc.addImage(imgPath1, 'JPEG', 10, 10, img1.naturalWidth/ratio, img1.naturalHeight/ratio);
    // }
    // if (this.locationDetails.image2_path) {
    //   imgPath2 = this.appConfig.mobileUrl + '/static/' + this.locationDetails.image2_path;
    //   let img2 = new Image();
    //   img2.src = imgPath2;
    //   console.log("Image2:" + img2.naturalHeight + "," + img2.naturalWidth);
    //   let ratio = img2.naturalWidth / 190;
    //   doc.addPage('2');
    //   doc.addImage(imgPath1, 'JPEG', 10, 10, img2.naturalWidth/ratio, img2.naturalHeight/ratio);
    // }
    // if (this.locationDetails.image3_path) {
    //   imgPath3 = this.appConfig.mobileUrl + '/static/' + this.locationDetails.image3_path;
    //   let img3 = new Image();
    //   img3.src = imgPath3;
    //   console.log("Image3:" + img3.naturalHeight + "," + img3.naturalWidth);
    //   let ratio = img3.naturalWidth / 190;
    //   doc.addPage('3');
    //   doc.addImage(imgPath1, 'JPEG', 10, 10, img3.naturalWidth/ratio, img3.naturalHeight/ratio);
    // }
    // doc.save('Panic info.pdf');
  }

}
