import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import * as MarkerClusterer from "@google/markerclusterer";
import * as OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
import { BasicPobInfo } from '../../../models/bpi';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { BackendUserService } from '../../../models_services/backend_user.service';
import { forkJoin } from 'rxjs';
import { LocationI } from '../../../models/interface/locationI';
import { MapService } from '../../../models_services/map.service';
import { Observable } from 'rxjs-compat';
import { StoreService } from 'src/app/common/application.state';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pob-map',
  templateUrl: './pob-map.component.html',
  styleUrls: ['./pob-map.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PobMapComponent implements OnInit {
  @Input() displayNavigationArrows = false;
  @Input() showPanicModeInfoLink: boolean;
  displayMarkerInfoDialog = false;
  isCursorActive = false;
  
  _bpiList: BasicPobInfo[];
  selectedBPI: BasicPobInfo;
  markersInfoMap: Map<number, BasicPobInfo>;
  markerCluster: MarkerClusterer;
  @Input() showPobsEmails: boolean = true;

  polygonDrawingEndResult: any;
  
  oms: any;
  options = {
    center: {lat: 48.830772399902344, lng: 2.8097193241119385},
    zoom: 9
  };
  overlays = [];
  map: google.maps.Map;

  constructor(
    private growlService: GrowlService,
    private mapService: MapService,
    private backendUserService: BackendUserService,
    private storeService: StoreService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.mapService.nullMapService();
    this.subscribeToDrawingPolygon();
  }

  @Input()
  set bpiList(bpiList: BasicPobInfo[]) {
    this._bpiList = bpiList;
    this.refreshMapPOIs();
  }

  setMap(event) {
    this.map = event.map;
    // window.wmap = this.map;
    this.map.setCenter(this.options.center);
    this.refreshMapPOIs();
  }

  

  refreshMapPOIs(){
    this.overlays.length = 0;
    if (!this._bpiList || this._bpiList.length === 0) {
      console.log("There are not locations to be displayed on the map");
    }
    if (this.map) { // map could be initialized later. When map is set, it will call this method again
      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true
      });
      this.markersInfoMap = new Map<number, BasicPobInfo>();
      this.markerCluster = this.mapService.populateMapWithPath(
        this.map, 
        this.markersInfoMap, 
        this.overlays, 
        this.oms, 
        this.markerCluster,
        this._bpiList, 
        this.handleOverlayClick, 
        this.showPobsEmails
      );
      this.oms.addListener('click', (marker, event) => {
        this.handleOverlayClick(marker);
      });
    }
  }

  // handleOverlayClick(event) if it comes from primeng with (onOverlayClick)="handleOverlayClick($event)"
  // event.overlay.labelContent
  // But here the event comes from the spiderfier oms and receives the marker as parameter
  handleOverlayClick(marker) {
    const label: string = marker.labelContent;
    this.selectedBPI = this.markersInfoMap.get(+(label.split('.')[0]));
    this.displayMarkerInfoDialog = true;
    // let requestTeamsObservable = this.backendUserService.getTeams(this.selectedBPI.pob_id);
    // let requestManagedTeamsObservable = this.backendUserService.getManaged(this.selectedBPI.pob_id);
    // // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    // forkJoin(requestTeamsObservable, requestManagedTeamsObservable).subscribe(
    //   (res: Array<any>) => {
    //     let teamsData = res[0];
    //     let managedTeamsData = res[1];
    //     if ( teamsData ) { this.selectedUserTeams = teamsData.teams; }
    //     if (managedTeamsData) { this.selectedUserManagedTeams = managedTeamsData.managed_teams; }
    //   }
    // );
  }

  onDialogClose() {
    this.displayMarkerInfoDialog = false;
  }

  moveNextLocation():void {
    const result: LocationI = this.mapService.moveNextLocation(this._bpiList);
    (result) ? this.map.setCenter(result) : this.options.center;
  }
  
  movePrevLocation ():void {
    const result: LocationI = this.mapService.movePrevLocation(this._bpiList);
    (result) ? this.map.setCenter(result) : this.options.center;
  }
  
  moveToLastLocation ():void {
    const result: LocationI = this.mapService.moveToLastLocation(this._bpiList);
    (result) ? this.map.setCenter(result) : this.options.center;
  }
  
  moveToFirstLocation ():void {
    const result: LocationI = this.mapService.moveToFirstLocation(this._bpiList);
    (result) ? this.map.setCenter(result) : this.options.center;
  }

  onCursorClick(){
    console.log("onCursorClick");
    this.isCursorActive = !this.isCursorActive;
    if (this.isCursorActive) {
      this.mapService.initDrawingManager(this.map);
    } else {
      this.mapService.deactivateDrawingManager();
      this.mapService.clearDrawingManager();
    }
  }

  subscribeToDrawingPolygon() {
    this.mapService.getPolygonDrawingEndObservable().subscribe({
      next: (result) => {
        this.polygonDrawingEndResult = result;
        console.log("PolygonDrawingEndResult: ");
        console.log(this.polygonDrawingEndResult);
        if (this.polygonDrawingEndResult && this.polygonDrawingEndResult.length > 0) {
          var selectedBPIsIds = []; 
          this.polygonDrawingEndResult.forEach(pdr => {
            const label: string = pdr.labelContent;
            let basicPobInfo = this.markersInfoMap.get(+(label.split('.')[0]))
            selectedBPIsIds.push(
              basicPobInfo.pob_id
            );
          });
          selectedBPIsIds.forEach(element => {
            console.log("selected bpi");
            console.log(element);
          });
          this.storeService.setHowAreYouPobIds(selectedBPIsIds);
          // navigate to how are you
          this.router.navigate(['/home/push_notification']);
        } else {
          this.growlService.showInfoText("No locations were found in that polygon");
          this.isCursorActive = false;
          this.mapService.deactivateDrawingManager();
          this.mapService.clearDrawingManager();
        }
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });
  }

}
