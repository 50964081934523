import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var AlertService = /** @class */ (function () {
    function AlertService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    AlertService.prototype.sentTo = function (type, divisionIds, poleIds, mobileuserIds, selectedSubscriptionCountryIds, selectedLocalizedCountryIds, title, body, titleFr, bodyFr, itIsHowAreYou) {
        if (itIsHowAreYou === void 0) { itIsHowAreYou = false; }
        return this.httpClient.post(this.config.apiUrl + '/alerts/send_to', {
            'type': type,
            'division_ids': divisionIds,
            'pole_ids': poleIds,
            'mobileuser_ids': mobileuserIds,
            'selected_subscription_country_ids': selectedSubscriptionCountryIds,
            'selected_localized_country_ids': selectedLocalizedCountryIds,
            'title': title,
            'body': body,
            'title_fr': titleFr,
            'body_fr': bodyFr,
            'it_is_how_are_you': itIsHowAreYou
        });
    };
    AlertService.prototype.getAllHowAreYouForUserWithParams = function (params, mobileUserId) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/alerts/howareyou/' + mobileUserId, {
            params: httpParams,
            observe: 'body'
        });
    };
    AlertService.prototype.getHowAreYouWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/alerts/howareyou', {
            params: httpParams,
            observe: 'body'
        });
    };
    AlertService.prototype.getAllWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/alerts', {
            params: httpParams,
            observe: 'body'
        });
    };
    AlertService.prototype.getAllHAYWithParams = function (params) {
        var httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get(this.config.apiUrl + '/hayalerts', {
            params: httpParams,
            observe: 'body'
        });
    };
    AlertService.prototype.getAllAlertTypes = function () {
        return this.httpClient.get(this.config.apiUrl + '/alerts/types');
    };
    AlertService.prototype.getAlertSentUsers = function (alertId) {
        return this.httpClient.get(this.config.apiUrl
            + '/alerts/' + alertId + '/alerted_users');
    };
    AlertService.prototype.getById = function (alertId) {
        return this.httpClient.get(this.config.apiUrl + '/alerts/' + alertId);
    };
    AlertService.prototype.update = function (alert) {
        return this.httpClient.put(this.config.apiUrl + '/alerts/' + alert.id, alert);
    };
    AlertService.prototype.show = function (id) {
        return this.httpClient.put(this.config.apiUrl + '/alerts/' + id + '/show', alert);
    };
    AlertService.prototype.hide = function (id) {
        return this.httpClient.put(this.config.apiUrl + '/alerts/' + id + '/hide', alert);
    };
    AlertService.prototype.delete = function (id) {
        return this.httpClient.delete(this.config.apiUrl + '/alerts/' + id);
    };
    AlertService.prototype.deleteBulkOfAlerts = function (alerts) {
        var ids = [];
        if (alerts && alerts.length > 0) {
            ids = alerts.map(function (a) { return a.id; });
        }
        return this.httpClient.post(this.config.apiUrl + '/alerts/bulkdelete', { "ids": ids });
    };
    AlertService.prototype.downloadAlertsCSV = function () {
        return this.httpClient.get(this.config.apiUrl
            + '/alerts/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    AlertService.prototype.downloadAlertsPdf = function () {
        return this.httpClient.get(this.config.apiUrl
            + '/alerts/pdf', {
            headers: new HttpHeaders().set('Accept', 'application/pdf'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    AlertService.prototype.downloadHAYsCSV = function () {
        return this.httpClient.get(this.config.apiUrl
            + '/alerts/howareyou/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    AlertService.prototype.downloadHAYsPdf = function () {
        return this.httpClient.get(this.config.apiUrl
            + '/alerts/howareyou/pdf', {
            headers: new HttpHeaders().set('Accept', 'application/pdf'),
            observe: 'response',
            responseType: 'blob'
        });
    };
    AlertService.ngInjectableDef = i0.defineInjectable({ factory: function AlertService_Factory() { return new AlertService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
export { AlertService };
