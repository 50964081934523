import { Injectable } from '@angular/core';

import { BackendUser } from '../models/backend-user';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class BackendUserService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAll() {
        return this.httpClient.get<any>(this.config.apiUrl + '/backendusers');
    }

    getAllWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/backendusers', {
            params: httpParams,
            observe: 'body'
        });
    }

    getById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/backendusers/' + _id);
    }

    create(user: BackendUser) {
        return this.httpClient.post(this.config.apiUrl + '/backendusers', user);
    }

    update(user: BackendUser) {
        return this.httpClient.put(this.config.apiUrl + '/backendusers/' + user.id, user);
    }

    delete(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/backendusers/' + _id);
    }

    downloadUsersCSV() {
        return this.httpClient.get(this.config.apiUrl + '/backendusersCSV', {
            headers: new HttpHeaders().set('Accept', 'application/zip' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    sendResetPasswordEmail(email){
        return this.httpClient.post(this.config.apiUrl + '/backendusers/reset_password_email', {'email': email});
    }

}
