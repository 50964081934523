<p-dialog header="Location info" [(visible)]="displayMarkerInfoDialog" (onHide)="onHide()">
  <div fxLayout="column">
    <div fxLayout="row">
      <label>Given name</label>
      <span>{{ selectedBPI?.firstname }}</span>
    </div>
    <div fxLayout="row">
      <label>Surname</label>
      <span>{{ selectedBPI?.surname }}</span>
    </div>
    <div fxLayout="row">
      <label>Time</label>
      <span>{{ selectedBPI?.location_time | date:'long' }}</span>
    </div>
    <div fxLayout="row">
      <label>Location latitude</label>
      <span>{{ selectedBPI?.latitude }}</span>
    </div>
    <div fxLayout="row">
      <label>Location longitude</label>
      <span>{{ selectedBPI?.longitude }}</span>
    </div>
    <div fxLayout="row">
      <label>City</label>
      <span>{{ selectedBPI?.city }}</span>
    </div>
    <div fxLayout="row">
      <label>Country</label>
      <span>{{ selectedBPI?.country }}</span>
    </div>
    <div fxLayout="row">
      <label>Battery level</label>
      <span>{{ selectedBPI?.battery_level }}</span>
      <span *ngIf="selectedBPI && selectedBPI.battery_level">%</span>
    </div>
    <div fxLayout="row">
      <label>Profile</label>
      <span class="icon_link">
        <mat-icon (click)="navigateToUserEditPage(selectedBPI?.pob_id)">link</mat-icon>
      </span>
    </div>
    <div fxLayout="row" [style.visibility]="showPanicModeInfoLink? 'visible': 'hidden'">
      <label>Panic mode info</label>
      <span class="icon_link">
        <mat-icon (click)="showPanicModeInfoDialog(selectedBPI)">link</mat-icon>
      </span>
    </div>
  </div>
</p-dialog>


<p-dialog header="Panic details" [(visible)]="displayPanicInfoDialog">
  <div fxLayout="column">
    <!-- 'Given name', 'Surname name', 'Email', 
      'Type', 'Latitude', 'Longitude', 'Country',
      'Baterry level', 'Description'
    -->
    <div fxLayout="row">
      <label>Given name</label>
      <span>{{ mobileUser?.firstname }}</span>
    </div>
    <div fxLayout="row">
      <label>Surname</label>
      <span>{{ mobileUser?.lastname }}</span>
    </div>
    <div fxLayout="row">
      <label>Time</label>
      <span>{{ locationDetails?.date_created + " " + ((locationDetails && locationDetails.date_created) ? "(UTC)": "") }}</span>
    </div>
    <div fxLayout="row">
      <label>Email</label>
      <span>{{ mobileUser?.email }}</span>
    </div>
    <div fxLayout="row">
      <label>Alert type</label>
      <span>{{ location?.type }}</span>
    </div>
    <div fxLayout="row">
      <label>Panic type</label>
      <span>{{ locationDetails?.type_of_panic }}</span>
    </div>
    <div fxLayout="row">
      <label>Latitude</label>
      <span>{{ location?.latitude }}</span>
    </div>
    <div fxLayout="row">
      <label>Longitude</label>
      <span>{{ location?.longitude }}</span>
    </div>
    <div fxLayout="row">
      <label>Country</label>
      <span>{{ location?.country }}</span>
    </div>
    <div fxLayout="row">
      <label>Baterry level</label>
      <span>{{ location?.battery_level }}</span>
    </div>
    <div fxLayout="row">
      <label>Description</label>
      <span>{{ locationDetails?.description }}</span>
    </div>
    <br/>
    <div fxLayout="row" style="margin-top:10px">
      <a [style.visibility]="locationDetails?.image_path ? 'visible': 'hidden'">
        <img 
        class="panic_image" 
        [src]="appConfig.mobileUrl + '/static/' + locationDetails?.image_path"
        (click)="goToLink(appConfig.mobileUrl + '/static/' + locationDetails?.image_path)"
      >
      </a>
      <a [style.visibility]="locationDetails?.image2_path ? 'visible': 'hidden'">
        <img 
        class="panic_image" 
        [src]="appConfig.mobileUrl + '/static/' + locationDetails?.image2_path"
        (click)="goToLink(appConfig.mobileUrl + '/static/' + locationDetails?.image2_path)"
        >
      </a>
      <a [style.visibility]="locationDetails?.image3_path ? 'visible': 'hidden'">
        <img 
        class="panic_image" 
        [src]="appConfig.mobileUrl + '/static/' + locationDetails?.image3_path"
        (click)="goToLink(appConfig.mobileUrl + '/static/' + locationDetails?.image3_path)"
        >
      </a>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button (click)="exportPanicInfo()"
        style="color: red; border-radius: 5px; border-color: red; "
        pTooltip="Save Panic Info as PDF" >
        <fa-icon [icon]="faFilePdf" style="font-size: 25px;"></fa-icon> 
        <b>&nbsp; Save as PDF</b>
      </button>
    </div>
  </div>
</p-dialog>
