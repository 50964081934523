import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationService } from '../../models_services/application.service';
import { AppText } from '../../models/app-text';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { Editor } from 'primeng/primeng';
import { ConfirmDialogsService } from 'src/app/_module_dialog/confirm_dialog/confirm-dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appsettings',
  templateUrl: './appsettings.component.html',
  styleUrls: ['./appsettings.component.css']
})
export class AppsettingsComponent implements OnInit, AfterViewInit {
  smtpPassword: string;
  smtpEmail: string;
  tabIndex = 0;
  versionNumbers = [
    {name: 'Visible version', value: 1}, 
    {name: 'Backup version', value: 0}
  ];
  selectedVersionNumber = {name: 'Visible version', value: 1};

  appTexts = [
    {header: 'FAQ', text: new AppText(), selected: true},
    {header: 'Support', text: new AppText(), selected: false},
    {header: 'Terms & Conditions', text: new AppText(), selected: false},
    {header: 'Use of Personal data', text: new AppText(), selected: false},
  ]

  constructor(
    private applicationService: ApplicationService,
    private growlService: GrowlService, 
    private confirmDialogsService: ConfirmDialogsService,
  ) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.getTexts();
    this.applicationService.getSmtpSettings().subscribe(
      (data: any) => {
        this.smtpPassword = data.password;
        this.smtpEmail = data.email;
      }
    );
  }

  getTexts() {
    this.applicationService.getFAQ(this.selectedVersionNumber.value).subscribe(
      (data: any) => {
        this.appTexts[0].text = data.app_text;
      }
    );
    this.applicationService.getSupport(this.selectedVersionNumber.value).subscribe(
      (data: any) => {
        this.appTexts[1].text = data.app_text;
      }
    );
    this.applicationService.getTAndC(this.selectedVersionNumber.value).subscribe(
      (data: any) => {
        this.appTexts[2].text = data.app_text;
      }
    );
    this.applicationService.getPersonalDataUse(this.selectedVersionNumber.value).subscribe(
      (data: any) => {
        this.appTexts[3].text = data.app_text;
      }
    );
  }

  onTabChange(event) {
    this.tabIndex = event.index;
  }

  updateFAQ(){
    this.applicationService.updateFAQ(
      this.appTexts[0].text, 
      this.selectedVersionNumber.value
    ).subscribe(
      (data)=> {
        this.growlService.showInfoText('FAQ was updated');
      }
    );
  }

  updateSupport(){
    this.applicationService.updateSupport(
      this.appTexts[1].text, 
      this.selectedVersionNumber.value
    ).subscribe(
      (data)=> {
        this.growlService.showInfoText('Support text was updated');
      }
    );
  }

  updateTAndC(){
    this.applicationService.updateTAndC(
      this.appTexts[2].text, 
      this.selectedVersionNumber.value
    ).subscribe(
      (data)=> {
        this.growlService.showInfoText('Terms & Conditions text was updated');
      }
    );
  }

  updatePersonalData(){
    this.applicationService.updatePersonalData(
      this.appTexts[3].text, 
      this.selectedVersionNumber.value
    ).subscribe(
      (data)=> {
        this.growlService.showInfoText('Use of Personal data was updated ');
      }
    );
  }

  updateEmailPassword(){
    this.applicationService.updateEmailPassword(this.smtpEmail, this.smtpPassword).subscribe(
      (data)=> {
        this.growlService.showInfoText('Sender email password was updated');
      }
    );
  }

  // Version change
  onVersionNumbers() {
    console.log('Selected version number:' + this.selectedVersionNumber);
    this.getTexts();
  }

  restoreBackup() {
    this.confirmDialogsService.show(
      'Restore from backup', 
      'This will replace the current visible mobile application texts with the backup texts. Do you confirm?',
      "Yes", "No"
    )
    .subscribe(res => {
      if (res) {
        this.applicationService.restoreFromBackup().subscribe(
          (data)=> {
            this.growlService.showInfoText('Visible version was updated successfully');
            this.getTexts();
          }
        );
      }
    });
  }

}
