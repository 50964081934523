import { jsPDF } from "jspdf";
import 'jspdf-autotable';

var imagesLoadError = false;
var imagesLoaded = 0;
var imagesToLoad = 0;

export function exportPanicInfoPDF(mobileUser, locationDetails, location, appConfig) {
  console.log("exportPanicInfoPDF");
  try {
    console.log("Create doc jsPDF object");
    const doc = new jsPDF('p', 'mm', [210, 279]);
    let head = ['', ''];
    let body = [];
    let row = [];

    console.log("Init the columns");
    let firstColumnLables = [
      'Given name', 'Surname name', 'Time', 'Email',
      'Alert type', 'Panic type', 'Latitude', 'Longitude', 'Country',
      'Baterry level', 'Description',
    ]
    console.log("Init second column values");
    let secondColumnValues = [
      (mobileUser) ? mobileUser.firstname : '',
      (mobileUser) ? mobileUser.lastname : '',
      (locationDetails) ? (locationDetails.date_created + ' (UTC)') : "",
      (mobileUser) ? mobileUser.email : '',
      location.type,
      (locationDetails) ? locationDetails.type_of_panic : '',
      location.latitude,
      location.longitude,
      location.country_code,
      location.battery_level,
      (locationDetails) ? locationDetails.description : '',
    ]

    console.log("push first and second column value for every row")
    for (let index = 0; index < firstColumnLables.length; index++) {
      row = [];
      const label = firstColumnLables[index];
      const value = secondColumnValues[index];
      row.push(label);
      row.push(value);
      body.push(row);
    }

    console.log("Do autoTable");
    (doc as any).autoTable({
      head: [head],
      body: body,
    })
    let imgPath1 = null;
    let imgPath2 = null;
    let imgPath3 = null;

    console.log("Count images to load...");
    imagesLoaded = 0;
    imagesToLoad = 0;
    if (locationDetails) {
      console.log("There are location details");
      if (locationDetails.image_path) { imagesToLoad = 1 + imagesToLoad; }
      if (locationDetails.image2_path) { imagesToLoad = 1 + imagesToLoad; }
      if (locationDetails.image3_path) { imagesToLoad = 1 + imagesToLoad; }

      console.log("Check image1 and process");
      if (locationDetails.image_path) {
        imgPath1 = appConfig.mobileUrl + '/static/' + locationDetails.image_path;
        let img1 = new Image();
        img1.src = imgPath1;
        img1.onload = function () {
          try {
            console.log("Image1: height->" + img1.naturalHeight + ",width->" + img1.naturalWidth + ",url->" + imgPath1);
            let ratio = img1.naturalWidth / 190;
            doc.addPage('1');
            doc.addImage(imgPath1, 'JPEG', 10, 10, img1.naturalWidth / ratio, img1.naturalHeight / ratio);
            saveAfterCheckAllImagesLoaded(doc);
          } catch (error) {
            saveAfterCheckAllImagesLoaded(doc);
          }
        };
      }
      console.log("Check image2 and process");
      if (locationDetails.image2_path) {
        imgPath2 = appConfig.mobileUrl + '/static/' + locationDetails.image2_path;
        let img2 = new Image();
        img2.src = imgPath2;
        img2.onload = function () {
          try {
            console.log("Image2:" + img2.naturalHeight + "," + img2.naturalWidth);
            let ratio = img2.naturalWidth / 190;
            doc.addPage('2');
            doc.addImage(imgPath1, 'JPEG', 10, 10, img2.naturalWidth / ratio, img2.naturalHeight / ratio);
            saveAfterCheckAllImagesLoaded(doc);
          } catch (error) {
            saveAfterCheckAllImagesLoaded(doc);
          }
        }
      }
      console.log("Check image3 and process");
      if (locationDetails.image3_path) {
        imgPath3 = appConfig.mobileUrl + '/static/' + locationDetails.image3_path;
        let img3 = new Image();
        img3.src = imgPath3;
        img3.onload = function () {
          try {
            console.log("Image3:" + img3.naturalHeight + "," + img3.naturalWidth);
            let ratio = img3.naturalWidth / 190;
            doc.addPage('3');
            doc.addImage(imgPath1, 'JPEG', 10, 10, img3.naturalWidth / ratio, img3.naturalHeight / ratio);
            saveAfterCheckAllImagesLoaded(doc);
          } catch (error) {
            saveAfterCheckAllImagesLoaded(doc);
          }
        }
      }
    } else {
      console.log("No location details found");
    }

    console.log("Check if image to load are not present and directly export pdf");
    if (imagesToLoad == 0) {
      console.log("Directly export pdf")
      doc.save('Panic info.pdf');
    }
  } catch (error) {
    console.error("An error occurred:", error.message);
    console.error("Stack trace:", error.stack);
  }
}

function saveAfterCheckAllImagesLoaded(doc) {
  console.log("Call saveAfterCheckAllImagesLoaded");
  imagesLoaded++;
  if (imagesLoaded === imagesToLoad) {
    // All images have finished loading
    console.log("All images have loaded");
    if (!imagesLoadError) { doc.save('Panic info.pdf'); }
  }
}