<div 
  *ngIf="showTimeFilterButtons" 
  id="timeFilterButtons"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="10px">
  <div 
  style="position: absolute; z-index: 2; top:3px;" 
  fxLayout="row" 
  fxLayoutGap="5px">
    <button pButton label="Time filter" (click)="onTimeFilterButtonClick()"></button>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button pButton type="button" 
      label="Corporate"
      *ngIf="!itIsSingleUserPage"
      [ngClass]="{'ui-button-secondary': (this.userType == 'SPONSORED' || !this.userType)? true: false}"
      (click)="showCorporateUsers()">
    </button>
    <button pButton type="button" 
      label="Sponsored"
      *ngIf="!itIsSingleUserPage"
      [ngClass]="{'ui-button-secondary': (this.userType == 'CORPORATE' || !this.userType)? true: false}"
      (click)="showSponsoredUsers()">
    </button>
    <p-button icon="pi pi-question" (click)="onLegendQuestionClick()"></p-button>
  </div>
</div>

<p-card header="User info" 
  [style]="{'width': '360px', 'position': 'absolute', 'z-index':'2', top:'70px', left:'16px'}" 
  [hidden]="!selectedPob"
>
  <div>
    <label><b>Given name:</b></label>
     {{ selectedPob?.firstname }}
    <br />
    <label><b>Surname:</b></label>
     {{ selectedPob?.lastname }}
    <br />
    <label><b>Email:</b></label>
    {{ selectedPob?.email }}
  </div>
</p-card>

<div id="legend" [style.visibility]="displayLegend? 'visible': 'hidden'" 
  style="padding:5px"
  fxLayoutAlign="center"
  style="position: fixed; left: 50%; margin-top: 35px; z-index: 2; background-color: #EEE;padding: 10px; border-radius:5px;">
  <app-poilegend></app-poilegend>
</div>

<div id="mapgm" 
[style.visibility]="displayTimeFilterForm? 'visible': 'hidden'"
style="padding:5px" fxLayoutAlign="center"
style="position: fixed; left: 30%; margin-top: 35px; z-index: 2; background-color: #EEE;padding: 10px; border-radius:5px;"
>
  <app-time-filter 
    (onFilter)="onFilter($event)"
    [startDateInput]="startDateInput"
    [endDateInput]="endDateInput"
  >
  </app-time-filter>
</div>

<app-pob-map 
  [bpiList]="bpiList" 
  [displayNavigationArrows]="false"
  [showPanicModeInfoLink]="true"
>
</app-pob-map>
