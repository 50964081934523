<div  id="timeFilterButtons" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div 
    style="position: absolute; z-index: 2; top:3px;" 
    fxLayout="row" 
    fxLayoutGap="5px">
    <button *ngIf="showTimeFilterButtons" 
    pButton label="Time filter" (click)="onTimeFilterButtonClick()">
    </button>
    <p-button icon="pi pi-question" (click)="onLegendQuestionClick()"></p-button>
  </div>
</div>


<p-card header="User info" 
  [style]="{'width': '360px', 'position': 'absolute', 'z-index':'2', top:'70px', left:'16px'}" 
  [hidden]="!selectedPob"
>
  <div>
    <label><b>Given name:</b></label>
     {{ selectedPob?.firstname }}
    <br />
    <label><b>Surname:</b></label>
     {{ selectedPob?.lastname }}
    <br />
    <label><b>Email:</b></label>
    {{ selectedPob?.email }}
  </div>
</p-card>

<div id="legend" [style.visibility]="displayLegend? 'visible': 'hidden'" 
  style="padding:5px"
  fxLayoutAlign="center"
  style="position: fixed; left: 50%; margin-top: 35px; z-index: 2; background-color: #EEE;padding: 10px; border-radius:5px;">
  <app-poilegend></app-poilegend>
</div>

<div id="mapgm" 
[style.visibility]="displayTimeFilterForm? 'visible': 'hidden'"
style="padding:5px" fxLayoutAlign="center"
style="position: fixed; left: 30%; margin-top: 35px; z-index: 2; background-color: #EEE;padding: 10px; border-radius:5px;"
>
  <app-time-filter (onFilter)="onFilter($event)" ></app-time-filter>
</div>

<app-pob-map 
[bpiList]="bpiList" 
[displayNavigationArrows]="false"
[showPanicModeInfoLink]="false"
>
</app-pob-map>
