import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MobileUserService } from '../../../models_services/mobile-users.service';
import { Subscription } from 'rxjs/Subscription';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { MobileUser } from '../../../models/mobile-user';
import { PhoneWithPlusAndSpaceValidator } from '../../../_helpers/validators/phone-number-with-plus-and-space';
import { BackendUser } from '../../../models/backend-user';
import { GroupService } from '../../../models_services/group.service';
import { PoleService } from '../../../models_services/pole.service';
import { DivisionService } from '../../../models_services/division.service';
import { SelectItem } from 'primeng/api';
import { BackendUserService } from '../../../models_services/backend_user.service';

@Component({
  selector: 'app-mobile-user-edit',
  templateUrl: 'mobile-user-edit.component.html',
  styles: []
})
export class MobileUserEditComponent implements OnInit, OnDestroy {
  currentUser: BackendUser;
  user: MobileUser = new MobileUser();
  subscription: Subscription;
  userForm: FormGroup = new FormGroup({});
  isNew = true;
  passwordDivision: AbstractControl;
  roles = [
    {label: 'Corporate' , value: 'CORPORATE'},
    {label: 'Manager' , value: 'MANAGER'},
    {label: 'Sponsored' , value: 'SPONSORED'}
  ]
  groupsSelectItems: SelectItem[] = [];
  polesSelectItems: SelectItem[] = [];
  divisionSelectItems: SelectItem[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: MobileUserService,
    private backendUserService: BackendUserService,
    private fromBuilder: FormBuilder,
    private growlService: GrowlService,
    private groupService: GroupService,
    private poleService: PoleService,
    private divisionService: DivisionService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('current_user'));
  }

  ngOnInit() {
    this.initForm();
    this.loadTheUser();
  }

  loadTheUser() {
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        if (params.hasOwnProperty('id')) {
          this.isNew = false;
          this.userService.getById(params['id']).subscribe(data => {
            console.log(data.user);
            this.user = data.user;
            if (this.user != null) {
              this.initForm();
              this.loadDropdownsOnEdit();
            }
          });
        } else {
          this.loadDropdownsOnCreate();
        }
      }
    );
  }

  loadDropdownsOnCreate(){
    // New user -> then fetch only the accessible entities of the top level
    // 1. If the user is master admin fetch the groups

    // 2. If the user is group admin set his group as selected group_id (put only one group inside the select items group list),  
    // and fetch the group poles

    // 3. If the user is pole admin, set selected group (only one in list) and pole (only one in list), 
    // and fetch the pole divisions

    // 4. If the user is division admin, set selected group, pole and division. 
    // In this case all dropdowns are fixed and cannot be changed

    const role = this.currentUser.role;
    switch (role) {
      case 'MASTER_ADMIN':
        // 1. If the user is master admin fetch the groups
        this.loadGroups();
        break;
      case 'GROUP_ADMIN':
        // 2. If the user is group admin set his group as selected group_id (put only one group inside the select items group list),  
        // and fetch the group poles
        this.backendUserService.getById(this.currentUser.id).subscribe(data => {
          this.groupsSelectItems = [];
          if (data.user && data.user.group) {
            this.groupsSelectItems.push({label:data.user.group.name_en, value: data.user.group.id});
            this.userForm.get('group_id').patchValue(data.user.group.id);
            this.loadAllAccessiblePoles(0);
          }
        });
        break;
      case 'POLE_ADMIN':
        // 3. If the user is pole admin, set selected group (only one in list) and pole (only one in list), 
        // and fetch the pole divisions
        this.backendUserService.getById(this.currentUser.id).subscribe(data => {
          this.groupsSelectItems = [];
          this.polesSelectItems = [];
          if (data.user && data.user.group && data.user.pole) {
            this.groupsSelectItems.push({label:data.user.group.name_en, value: data.user.group.id});
            this.userForm.get('group_id').patchValue(data.user.group.id);

            this.polesSelectItems.push({label:data.user.pole.name_en, value: data.user.pole.id});
            this.userForm.get('pole_id').patchValue(data.user.pole.id);

            this.loadAllAccessibleDivisions(0);
          }
        });
        break;
      case 'DIVISION_ADMIN':
        // 4. If the user is division admin, set selected group, pole and division. 
        // In this case all dropdowns are fixed and cannot be changed
        this.backendUserService.getById(this.currentUser.id).subscribe(data => {
          this.groupsSelectItems = [];
          this.polesSelectItems = [];
          this.divisionSelectItems = [];
          if (data.user && data.user.group && data.user.pole && data.user.division) {
            this.groupsSelectItems.push({label:data.user.group.name_en, value: data.user.group.id});
            this.userForm.get('group_id').patchValue(data.user.group.id);

            this.polesSelectItems.push({label:data.user.pole.name_en, value: data.user.pole.id});
            this.userForm.get('pole_id').patchValue(data.user.pole.id);

            this.divisionSelectItems.push({label:data.user.division.name_en, value: data.user.division.id});
            this.userForm.get('division_id').patchValue(data.user.division.id);
          }
        });
        break;
      default:
        break;
    }
  }

  loadDropdownsOnEdit() {
    const role = this.currentUser.role;
    switch (role) {
      case 'MASTER_ADMIN':
        this.loadGroups();
        this.loadAllAccessiblePoles(0);
        this.loadAllAccessibleDivisions(0);
        break;
      case 'GROUP_ADMIN':
        // If the user is group admin set his group as selected group_id (put only one group inside the select items group list)
        this.backendUserService.getById(this.currentUser.id).subscribe(data => {
          this.groupsSelectItems = [];
          if (data.user && data.user.group) {
            this.groupsSelectItems.push({label:data.user.group.name_en, value: data.user.group.id});
            this.userForm.get('group_id').patchValue(data.user.group.id);
            this.loadAllAccessiblePoles(0);
            this.loadAllAccessibleDivisions(this.user.id);
          }
        });
        // load accessible divisions for the edited user pole_id

        break;
      case 'POLE_ADMIN':
        // 3. If the user is pole admin, set selected group (only one in list) and pole (only one in list), 
        // and fetch the pole divisions
        this.backendUserService.getById(this.currentUser.id).subscribe(data => {
          this.groupsSelectItems = [];
          this.polesSelectItems = [];
          if (data.user && data.user.group && data.user.pole) {
            this.groupsSelectItems.push({label:data.user.group.name_en, value: data.user.group.id});
            this.userForm.get('group_id').patchValue(data.user.group.id);

            this.polesSelectItems.push({label:data.user.pole.name_en, value: data.user.pole.id});
            this.userForm.get('pole_id').patchValue(data.user.pole.id);

            this.loadAllAccessibleDivisions(0);
          }
        });
        this.loadAllAccessiblePoles(0);
        break;
      case 'DIVISION_ADMIN':
        // 4. If the user is division admin, set selected group, pole and division. 
        // In this case all dropdowns are fixed and cannot be changed
        this.backendUserService.getById(this.currentUser.id).subscribe(data => {
          this.groupsSelectItems = [];
          this.polesSelectItems = [];
          this.divisionSelectItems = [];
          if (data.user && data.user.group && data.user.pole && data.user.division) {
            this.groupsSelectItems.push({label:data.user.group.name_en, value: data.user.group.id});
            this.userForm.get('group_id').patchValue(data.user.group.id);

            this.polesSelectItems.push({label:data.user.pole.name_en, value: data.user.pole.id});
            this.userForm.get('pole_id').patchValue(data.user.pole.id);

            this.divisionSelectItems.push({label:data.user.division.name_en, value: data.user.division.id});
            this.userForm.get('division_id').patchValue(data.user.division.id);
          }
        });
        break;
      default:
        break;
    }
  }

  onGroupChange(){
    this.userForm.get('pole_id').patchValue(null);
    this.userForm.get('division_id').patchValue(null);
    this.loadAllAccessiblePoles(0);
  }

  onPoleChange(){
    this.userForm.get('division_id').patchValue(null);
    this.loadAllAccessibleDivisions(0);
  }

  loadGroups(){
    this.groupService.getAllAccessibleGroups().subscribe(
      (data) => {
        this.groupsSelectItems = [];
        if (data.groups) {
          this.groupsSelectItems.push({label:'Select group...', value: null});
          data.groups.forEach(group => {
            this.groupsSelectItems.push({label:group.name_en, value: group.id});
          });
        }
      }
    );
  }

  loadAllAccessiblePoles(editedUserId){
    // Only group admins and master admin can create pole admins
    
    let groupId = this.userForm.get('group_id').value;
    if (!groupId) {
      groupId = 0;
    }
    this.poleService.getAllAccessiblePoles(groupId, editedUserId).subscribe(
      (data) => {
        this.polesSelectItems = [];
        if (data.poles) {
          this.polesSelectItems.push({label:'Select pole...', value: null});
          data.poles.forEach(pole => {
            this.polesSelectItems.push({label: pole.name_en, value: pole.id});
          });
        }
      }
    );
  }

  loadAllAccessibleDivisions(editedUserId){
    // Only division admins cannot create other division admins
    let poleId = this.userForm.get('pole_id').value;
    if (!poleId) {
      poleId = 0;
    }
    this.divisionService.getAllAccessibleDivisions(poleId, editedUserId).subscribe(
      (data) => {
        this.divisionSelectItems = [];
        if (data.divisions) {
          this.divisionSelectItems.push({label:'Select division...', value: null});
          data.divisions.forEach(division => {
            this.divisionSelectItems.push({label: division.name_en, value: division.id});
          });
        }
      }
    );
  }

  private initForm() {
    let divisionObject = <any>{};
    divisionObject = {
      role: new FormControl((this.user.role) ? this.user.role: 'Corporate' , [Validators.required]),
      phone: new FormControl(this.user.phone, [Validators.required, PhoneWithPlusAndSpaceValidator()]),
      email: new FormControl(this.user.email, []),
      group_id: new FormControl(this.user.group_id, (this.user.sponsor_id) ? [] : [Validators.required]),
      pole_id: new FormControl(this.user.pole_id, (this.user.sponsor_id) ? [] : [Validators.required]),
      division_id: new FormControl(this.user.division_id, (this.user.sponsor_id) ? [] : [Validators.required]),
      passwords: new FormGroup({
        password: new FormControl('', (this.isNew) ? Validators.required : []),
        confirm: new FormControl('', (this.isNew) ? Validators.required : []),
      }, this.passwordMatcher)
    };
    this.userForm = new FormGroup(divisionObject);
  }

  passwordMatcher = (divisionControl: AbstractControl): { [key: string]: boolean } => {
    // let password = division.controls.password;
    // let confirm = division.controls.confirm;
    const password = divisionControl.get('password');
    const confirm = divisionControl.get('confirm');
    if (!password || !confirm) {
      return null;
    }
    return password.value === confirm.value ? null : { nomatch: true };
  }

  onSubmit() {
    const editedUser = this.userForm.value;
    editedUser.password = editedUser.passwords.password;
    console.log(editedUser);
    if (this.isNew) {
      this.userService.create(editedUser).subscribe(
        (data) => {
          this.growlService.showInfo('User is created');
          this.navigateBack();
        },
        (error_data: any) => {
          console.log(error_data.error);
          this.growlService.showError(error_data.error.msg_const);
        }
      );
    } else {
      editedUser.id = this.user.id;
      this.userService.update(editedUser).subscribe(
        data => {
          this.growlService.showInfo('User is updated');
          this.navigateBack();
        },
        (error_data: any) => {
          console.log(error_data.error);
          this.growlService.showError(error_data.error.msg_const);
        }
      );
    }
  }

  private navigateBack() {
    this.router.navigate(['/home/mobile_users']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
