

<p-dropdown [options]="riskLevelsSI" [(ngModel)]="selectedRiskLevel" placeholder="Select risk level"></p-dropdown>

<button mat-raised-button color="primary" type="button" (click)="saveVigiCountryDescr()"
  [disabled]="(!selectedRiskLevel)? true: false">Save</button>

<h2 fxLayoutAlign="left center">Upload country image in JPEG format</h2>
<div fxLayout="column">
  <div id="fields_container" fxLayout="row" fxLayout.lt-sm="column" 
      fxLayoutGap="10px" >
      <div style="display: inline-block">
          <input type="file" (change)="imageChange($event)" placeholder="Upload counntry image" accept=".jpeg,.jpg">
          <mat-error *ngIf="imageError">{{ imageError }}</mat-error>
      </div>
      <button mat-raised-button color="primary" type="button" 
          (click)="uploadNewImageForCountry()"> 
          Upload image
      </button>
      <br />
  </div>
  <div style="width: 100px; height: 100px;">
      <img [src]="countryImgUrl + '?' + timestamp" *ngIf="countryImgUrl" alt=" &nbsp;There is not image uploaded for this country."/>
  </div>
</div>

<h2 style="font-size: 1.2em" fxlayoutalign="left center">VIGI Areas</h2>
<app-vigi-region-descr></app-vigi-region-descr>

