import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { AuthGuard } from '../_services/auth-guard.service';
// Components
import { HomeComponent } from '../home/home.component';
import { BackendUserEditComponent } from './backend_users/backend-user-edit/backend-user-edit.component';
import { BackendUsersComponent } from './backend_users/backend-users.component';
import { MobileUserEditComponent } from './mobile_users/mobile-user-edit/mobile-user-edit.component';
import { MobileUsersComponent } from './mobile_users/mobile-users.component';
import { PropertiesComponent } from './properties/properties.component';
import { CompanyEditComponent } from './companies/company_edit/company_edit.component';
import { CompaniesComponent } from './companies/companies.component';
import { SettingsComponent } from './settings/settings.component';
import { VigiCountryComponent } from './risk_assessment/vigi-country/vigi-country.component';
import { CountriesSheetsComponent } from './risk_assessment/fact_sheets/countries-sheets/countries-sheets.component';
import { VigiCountryDescrComponent } from './risk_assessment/vigi-country/vigi-country-descr/vigi-country-descr.component';
import { VigiRegionDescrComponent } from './risk_assessment/vigi-country/vigi-region-descr/vigi-region-descr.component';
import { VigiCountryMapComponent } from './risk_assessment/vigi-country/vigi-country-map/vigi-country-map.component';
import { RegionsSheetsComponent } from './risk_assessment/fact_sheets/regions-sheets/regions-sheets.component';
import { FactSheetComponent } from './risk_assessment/fact_sheets/fact_sheet.component';
import { TipsComponent } from './risk_assessment/tips/tips.component';
import { RiskSettingsComponent } from './risk_assessment/risk-settings/risk-settings.component';
import { MobileUserTripsComponent } from './mobile_users/mobile-user-trips/mobile-user-trips.component';
import { MobileUserLocationsComponent } from './mobile_users/mobile-user-locations/mobile-user-locations.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { AlertPushComponent } from './alerts/push/alert-push.component';
import { AlertsTableComponent } from './alerts/list/alerts-table.component';
import { HayCampainsTableComponent } from './alerts/haycampains/haycampains-table.component';
import { AppsettingsComponent } from './appsettings/appsettings.component';
import { MapRealTimeComponent } from './maps/map-real-time/map-real-time.component';
import { TimeFilterComponent } from './maps/time-filter/time-filter.component';
import { POILegendComponent } from './maps/poilegend/poilegend.component';
import { MapRouteComponent } from './maps/map-route/map-route.component';
import { MapCountryComponent } from './maps/map-country/map-country.component';
import { PobMapComponent } from './maps/pob-map/pob-map.component';
import { MarkerInfoDialogComponent } from './maps/marker-info-dialog/marker-info-dialog.component';
import { MapAllComponent } from './maps/map-all/map-all.component';
import { BackendSettingsComponent } from './backend_settings/backend_settings.component';
import { SSOListComponent } from './sso_list/sso_list.component';
import { CrisisListComponent } from './crisis_list/crisis_list.component';
import { AirportsTableComponent } from './airports/airports-table.component';
import { AirportEditComponent } from './airports/airport-edit/airport-edit.component';
import { HowAreYouComponent } from './how-are-you/how-are-you.component';
import { SmtpServerComponent } from './smtp-server/smtp-server.component';
import { EscrowTimeGeolocationComponent } from './escrow-time/escrow-time.component';
import { SwaggerDocumentationComponent } from './swagger-documentation/swagger-documentation.component';
import { MapPanicModeComponent } from './maps/map-panic-mode/map-panic-mode.component';
import { AlertsEditComponent } from './alerts/alerts-edit/alerts-edit.component';
import { HowAreYouLogsComponent } from './how-are-you-logs/how-are-you-logs.component';
import { AdminLogsComponent } from './admin-logs/admin-logs.component';

// Technically, pathMatch = 'full' results in a route hit when the remaining, unmatched
// segments of the URL match ''. In this example, the redirect is in a top level route so the
// remaining URL and the entire URL are the same thing.

// The other possible pathMatch value is 'prefix' which tells the router to match the redirect
// route when the remaining URL begins with the redirect route's prefix path.

// !!! If we navigate to subroute and there is an error that is not cought then the navigation
// automaticaly redirects to the previous route without errors
const ROUTES: Routes = [
  { path: '', redirectTo: '/home/mobile_users', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
      // BACKEND USERS
      {
        path: 'backend_user', children: [
          { path: 'new', component: BackendUserEditComponent },
          { path: ':id/edit', component: BackendUserEditComponent }
        ]
      },
      { path: 'backend_users', component: BackendUsersComponent },

      // MOBILE USERS
      {
        path: 'mobile_user', children: [
          { path: 'new', component: MobileUserEditComponent },
          { path: ':id/edit', component: MobileUserEditComponent },
          { path: ':id/trips', component: MobileUserTripsComponent },
          { path: ':id/locations', component: MobileUserLocationsComponent },
          { path: ':id/time_map', component: MapRealTimeComponent },
          { path: ':id/mobile_panic_mode_map', component: MapPanicModeComponent },
          { path: ':id/how_are_you_logs', component: HowAreYouLogsComponent },
        ]
      },
      { 
        path: 'mobile_users', component: MobileUsersComponent
      },
      { path: 'mobile_users_locations', component: MobileUserLocationsComponent },
      { path: 'mobile_users_trips', component: MobileUserTripsComponent },
      { path: 'mobile_panic_mode_map', component: MapPanicModeComponent },
      { path: 'how_are_you_logs', component: HowAreYouLogsComponent },

      // Properties (divisions, poles, groups)
      { path: 'properties', component: PropertiesComponent },

      {
        path: 'risk_assessment', children: [
          { 
            path: 'vigi_country', 
            component: VigiCountryComponent, 
            children: [
              { path: 'vigi_country_descr', component: VigiCountryDescrComponent, outlet: 'vigi'},
              // { path: 'vigi_region_descr', component: VigiRegionDescrComponent, outlet: 'vigi'},
              { path: 'vigi_country_map', component: VigiCountryMapComponent, outlet: 'vigi'},
            ]
          },
          { path: 'fact_sheets', component: FactSheetComponent },
          { path: 'tips', component: TipsComponent },
          { path: 'risk_settings', component: RiskSettingsComponent },
        ]
      },

      // Domains
      { path: 'domains', component: SettingsComponent },
      // Statistics
      { path: 'statistics', component: StatisticsComponent },
      // Alerts send push page
      { path: 'push_notification', component: AlertPushComponent },
      // Alerts table
      { path: 'notifications', component: AlertsTableComponent },
      // Alerts campain
      { path: 'haycampains', component: HayCampainsTableComponent },
      // Alerts
      {
        path: 'alert', children: [
          { path: ':id/edit', component: AlertsEditComponent },
        ]
      },
      // How are you
      { path: 'how_are_you', component: HowAreYouComponent },
      // App settings
      { path: 'appsettings', component: AppsettingsComponent },
      // Backend settings
      { path: 'backendsettings', component: BackendSettingsComponent },
      // Global map
      { path: 'map_all', component: MapAllComponent },
      // SSO Log
      { path: 'sso_logs', component: SSOListComponent },
      // Crisis Logs
      { path: 'crisis_logs', component: CrisisListComponent },
      // Admin Logs
      { path: 'admin_logs', component: AdminLogsComponent },
      // Airports
      { path: 'airports', component: AirportsTableComponent },
      // Airport edit
      {
        path: 'airport', children: [
          { path: 'new', component: AirportEditComponent },
          { path: ':id/edit', component: AirportEditComponent }
        ]
      }, 
      // SMTP Server
      { path: 'smtp_server', component: SmtpServerComponent },
      // Escrow Time
      { path: 'geolocation_escrow_time', component: EscrowTimeGeolocationComponent },
      // Escrow Time
      { path: 'swagger_documentation', component: SwaggerDocumentationComponent },
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule { }

export const routedComponents = [
  BackendUsersComponent,
  BackendUserEditComponent,
  CompaniesComponent,
  CompanyEditComponent,
  HomeComponent,
  LoginComponent,
  MobileUserEditComponent,
  MobileUsersComponent,
  PropertiesComponent,

  VigiCountryComponent,
  VigiCountryDescrComponent, VigiRegionDescrComponent, VigiCountryMapComponent,
  CountriesSheetsComponent, FactSheetComponent, TipsComponent, RegionsSheetsComponent, RiskSettingsComponent,
  SettingsComponent, AppsettingsComponent, BackendSettingsComponent,

  MobileUserTripsComponent, MobileUserLocationsComponent, StatisticsComponent, 
  // alerts
  AlertPushComponent, AlertsTableComponent, AlertsEditComponent, HowAreYouLogsComponent,
  SSOListComponent, HayCampainsTableComponent,
  CrisisListComponent, AdminLogsComponent, AirportsTableComponent, AirportEditComponent,
  HowAreYouComponent, SmtpServerComponent, 
  EscrowTimeGeolocationComponent, 
  SwaggerDocumentationComponent,
  MapPanicModeComponent,

  // maps
  MapRealTimeComponent, TimeFilterComponent, PobMapComponent, MarkerInfoDialogComponent,
  MapRouteComponent, MapAllComponent, MapCountryComponent, POILegendComponent
];
