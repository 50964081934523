<div style="padding:5px" fxLayoutAlign="center">
  <div fxLayout="column">
    <form [formGroup]="alertForm" (ngSubmit)="onSubmit()">
      <input formControlName="title" placeholder="Title (en)" 
        type="text" pInputText maxlength="200">
      <br />
      <div fxLayout="column">
        <span>Alert text (en)</span>
        <p-editor formControlName="content" [style]="{'height':'200px'}"></p-editor>
      </div>
      <br />

      <input formControlName="title_fr" placeholder="Title (fr)" 
        type="text" pInputText maxlength="200">
      <br />
      <div fxLayout="column">
        <span>Alert text (fr)</span>
        <p-editor formControlName="content_fr" [style]="{'height':'200px'}"></p-editor>
      </div>
      <br />

      <br />
      <button mat-raised-button 
        color="primary" 
        type="submit" 
        [disabled]="!alertForm.valid"
      >
        Save
      </button>
    </form>

</div>
