import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
// gallery module
import { NgxImageGalleryModule } from 'ngx-image-gallery';
// confirm dialog
import { ConfirmDialogsService } from './confirm_dialog/confirm-dialog.service';
import { ConfirmDialogComponent } from './confirm_dialog/confirm-dialog.component';
// upload dialog
import { UploadFileDialogComponent } from './upload_dialog/upload-dialog.component';
import { UploadDialogsService } from './upload_dialog/upload-dialog.service';
// growl dialog
import { GrowlComponent } from './growl_dialog/growl.component';
import { GrowlService } from './growl_dialog/growl.service';
import { Growl } from './growl_dialog/growl';
import { GrowlModule } from 'primeng/primeng';

import { InputConfirmDialogsService } from './input_confirm_dialog/input-confirm-dialog.service';
import { InputConfirmDialogComponent } from './input_confirm_dialog/input-confirm-dialog.component';
import { MyMaterialModule } from '../_module_my_material/material.module';
import { SingleImageDialogComponent } from './single_image_dialog/single_image_dialog.component';
import { SingleImageDialogService } from './single_image_dialog/single_image_dialog.service';
import { InfoDialogComponent } from './info_dialog/info-dialog.component';
import { InfoDialogsService } from './info_dialog/info-dialog.service';


@NgModule({
    imports: [
        MyMaterialModule, GrowlModule, BrowserModule, NgxImageGalleryModule,
        FormsModule
    ],
    exports: [
        ConfirmDialogComponent, UploadFileDialogComponent,
        GrowlComponent, InputConfirmDialogComponent, InfoDialogComponent
    ],
    declarations: [
        ConfirmDialogComponent, UploadFileDialogComponent,
        GrowlComponent, InputConfirmDialogComponent, SingleImageDialogComponent, InfoDialogComponent
    ],
    providers: [
        ConfirmDialogsService, UploadDialogsService,
        GrowlService, InputConfirmDialogsService, SingleImageDialogService, InfoDialogsService
    ],
    entryComponents: [
        ConfirmDialogComponent, UploadFileDialogComponent,
        InputConfirmDialogComponent, SingleImageDialogComponent, InfoDialogComponent
    ],
})
export class DialogsModule { }
