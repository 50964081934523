import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.config";
var VigiDescriptionService = /** @class */ (function () {
    function VigiDescriptionService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    // Get by ID
    VigiDescriptionService.prototype.getById = function (_id) {
        return this.httpClient.get(this.config.apiUrl + '/vigidescr/' + _id);
    };
    VigiDescriptionService.prototype.update = function (vigiDescr) {
        return this.httpClient.put(this.config.apiUrl + '/vigidescr/' + vigiDescr.id, vigiDescr);
    };
    VigiDescriptionService.ngInjectableDef = i0.defineInjectable({ factory: function VigiDescriptionService_Factory() { return new VigiDescriptionService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfig)); }, token: VigiDescriptionService, providedIn: "root" });
    return VigiDescriptionService;
}());
export { VigiDescriptionService };
