/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./smtp-server.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./smtp-server.component";
var styles_SmtpServerComponent = [i0.styles];
var RenderType_SmtpServerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SmtpServerComponent, data: {} });
export { RenderType_SmtpServerComponent as RenderType_SmtpServerComponent };
export function View_SmtpServerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" smtp-server works!\n"]))], null, null); }
export function View_SmtpServerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-smtp-server", [], null, null, null, View_SmtpServerComponent_0, RenderType_SmtpServerComponent)), i1.ɵdid(1, 114688, null, 0, i2.SmtpServerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmtpServerComponentNgFactory = i1.ɵccf("app-smtp-server", i2.SmtpServerComponent, View_SmtpServerComponent_Host_0, {}, {}, []);
export { SmtpServerComponentNgFactory as SmtpServerComponentNgFactory };
